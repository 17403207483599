
<div class=" main_wrapper ">
    <div class="sidebar">
      <app-side-nav></app-side-nav>
    </div>
    <div class="content-wrapper">
    <div class="top_bar">
      <app-header></app-header>
      </div>
      <div class="main_content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
