<div class=" main_wrapper ">
  <div class="sidebar">
    <app-side-nav></app-side-nav>
  </div>
  <div class="content-wrapper">
    <div class="top_bar">
      <app-header></app-header>
    </div>

    <div class="main_content">
      <div class="view_snv-details">
        <div class="d-flex justify-content-end"
          *ngIf="this.clinicalTrialResList!=null && this.clinicalTrialResList.length>0">
          <a [routerLink]="[ '/wb/hotspot_trail_list', hotspotVariantId]"
          class="drug_title">Select Hotspot Drugs</a>
        </div>
        <div class="d-flex justify-content-end"
          *ngIf="this.clinicalTrialResList==null || this.clinicalTrialResList.length<=0">
          <a  class="drug_title">No Clinical Trials</a>
        </div>
        <div *ngIf="hotspotSpinner" class="w-100 spinner_position">
          <div class="spinner-border  m-auto d-block" role="status">
            <span></span>
          </div>
        </div>
        <h6 class="clr_text"><span style="font-size: 20px!important;">Hotspot Variant Details : </span>
          {{ecrfPatientId}}
          <span>[<i>{{hotspotVariant?.geneName}}</i>]</span>
        </h6>


        <div class="snv_info mt-4">
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Gene</p>
            </div>
            <div class="col w-adjust">
              <div class="form-group">
                <!-- <label for=""></label> -->
                <i><input type="text" class="form-control" style="font-style: italic!important;" [(ngModel)]="hotspotVariant.geneName" [disabled]="true"></i>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Variant</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="" class="">Chromosome Number</label>
                    <input type="text" class="form-control" [(ngModel)]="hotspotVariant.chrom" [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">POS</label>
                    <input type="text" class="form-control" [(ngModel)]="hotspotVariant.pos" [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Ref</label>
                    <input type="text" class="form-control" [(ngModel)]="hotspotVariant.ref" [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Alt</label>
                    <input type="text" class="form-control" [(ngModel)]="hotspotVariant.alt" [disabled]="true">
                  </div>
                </div>
                <div class="w-100 mb-3"></div>

                <div class="col">
                  <div class="form-group">
                    <label for="">Qual</label>
                    <input type="text" class="form-control" [(ngModel)]="hotspotVariant.qual" [disabled]="true">
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label for="">Sample Id</label>
                    <input type="text" class="form-control" [(ngModel)]="hotspotVariant.sampleId" [disabled]="true">
                  </div>
                </div>
                <!--  -->
                <div class="col">
                  <div class="form-group">
                    <label for="">Ao</label>
                    <input type="text" class="form-control" [(ngModel)]="hotspotVariant.ao"
                      [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Strand Info</label>
                    <input type="text" class="form-control" [(ngModel)]="hotspotVariant.strandInfo"
                      [disabled]="true">
                  </div>
                </div>
                <div class="w-100 mb-3"></div>

                <div class="col-3">
                  <div class="form-group">
                    <label for="">Comment</label>
                    <input type="text" class="form-control" [(ngModel)]="hotspotVariant.comment" [disabled]="true">
                  </div>
                </div>
              </div>

            </div>
          </div>


          <!--  -->
          <div class="row pt-3 pb-4 border_line" *ngIf="isFirst72">
            <div class="col-2">
              <p class="clr_text">Prognostic</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Prognostic Significance</label>
                    <select class="form-control" name="significance"
                      [(ngModel)]="hotspotVariant.prognasticSignificance" (ngModelChange)="getPrognasticTag($event)">
                      <option value="Poor">Poor</option>
                      <option value="Good">Good</option>
                      <option value="NA">NA</option>

                    </select>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label for="">Prognostic Tag</label>
                    <ng-multiselect-dropdown [settings]="prognasticBiomarkerDropdownSettings" [data]="prognasticTagList"
                      [(ngModel)]="selectedPrognasticBiomarker" (ngModelChange)="selectPrognasticBiomarker($event)"
                      name="prognasticTag"></ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="w-100 mb-3"> </div>
                <div class="col">
                  <div class="form-group">
                    <!-- <label for="">mutationTasterPred</label> -->
                    <!-- <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Discription"></textarea> -->

                    <label for="">Add Prognostic Description</label>
                    <!-- <textarea type="text" class="form-control" style="height: 200px;" name="prognasticDescription"
                  [(ngModel)]="hotspotVariant.prognasticDescription"></textarea> -->
                    <div
                      *ngIf="hotspotVariant.prognasticDescription!=null ||hotspotVariant.prognasticDescription!=undefined">
                      <P *ngIf="hotspotVariant.prognasticDescription.length">
                        <strong>[{{editorText?.length}}/2000]</strong></P>
                    </div>
                    <ckeditor [editor]="editor"
                      [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                      name="addPrognasticDescription" [(ngModel)]="hotspotVariant.prognasticDescription"
                      (keyup)="textcount('addPrognasticDescription-editor')"></ckeditor>
                    <div class="addPrognasticDescription-editor" hidden="true"
                      [innerHtml]="hotspotVariant.prognasticDescription"></div>
                    <p class="pt-2"><strong>*[</strong>A<sup>b</sup> →
                      {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>

                  </div>
                </div>

              </div>

            </div>
          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line" *ngIf="hotspotVariant.significance!='NA'">
            <div class="col-2">
              <p class="clr_text">Depth</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Total Depth</label>
                    <input type="text" class="form-control" name="bamDepth" [(ngModel)]="totalDept" >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Alternative Allele Depth</label>
                    <input type="text" class="form-control" name="bam_ALT_Depth" [(ngModel)]="alternativeAlleleDepth" >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Allelic burden (%)</label>
                    <input type="text" class="form-control" name="allelicBurden" [(ngModel)]="allelicBurden" >
                  </div>
                </div>
                <!-- <div class="col"></div>
        <div class="col"></div> -->

              </div>

            </div>
          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Gene</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Clinical Significance</label>

                    <select class="form-control" name="significance" [(ngModel)]="hotspotVariant.significance"
                      (click)="getGeneDescription()">
                      <option value="Benign">Benign</option>
                      <option value="Pathogenic">Pathogenic</option>
                      <option value="Likely Pathogenic">Likely Pathogenic</option>
                      <option value="VUS">VUS</option>
                      <option value="Not Classified">Not Classified</option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Reference</label>
                    <input type="text" class="form-control" name="reference" [(ngModel)]="hotspotVariant.reference">
                  </div>
                </div>
                <div class="w-100 mb-3"> </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Gene Description (<i>{{gene?.name}}</i>)</label>
                    <div style="font-size: small;" [innerHtml]="gene?.description"></div><br/>
                    <!-- <textarea type="text" class="form-control" style="height: 200px;" name="addGeneDescription"
                  [(ngModel)]="hotspotVariant.addGeneDescription"></textarea> -->
                    <div
                      *ngIf="hotspotVariant.addGeneDescription!=null ||hotspotVariant.addGeneDescription!=undefined">
                      <P *ngIf="hotspotVariant.addGeneDescription.length"><strong>[{{editorText.length}}/2000]</strong>
                      </P>
                    </div>
                    <ckeditor [editor]="editor"
                      [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                      name="addGeneDescription" [(ngModel)]="hotspotVariant.addGeneDescription"
                      (keyup)="textcount('addGeneDescription-editor')"></ckeditor>
                    <div class="addGeneDescription-editor" hidden="true"
                      [innerHtml]="hotspotVariant.addGeneDescription"></div>
                    <p class="pt-2"><strong>*[</strong>A<sup>b</sup> →
                      {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
                  </div>
                </div>
                <div class="w-100 mb-3"> </div>

                <div class="col">
                  <div class="form-group">
                    <label for="">PMIDS:</label>
                    <input *ngIf="isFirst72" type="text" class="form-control"
                      placeholder="separate each pmId by comma(,)" name="pmIds"
                      [(ngModel)]="hotspotVariant.variantPmIds">
                    <input *ngIf="!isFirst72" type="text" class="form-control"
                      placeholder="separate each pmId by comma(,)" name="pmIds" [(ngModel)]="pmIds">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--  -->

          <!--  -->
          <div class="save_buton py-3 ">
            <button type="button" [disabled]="isSubmited" (click)="submitDetails()" class="btn btn_cyan float-right">
              <span *ngIf="!isSubmited" style="color: white;"> Save </span>
              <div *ngIf="isSubmited" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
