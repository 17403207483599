
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_services/auth/auth.guard';
import { WorkbenchComponent } from './workbench.component';
import { ViewSnvVariantComponent } from './view-snv-variant/view-snv-variant.component';
import { ViewCnaVariantComponent } from './view-cna-variant/view-cna-variant.component';
import { ViewFusionVariantComponent } from './view-fusion-variant/view-fusion-variant.component';
import { AdditionalDetailsComponent } from './additional-details/additional-details.component';
import { VariantDashboardComponent } from './variant-dashboard/variant-dashboard.component';
import { SnvVariantComponent } from './snv-variant/snv-variant.component';
import { CnaVariantComponent } from './cna-variant/cna-variant.component';
import { FusionVariantComponent } from './fusion-variant/fusion-variant.component';
import { ClinicalTrailComponent } from './clinical-trail/clinical-trail.component';
import { First72SolidModule } from './first72-solid/first72-solid.module';
import { ViewHotspotVariantComponent } from './view-hotspot-variant/view-hotspot-variant.component';



const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: WorkbenchComponent,
    children: [

      {
        path: ':id/dash-reports/:reportId',
        component: VariantDashboardComponent,
      },
      {
        path: 'cna_excel/:id',
        component: CnaVariantComponent,
      },
      {
        path: 'fusion_excel/:id',
        component: FusionVariantComponent,
      },
      {
        path : ':reportId/snv/:variantId',
        component: ViewSnvVariantComponent
      },
      {
        path : ':reportId/cna/:variantId',
        component: ViewCnaVariantComponent
      },
      {
        path : ':reportId/fusion/:variantId',
        component: ViewFusionVariantComponent
      },
      {
        path : 'additional_info',
        component: AdditionalDetailsComponent
      },
      {
        path: 'snv_trail_list/:id',
        component: ClinicalTrailComponent,
      },
      {
        path: 'cna_trail_list/:cid',
        component: ClinicalTrailComponent,
      },
      {
        path : ':reportId/hotspot/:variantId',
        component: ViewHotspotVariantComponent,
      },
      {
        path: 'fusion_trail_list/:fid',
        component: ClinicalTrailComponent,
      },
      {
        path: 'hotspot_trail_list/:hid',
        component: ClinicalTrailComponent,
      },
            {
        path: '',
        loadChildren: () => First72SolidModule
      },

]
},
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkbenchRoutingModule { }
