<div class=" main_wrapper ">
  <div class="sidebar">
    <app-side-nav></app-side-nav>
  </div>
  <div class="content-wrapper">
    <div class="top_bar">
      <app-header></app-header>
    </div>

    <div class="main_content">
      <div class="view_snv-details">
        <div class="d-flex justify-content-end" *ngIf="this.clinicalTrialListCount>0">
          <a [routerLink]="[ '/wb/fusion_trail_list', fusionVariantId]"
            class="drug_title">Select Hotspot Drugs</a>
        </div>
        <div class="d-flex justify-content-end" *ngIf="this.clinicalTrialListCount<=0">
          <a  class="drug_title">No Clinical Trials</a>
        </div>

        <div *ngIf="fusionSpinner" class="w-100 spinner_position">
          <div class="spinner-border  m-auto d-block" role="status">
            <span></span>
          </div>
        </div>
        <h6 class="clr_text"><span style="font-size: 20px!important;">Fusion Variant Detail : </span>
          {{ecrfPatientId}}
          <span>[<i>{{fusionVariant?.fusionGene}}</i>]</span>
        </h6>

        <div class="snv_info mt-4">
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Fusion Variant </p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Gene</label>
                    <i><input type="text" class="form-control" style="font-style: italic!important;" [(ngModel)]="fusionVariant.fusionGene" [disabled]="true"></i>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Read Support</label>
                    <input type="text" class="form-control" [(ngModel)]="fusionVariant.score" [disabled]="true">
                  </div>
                </div>
                <div class="w-100 mb-3"> </div>

                <div class="col">
                  <div class="form-group">
                    <label for="">Read Name</label>
                    <div class="col-6" style="border: 1px solid #d4d6d9; background-color: #e9ecef;border-radius: 5px;"
                      *ngIf="readNameList!=null && readNameList.length>0">
                      <p *ngFor="let item of readNameList">{{item}}</p>
                    </div>
                    <div class="col-6" style="border: 1px solid #d4d6d9; background-color: #e9ecef;border-radius: 5px;"
                      *ngIf="readNameList==null || readNameList.length<=0">
                      <input type="text" class="form-control" [disabled]="true">
                    </div>
                  </div>
                </div>
                <div class="w-100 mb-3"> </div>

                <div class="col">
                  <div class="form-group">
                    <label for="">Left Break Point </label>
                    <input type="text" class="form-control" [(ngModel)]="fusionVariant.leftBreakPoint"
                      [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Right Break Point </label>
                    <input type="text" class="form-control" [(ngModel)]="fusionVariant.rightBreakPoint"
                      [disabled]="true">
                  </div>
                </div>

              </div>

            </div>
          </div>
          <!--  -->

          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Clinical Siginificance</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <select class="form-control" name="significance" [(ngModel)]="fusionVariant.significance"
                      (click)=" getGeneDescription()">
                      <option value="Benign">Benign</option>
                      <option value="Pathogenic">Pathogenic</option>
                      <option value="Likely Pathogenic">Likely Pathogenic</option>
                      <option value="VUS">VUS</option>
                      <option value="Not Classified">Not Classified</option>
                    </select>
                  </div>
                </div>


              </div>

            </div>
          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Exons</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Left Exon</label>
                    <input type="text" class="form-control" name="reference" [(ngModel)]="fusionVariant.leftExon">
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Right Exon</label>
                    <input type="text" class="form-control" name="reference" [(ngModel)]="fusionVariant.rightExon">
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Reference</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="reference" [(ngModel)]="fusionVariant.reference">
                  </div>
                </div>



              </div>

            </div>
          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Additional Notes</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <!-- <label for="">Discription</label> -->
                    <ckeditor [editor]="editor"
                      [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }" name="addNotes"
                      [(ngModel)]="fusionVariant.addNotes"></ckeditor>
                  </div>
                </div>



              </div>

            </div>
          </div>
          <!--  -->
          <div class="save_buton py-3 ">
            <button type="button" [disabled]="isSubmited" (click)="submitDetails()" class="btn btn_cyan float-right">
              <span *ngIf="!isSubmited" style="color: white;"> Save </span>
              <div *ngIf="isSubmited" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
