<div class=" main_wrapper ">
  <div class="sidebar">
    <app-side-nav></app-side-nav>
  </div>
  <div class="content-wrapper">
    <div class="top_bar">
      <app-header></app-header>
    </div>
    <div class="main_content">
      <div class="report_icon">

      </div>
      <div class="p-0">
        <div class="patient_info bg-shade p-4 rounded">
          <div class="item">
            <ul class="p-0">
              <li><span>Sample ID : </span> <span> {{ecrfPatientId || "NA"}} </span></li>
              <li><span>Input file name R1 : </span> <span> {{dnaR1Documents[0]?.title || "NA"}} </span></li>
              <li><span>Input file name R2 : </span> <span> {{dnaR2Documents[0]?.title || "NA"}} </span></li>
              <li><span>Age/DOB : </span> <span> {{patient?.dateOfBirth}} ({{patient?.age}}years) </span></li>
            </ul>
          </div>
          <div class="item">
            <ul class="p-0">
              <li><span>Test name : </span> <span> {{patient?.testPackageName || "NA"}} </span></li>
              <li><span>Gender : </span> <span> {{patient?.gender || "NA"}}</span></li>
              <li><span>Capturing Kit : </span> <span> {{patient?.dnaKitUsed || "NA"}} </span></li>
              <li><span>Registration Date : </span> <span> {{sampleInfo?.sampleRegistrationOn || "NA"}} </span></li>
            </ul>
          </div>
          <div class="item">
            <ul class="p-0">
              <li><span> Cancer Type <a (click)="toggleEditMode()" *ngIf="!isEditMode">
                (<i class="zmdi zmdi-edit" ></i>)
              </a>: </span> <span *ngIf="!isEditMode"> {{selectedFirstCancertype || "NA"}}</span>

                  <div *ngIf="isEditMode">
                    <ng-multiselect-dropdown class="drop" name="fileType" [(ngModel)]="selectedcancertype"
                      [placeholder]="'Select File Type'" [settings]="cancerTypeDropdownSettings" [data]="cancerTypeList"
                      (onSelect)="cancerTypeSelect($event)" (onDeSelect)="cancerTypeDeSelect($event)">
                    </ng-multiselect-dropdown>
                  </div>
                 </li>
              <!-- <li><span>Cancer Type :
                  <ng-multiselect-dropdown
                    [settings]="cancerTypeDropdownSettings"
                    [data]="cancerTypeList"
                    name="cancerType"
                    [(ngModel)]="selectedcancertype"
                    (onSelect)="cancerTypeSelect($event)"
                    (onDeSelect)="cancerTypeDeSelect($event)">
                  </ng-multiselect-dropdown>
                </span></li> -->
              <li><span> Sample source : </span> <span> {{sampleInfo?.somaticType || "NA"}} </span> </li>
              <li><span>Sample date : </span> <span> {{sampleInfo?.sampleRegistrationOn || "NA"}} </span></li>
            </ul>
          </div>
        </div>
        <!-- tabs -->
        <div class="cust_tabs mt-4">
          <div class="col pr-1" *ngIf="is4basecare || isTMH || isHCG">
            <div class="d-flex justify-content-end">
              <div class=""><button class="btn btn_cyan ml-2" style="color: white!important;">
                  <div *ngIf="is4basecare">
                    <a [routerLink]="[ '/wb/first72_view_report/4bc', reportId, temp ]" target="_blank"
                      style="color: white!important;">
                      <i class="zmdi zmdi-eye eye"></i> View Report</a>
                  </div>
                  <div *ngIf="isTMH">
                    <a [routerLink]="[ '/wb/first72_view_report/tmh', reportId, temp ]" target="_blank"
                      style="color: white!important;">
                      <i class="zmdi zmdi-eye eye"></i> View Report</a>
                  </div>
                  <div *ngIf="isHCG">
                    <a [routerLink]="[ '/wb/first72_view_report/hcg', reportId, temp ]" target="_blank"
                      style="color: white!important;">
                      <i class="zmdi zmdi-eye eye"></i> View Report</a>
                  </div>

                </button>
              </div>
              <br/>
              <div class="d-flex justify-content-end" >
                <div class="template_width ml-2">
                  <select class="form-control" (change)="tempSelection($event)">
                    <option selected disabled> Select Template</option>
                    <option value="temp1">Template 1</option>
                    <option value="temp2">Template 2</option>
                    <option value="temp3">Template 3</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!-- Nav tabs -->
          <ul class="nav nav-tabs">
            <li class="nav-item" *ngIf="patient?.dnaKitUsed!='FUS'">
              <a class="nav-link active" data-toggle="tab" href="#hotspot" style="color: black!important;">Hotspot</a>
            </li>
            <li class="nav-item" *ngIf="patient?.dnaKitUsed!='FUS'">
              <a class="nav-link " data-toggle="tab" href="#snv" style="color: black!important;">SNV/InDels</a>
            </li>
            <li class="nav-item" *ngIf="patient?.dnaKitUsed!='FUS'">
              <a class="nav-link" data-toggle="tab" href="#cna" style="color: black!important;">CNA</a>
            </li>
            <li class="nav-item" *ngIf="patient?.dnaKitUsed!='CDS'">
              <a class="nav-link" data-toggle="tab" href="#fusion" style="color: black!important;">Fusion</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#others" style="color: black!important;">Others</a>
            </li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content p-4 border rounded mt-4">

            <div id="hotspot" class="container tab-pane active">
              <app-hotspot-variant></app-hotspot-variant>
            </div>
            <div id="snv" class="container  tab-pane fade ">
              <app-snv-variant></app-snv-variant>
            </div>
            <div id="cna" class="container tab-pane fade">
              <app-cna-variant></app-cna-variant>
            </div>
            <div id="fusion" class="container tab-pane fade">
              <app-fusion-variant></app-fusion-variant>
            </div>
            <div id="others" class="container tab-pane fade">
              <app-additional-details></app-additional-details>
            </div>
          </div>
        </div>
        <!-- data -->



      </div>
    </div>
  </div>
</div>
