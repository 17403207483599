import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { First72SolidRoutingModule } from './first72-solid-routing.module';
import { First72SolidComponent } from './first72-solid.component';
import { TmhComponent } from './tmh/tmh.component';
import { HcgComponent } from './hcg/hcg.component';
import { Is4BaseCareComponent } from './4base-care/is4-base-care.component';
import { SplitCommaStringPipe } from 'src/app/_services/workbench-service/pipe';


@NgModule({
  declarations: [
    First72SolidComponent,
    Is4BaseCareComponent,
    TmhComponent,
    HcgComponent,
    SplitCommaStringPipe,

  ],
  imports: [
    CommonModule,
    First72SolidRoutingModule
  ]
})
export class First72SolidModule { }
