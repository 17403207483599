import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClinicalTrialRes, Gene, PrognasticBiomarkerRes } from 'src/app/_interfaces/admin';
import { CNAVariants } from 'src/app/_interfaces/excel';
import { StudyFieldsResponse } from 'src/app/_interfaces/trails';
import { ClinicalTrailService } from 'src/app/_services/workbench-service/clinical-trail.service';
import { CnaService } from 'src/app/_services/workbench-service/cna.service';
import { DataAdminService } from 'src/app/_services/workbench-service/data-admin.service';
import { ReportService } from 'src/app/_services/workbench-service/report.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { User } from 'src/app/_interfaces/auth.model';

@Component({
  selector: 'app-view-cna-variant',
  templateUrl: './view-cna-variant.component.html',
  styleUrls: ['./view-cna-variant.component.scss']
})
export class ViewCnaVariantComponent implements OnInit {

  public editor:any = ClassicEditor;
  editorText: string = '';
  sup="A{b} → Superscript";
  sub="A~b! → Subscript";
  pmIds!: string;
  cnaVariantId!: number;
  reportId!: number;
  cnaVariant = {} as CNAVariants;
  studyFieldsResponse = {} as StudyFieldsResponse;
  clinicalTrialResList: ClinicalTrialRes[] = [];
  prognasticBiomarkerDropdownSettings:any
  prognasticTagList:PrognasticBiomarkerRes []=[]
  selectedPrognasticBiomarker:any
  isSubmited!: boolean;
  statusMsg!: string;
  gene!: Gene;
  isGermline = false;

  constructor(
    private routerAct: ActivatedRoute,
    private router: Router,
    private reportService: ReportService,
    private trailService: ClinicalTrailService,
    private dataAdminService: DataAdminService,
    private cnaService: CnaService,
    private toastr: ToastrService,
    private titleService: Title
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }
  appUser = {} as User;

  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem('user') || '{}');
    console.log("appUser",this.appUser)

    this.cnaVariant.significance='Not Classified'

    this.cnaVariantId = this.routerAct.snapshot.params['variantId'];
    this.reportId = this.routerAct.snapshot.params['reportId'];
    this.selectedPrognasticBiomarker=[]
    this.prognasticBiomarkerDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'prognasticTag',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    if(this.cnaVariantId){
      this.getCNAVariant();
    }
    this.selectPrognasticBiomarkers()

  }

  textcount(classname:any){
    var dom=document.getElementsByClassName(classname);
      var data:any=dom[0]
      this.editorText= data.outerText
  }

  geneDescriptionMessage!:String;
  previousDescription!: any;
  getGeneDescription(){
    if(this.cnaVariant.significance=='Benign'){this.cnaVariant.addGeneDescription=this.previousDescription;}
     if(this.cnaVariant.significance=='Pathogenic'){
      this.geneDescriptionMessage=" (gene) (amplification/deletion) present in XX% of (cancer) patients. (gene) (amplification/deletion) has been shown to correlate with (gene) (overexpression/ downregulation) in (cancer type) and has been shown activate oncogenic signalling such as (pathway name) pathway, suggesting a (gain/loss) of function (Reference). Hence, the identified (gene) (amplification/deletion) is classified as Pathogenic. ";
      this.cnaVariant.addGeneDescription =this.previousDescription + `<br/>` + this.geneDescriptionMessage;
    }
    else if(this.cnaVariant.significance=='VUS'){
      this.geneDescriptionMessage=" (gene) (amplification/deletion) present in XX% of (cancer) patients. (gene) (amplification/deletion) has not been well characterized in the context of cancer. Hence due to lack of functional and clinical significance of the (gene) (amplification/deletion), it is classified as VUS and must be clinically correlated with other findings for any further management. ";
      this.cnaVariant.addGeneDescription =this.previousDescription + `<br/>` + this.geneDescriptionMessage;
    }
    else if(this.cnaVariant.significance=='NA' || this.cnaVariant.significance=='Not Classified'){this.cnaVariant.addGeneDescription=this.previousDescription;}
    else if(this.cnaVariant.significance=='Likely Pathogenic'){this.cnaVariant.addGeneDescription=this.previousDescription;}
  }
  declare  comparisionList : String[] ;

  getComparisionList(comparision : string):void{

    this.comparisionList =  comparision.split(";");
    console.log(" comparisionList : ",this.comparisionList);

  }

  nstudiesFound = 0;
  isFirst72=false;
  ecrfPatientId:any;
  cnaListSpinner=true;
  getCNAVariant() {
    this.cnaService.getCNAVariant(this.cnaVariantId).subscribe(
      (response: any) => {
        this.cnaVariant = response['payLoad'];
        this.cnaListSpinner=false
        if (this.appUser.organisation == 'Lifecell') {
          this.ecrfPatientId = this.cnaVariant?.report?.patient.orgLabId;
        } else if (this.appUser.organisation == '4baseCare Onco Solutions') {
          this.ecrfPatientId = this.cnaVariant?.report?.patient?.patientId;
        } else {
          this.ecrfPatientId = this.cnaVariant?.report?.patient.organisationPatientId;
        }
        if(this.cnaVariant.report.reportType.name.includes("Germline")){
          this.isGermline = true;
        }
        if(this.cnaVariant.report.reportStatus == 'REPORTED'){
          this.statusMsg = "Report is already delivered no operations can be done";
          this.showSuccess();
          this.router.navigate(['/reports']);
          return;
        }
        if(this.cnaVariant.significance=='NA'){
          this.cnaVariant.significance='Not Classified'
        }
        if(this.cnaVariant.significance==null){
          this.cnaVariant.significance='Not Classified'
        }
        this.pmIds=this.cnaVariant.report.pmIds.toString();
        console.log("complete cna variant : ",this.cnaVariant);
        if(this.cnaVariant.comparison!=null){
        this.getComparisionList(this.cnaVariant.comparison);
        }
        this.titleService.setTitle(this.cnaVariant.report.patient.ecrfId + " - CNA - " + this.cnaVariant.gene);



        this.dataAdminService.getGeneByName(this.cnaVariant.gene).subscribe(
          (response: any) => {
            this.gene = response['payLoad'];
            console.log("gene details", this.gene);
            this.previousDescription=this.cnaVariant.addGeneDescription;
            // this is to remove the <p></p> in gene.description the is being displayed above the add gene description
            this.gene.description= this.gene.description.replace(/<p>/g,'');
            this.gene.description= this.gene.description.replace(/<\/p>/g, '');
          }
        );
        if (this.cnaVariant.significance == null) {
          this.cnaVariant.significance = "Benign";
        }
        if(this.cnaVariant.report.reportType.name=="First 72 Solid" || this.cnaVariant.report.reportType.name=="First 72 Liquid"){
          this.isFirst72 = true;
        }

        if (response['success']) {
          this.dataAdminService.getAllHotspotClinicalTrailByGene(this.cnaVariant.gene.split(';', 1).toString()).subscribe(
            (response: any) => {
              this.clinicalTrialResList = response['payLoad'];
              console.log("clinical trail db",this.clinicalTrialResList);
            }
          );
      }
      setTimeout(()=>{
        this.textcount('addGeneDescription-editor')
      },3000)
      }
    );
  }
  getPrognasticTag(significance:string){

  }
  selectPrognasticBiomarkers(){
    this.dataAdminService.prognasticBiomarkerList().subscribe((res:any)=>{
      this.prognasticTagList=res['payLoad']

    })
  }
  selectPrognasticBiomarker(prognasticTag:any){
    this.prognasticTagList.forEach((element:any)=>{
     if(this.selectedPrognasticBiomarker.length>0){
      if(element.id==this.selectedPrognasticBiomarker[0].id){
        this.cnaVariant.prognasticDescription=element.description
        console.log("prognasticTagList ",element);
      }
     }

    })
  }
  submitDetails() {
    this.isSubmited = true;
    console.log("this.reportedVariant.significance", this.cnaVariant.significance);
    this.cnaService.addCNAVariantSignificance(this.cnaVariantId, this.cnaVariant).subscribe(
      (response: any) => {
        this.isSubmited = false;
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
      }
    );
    this.isSubmited = true;
    if(this.pmIds.length>0){
      this.pmIds=this.pmIds;
    this.reportService.addPMIds(this.reportId, this.pmIds).subscribe(
      (response: any) => {
        console.log(response);
        this.isSubmited = false;
      }
    );
  }
  }

}
