<div class="view_snv-details">
  <h6 class="clr_text2">Others</h6>
  <div class="snv_info ">
    <div class="row py-4 border_line">
      <div class="col-2">
        <p class="clr_text">Basic Info</p>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="form-group">
              <div class="d-flex justify-content-between align-items-center">
                <label>Clinical Summary:</label>
                <div style="text-align: right; padding-bottom: 5px;" *ngIf="clinicalSummary!=null ||clinicalSummary!=undefined" >
                <P *ngIf="clinicalSummary.length"><strong>[{{clinicalSummary.length}}/3000]</strong></P>
                </div>
            </div>
                <input type="text" class="form-control" name="clinicalSummary" [(ngModel)]="clinicalSummary">

            </div>
          </div>
           <div class="col-12 mb-3">
            <div class="form-group">
              <div class="d-flex justify-content-between align-items-center">
                <label>Notes:</label>
            </div>
            <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
          name="disclaimerNote" [(ngModel)]="disclaimerNote" ></ckeditor>
</div>
          </div>

        </div>

      </div>
    </div>
     <!--  -->
     <div class="row py-4 border_line">
      <div class="col-2">
        <p class="clr_text">Additional Details</p>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div >
                  <label for="">Disclaimer DB</label>

                <ng-multiselect-dropdown  placeholder="Select Disclaimer"
                    [settings]="disclaimerDropdownSettings" [data]="disclaimerList"
                    (onSelect)="onDisclaimerItemSelect($event)" (onDeSelect)="onDisclaimerItemDeSelect($event)">
                </ng-multiselect-dropdown>
            </div>
            <label for="">Additional Notes</label>
            <div style="text-align: right; padding-bottom: 5px;" *ngIf="addNote!=null ||addNote!=undefined" >
              <P *ngIf="addNote.length"><strong>[{{editorText?.length}}/11000]</strong></P>
          </div>

              <div class="mb-3">
                <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                  name="addNote" [(ngModel)]="addNote" ></ckeditor>
              </div>

                  <div class="form-group row" >
                    <div class="col">
                        <label>PMIDS:</label>
                        <input type="text" class="form-control" placeholder="separate each pmId by comma(,)"
                            name="pmIds" [(ngModel)]="pmIds">
                    </div>
                </div>
</div>
          </div>



        </div>

      </div>
    </div>
     <!--  -->
     <!-- <div class="row py-4 border_line">
      <div class="col-2">
        <p class="clr_text">Websites</p>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="form-group">
            <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Websites"></textarea>
            </div>
          </div>



        </div>

      </div>
    </div> -->
    <!--  -->
    <!-- <div class="row py-4 border_line">
      <div class="col-2">
        <p class="clr_text">Disclaimer </p>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="form-group">
              <select class="form-control">
                <option value="">Select Tag</option>
                <option value="">option1</option>
                <option value="">option1</option>
                <option value="">option1</option>
              </select>
            </div>
          </div>
           <div class="col-12 ">
            <div class="form-group">
              <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Disclaimer"></textarea>
            </div>
          </div>

        </div>

      </div>
    </div> -->
    <div class="save_buton py-3 mb-3">
      <!-- <button type="button" class="btn btn_cyan float-right">Save</button> -->
      <button type="button" [disabled]="isSubmited" class="btn btn_cyan float-right"
       (click)="submit()">
      <span *ngIf="isDataSubmited" style="color:white;"> Update </span>
      <div *ngIf="!isDataSubmited" >
          <span  style="color:white;">Save</span>
      </div>
  </button>
    </div>

  </div>
  </div>
