import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HotspotService {

  constructor(
    private httpClient: HttpClient
  ) { }

  checkAutoUploadHostspotFile(reportId: Number) {
    return this.httpClient.post(environment.wbApiUrl+'/hot-spot/' + reportId + '/auto_upload_hotspot_file',null,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getHotspotVariantById(reportVariantId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/hot-spot/' + reportVariantId + '/get_report_variant',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getAllHotspotVariantsByReportId(reportId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/hot-spot/' + reportId + '/get_hotspot_variants',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  hotspotVariantStarStatus(reportVariantId: number, aBoolean: boolean) {
    return this.httpClient.post(environment.wbApiUrl+'/hot-spot/' + reportVariantId + '/hotspot_variant_star_status', aBoolean,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  hotspotVariantStatus(reportVariantId: number, aBoolean: boolean) {
    return this.httpClient.post(environment.wbApiUrl+'/hot-spot/' + reportVariantId + '/hotspot_variant_status', aBoolean,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addHotspotVariantSignificance(reportVariantId: number, body: any) {
    return this.httpClient.post(environment.wbApiUrl+'/hot-spot/' + reportVariantId + '/add_significance', body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }


  getSelectedHotspotVariants(reportId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/hot-spot/' + reportId + '/get_selected_report_variants',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
  setHotspotVariantPriorityOrder(reportVariantId: number, priorityOrderNumber: number) {
    return this.httpClient.post(environment.wbApiUrl+'/hot-spot/' + reportVariantId + '/priority_order', priorityOrderNumber,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
}
