import { Medical } from './../../_interfaces/lab-model';
import { Component, OnInit } from '@angular/core';
import {
  Documents,
  ReqId,
  SampleInfoReq,
  reportsEmailDto,
} from 'src/app/_interfaces/lab-model';
import { User } from 'src/app/_interfaces/auth.model';
import { LabService } from 'src/app/_services/oncobench-service/lab.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { Subscription, interval } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { S3ServiceService } from 'src/app/_services/oncobench-service/s3-service.service';
import { S3 } from 'aws-sdk';
import { environment } from 'src/environments/environment';

export interface PatientRes {
  id: number;
  dateOfBirth: Date;
  dnaKitUsed: string;
  familyName: string;
  gender: string;
  organisationPatientId: string;
  patientName: string;
  rnaKitUsed: string;
  testPackageName: string;
  patientTestPackages: any;
  patientDocumentStatus: string;
  oncobenchAnalysisStatus: string;
  orgLabId: string;
  patientId: string;
  age: number;
  sampleSourceType: string;
  medical: Medical;
}
export interface DnaAndSomaticTypeDataReq {
  dnaKitUsed: string;
  somaticType: string;
}

export interface PatientDocumentReq {
  patientId: number;
  type: string;
  comments: string;
  title: string;
  documentUrl: string;
  status: string;
}

export interface ProcessingComments {
  url: string;
}
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  fileTypeList = [
    { id: 'Fastq', name: 'Fastq' },
    // { id: 'Bam', name: 'Bam' },
    // { id: 'VCF', name: 'VCF' },
    // { id: 'Others', name: 'Others' },
  ];

  bamProgress = [{ fileName: '', percentage: 100 }];

  baiProgress = [{ fileName: '', percentage: 100 }];

  dnaR1Progress = [{ fileName: '', percentage: 100 }];

  dnaR2Progress = [{ fileName: '', percentage: 100 }];

  rnaR1Progress = [{ fileName: '', percentage: 100 }];

  rnaR2Progress = [{ fileName: '', percentage: 100 }];

  histoProgress = [{ fileName: '', percentage: 100 }];

  vcfProgress = [{ fileName: '', percentage: 100 }];

  patientId!: number;
  reqId = {} as ReqId;
  patient = {} as PatientRes;

  isuploadModal = false;
  isBioModel = false;
  isUploading = false;
  isDna = false;
  isRna = false;

  documentsList: Documents[] = [];
  dnaR1Documents: Documents[] = [];
  dnaR2Documents: Documents[] = [];
  rnaR1Documents: Documents[] = [];
  rnaR2Documents: Documents[] = [];
  histoDocuments: Documents[] = [];
  vcfDocuments: Documents[] = [];
  bamDocuments: Documents[] = [];
  baiDocuments: Documents[] = [];
  statusId!: number;
  patientDocumentReq = {} as PatientDocumentReq;
  dnaAndSomaticTypeDataReq = {} as DnaAndSomaticTypeDataReq;
  fileDropdownSettings: IDropdownSettings = {};
  selectedItems: any = [];
  selectedItems1: any = [];
  isDisabled = true;
  somaticType: any;
  subscription!: Subscription;
  fileUploadProgress = 10;
  registrationOn: any;
  private bucket: S3;
  isCnvComplete: boolean = false;
  isMsiComplete: boolean = false;
  isPanelCreationComplete: boolean = false;
  s3FolderName!: string;
  appUser = {} as User;
  analysisMessage: string = '';
  showAlertMessage: boolean = false;
  analysisStatusReload: any;
  alertType = 'success';
  analysisStatusInterval: any;
  analysisStatus: any = [
    {
      name: 'uploadFileStatus',
      value: 'File upload',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },

    {
      name: 'dragon3_9Status',
      value: 'Dragon 3.9',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },

    {
      name: 'basespaceAnalysisTestStatus',
      value: 'Basespace Analysis Test',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'basespaceMountStatus',
      value: 'Basespace Mount',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'cnvStatus',
      value: 'CNV',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },

    {
      name: 'dnaQcStatus',
      value: 'DNA QC',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'annotationStatus',
      value: 'Annotation',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },

    {
      name: 'uploadDirAwsStatus',
      value: 'Upload to AWS',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'downloadDirAwsStatus',
      value: 'Download From AWS CGI',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'cgiStatus',
      value: 'CGI',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'filterEngineStatus',
      value: 'Filter Engine',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'cgiUploadDirAwsStatus',
      value: 'CGI upload to AWS',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
  ];

  analysisStatusFEV2F2both: any = [
    {
      name: 'uploadFileStatus',
      value: 'File upload',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'SequenceAlignmentAndVariantCalling',
      value: 'Sequence alignment and Variant calling',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'dnaQcStatus',
      value: 'DNA QC',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'dnaFusionStatus',
      value: 'DNA Fusion',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'cnvStatus',
      value: 'CNV Analysis',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'variantAnnotation',
      value: 'Variant Annotation',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'variantFilterationAndPrioritization',
      value: 'Variant Filteration and Prioritization',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
  ];

  analysisStatusCDS: any = [
    {
      name: 'uploadFileStatus',
      value: 'File upload',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'SequenceAlignmentAndVariantCalling',
      value: 'Sequence alignment and Variant calling',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'dnaQcStatus',
      value: 'DNA QC',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'cnvStatus',
      value: 'CNV Analysis',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'variantAnnotation',
      value: 'Variant Annotation',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'variantFilterationAndPrioritization',
      value: 'Variant Filteration and Prioritization',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
  ];

  analysisStatusFUS: any = [
    {
      name: 'uploadFileStatus',
      value: 'File upload',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'SequenceAlignmentAndVariantCalling',
      value: 'Sequence alignment and Variant calling',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'dnaQcStatus',
      value: 'DNA QC',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
    {
      name: 'dnaFusionStatus',
      value: 'DNA Fusion',
      icon: 'zmdi-spinner',
      status: '',
      class: '',
    },
  ];

  constructor(
    private labService: LabService,
    private router: Router,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private s3Service: S3ServiceService
  ) {
    this.bucket = new S3({
      accessKeyId: environment.AWS_ACCESS_KEY_ID,
      secretAccessKey: environment.AWS_SECRET_ACCESS_KEY,
      region: environment.AWS_REGION,
    });
  }

  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem('user') || '{}');
    this.getAccessibleEmailIdsList();
    console.log('this.appUser', this.appUser);
    if (environment.apiUrl == 'https://www.v2.api.ecrf.4basecare.co.in') {
      this.s3FolderName = 'prod/' + this.appUser.orgShortName;
    } else {
      this.s3FolderName = 'qa/' + this.appUser.orgShortName;
    }

    this.fileDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 4,
      enableCheckAll: false,
    };

    this.patientId = this.routerAct.snapshot.params['id'];
    if (this.patientId) {
      this.getPatient();
      this.getDocuments();
      this.repeatationStop();
    }
  }

  ngOnDestroy(): void {
    if (this.analysisStatusReload) {
      clearInterval(this.analysisStatusReload);
    }
    if (this.analysisStatusInterval) {
      clearInterval(this.analysisStatusInterval);
    }
  }
  updateDnaAndSomaticTypeDetailsFor4bcPatients() {
    if (this.dnaAndSomaticTypeDataReq != null) {
      console.log('==>', this.dnaAndSomaticTypeDataReq);
      this.labService
        .updateDnaAndSomaticTypeDetailsFor4bcPatients(
          this.dnaAndSomaticTypeDataReq,
          this.patientId
        )
        .subscribe((response: any) => {
          console.log('res-->', response);
          this.reload();
        });
    }
  }

  bindAvailableFilesTypes() {
    console.log('in bindAvailableFilesTypes()');
    this.selectedItems1 = [];
    if (
      this.dnaR1Documents.length > 0 ||
      this.dnaR2Documents.length > 0 ||
      this.rnaR1Documents.length > 0 ||
      this.rnaR2Documents.length > 0
    ) {
      this.fastQ();
      this.selectedItems1.push({ id: 'Fastq', name: 'Fastq' });
    }

    if (this.histoDocuments.length > 0) {
      this.others();
      this.selectedItems1.push({ id: 'Others', name: 'Others' });
    }

    if (this.bamDocuments.length > 0 || this.baiDocuments.length > 0) {
      this.bam();
      this.selectedItems1.push({ id: 'Bam', name: 'Bam' });
    }

    if (this.vcfDocuments.length > 0) {
      this.vcf();
      this.selectedItems1.push({ id: 'VCF', name: 'VCF' });
    }

    this.selectedItems = this.selectedItems1;
  }

  repeatationMethod() {
    const source = interval(20000);
    const text = 'Your Text Here';
    this.subscription = source.subscribe((val) =>
      this.labService
        .getDocuments(this.patientId)
        .subscribe((response: any) => {
          this.documentsList = response['payLoad'];
          console.log('staus=>', this.documentsList);

          this.dnaR1Documents = this.documentsList.filter(function (document) {
            return document.type == 'DNA R1';
          });

          this.dnaR2Documents = this.documentsList.filter(function (document) {
            return document.type == 'DNA R2';
          });

          this.rnaR1Documents = this.documentsList.filter(function (document) {
            return document.type == 'RNA R1';
          });

          this.rnaR2Documents = this.documentsList.filter(function (document) {
            return document.type == 'RNA R2';
          });

          this.histoDocuments = this.documentsList.filter(function (document) {
            return document.type == 'HISTOPATHOLOGY';
          });

          this.vcfDocuments = this.documentsList.filter(function (document) {
            return document.type == 'VCF';
          });

          this.bamDocuments = this.documentsList.filter(function (document) {
            return document.type == 'BAM';
          });

          this.baiDocuments = this.documentsList.filter(function (document) {
            return document.type == 'BAI';
          });
        })
    );
  }

  repeatationStop() {
    this.subscription && this.subscription.unsubscribe();
  }

  onItemSelect(e: any) {
    console.log('selected value - ' + e.id);
    if (e.id == 'Fastq') {
      this.fastQ();
    }

    if (e.id == 'Bam') {
      this.bam();
    }

    if (e.id == 'VCF') {
      this.vcf();
    }

    if (e.id == 'Others') {
      this.others();
    }

    console.log(
      'main status--->',
      this.isFastQ && !this.isuploadModal && this.isRna,
      '\n=>>',
      this.isFastQ,
      !this.isuploadModal,
      this.isRna
    );
  }

  onDeSelect(e: any) {
    console.log('deselected value - ' + e.id);
    if (e.id == 'Fastq') {
      this.fastQ();
    }

    if (e.id == 'Bam') {
      this.bam();
    }

    if (e.id == 'VCF') {
      this.vcf();
    }

    if (e.id == 'Others') {
      this.others();
    }
  }

  isFastQ = false;
  isBam = false;
  isVcf = false;
  isOthers = false;
  fastQ() {
    this.isFastQ = !this.isFastQ;
    console.log(this.isFastQ);
  }
  bam() {
    this.isBam = !this.isBam;
    console.log(this.isBam);
  }
  vcf() {
    this.isVcf = !this.isVcf;
    console.log(this.isVcf);
  }
  others() {
    this.isOthers = !this.isOthers;
    console.log(this.isOthers);
  }

  fileType!: string;
  uploadFileModal(fileType: string) {
    this.repeatationStop();
    this.isuploadModal = !this.isuploadModal;
    this.fileType = fileType;
    console.log(this.isuploadModal);
  }

  file: any;
  onFileChange(event: any) {
    const files = event.target.files;
    const file = files[0];
    console.log(file);
    this.file = file;

    this.patientDocumentReq.title = files[0].name;
    if (this.fileType != null && this.fileType.length > 0) {
      this.patientDocumentReq.type = this.fileType;
      // this.uploadFile();
    }
  }

  dateTime!: number;
  replacedFileName: any;
  uploadFile() {
    this.spinner.show();
    this.isuploadModal = !this.isuploadModal;
    console.log(this.file);
    this.isUploading = true;

    let date = new Date();
    this.dateTime = date.getTime();

    const formdata: FormData = new FormData();
    formdata.append('file', this.file);
    console.log('patientDocumentReq.type', this.patientDocumentReq.type);
    this.patientDocumentReq.patientId = this.patientId;
    if (
      this.isTargetFirst == true &&
      (this.patientDocumentReq.type == 'DNA R1' ||
        this.patientDocumentReq.type == 'DNA R2')
    ) {
      let id = this.ecrfId;
      console.log('id==>', id);
      if (this.patient.dnaKitUsed != null) {
        if (
          this.sampleInfo.somaticType != null &&
          (this.sampleInfo.somaticType == 'FFPE' ||
            this.sampleInfo.somaticType == 'Fresh Frozen')
        ) {
          if (this.patientDocumentReq.type == 'DNA R1') {
            this.replacedFileName =
              id + '-F-D-' + this.patient.dnaKitUsed + '-S1_R1.fastq.gz';
          } else if (this.patientDocumentReq.type == 'DNA R2') {
            this.replacedFileName =
              id + '-F-D-' + this.patient.dnaKitUsed + '-S1_R2.fastq.gz';
          }
        } else if (
          this.sampleInfo.somaticType != null &&
          (this.sampleInfo.somaticType == 'Blood (cfDNA)' ||
            this.sampleInfo.somaticType == 'Slides')
        ) {
          if (this.patientDocumentReq.type == 'DNA R1') {
              this.replacedFileName =
              id + '-B-cf-D-' + this.patient.dnaKitUsed + '-S1_R1.fastq.gz';
          } else if (this.patientDocumentReq.type == 'DNA R2') {
              this.replacedFileName =
              id + '-B-cf-D-' + this.patient.dnaKitUsed + '-S1_R2.fastq.gz';
          }
        }
      }
      console.log('this.fileName=>', this.replacedFileName);
      console.log('this.file.name==>', this.file.name);
      this.patientDocumentReq.documentUrl =
        'https://drjcvfmhd5pya.cloudfront.net/' +
        this.s3FolderName +
        '/' +
        this.dateTime +
        '-' +
        this.replacedFileName;
    } else {
      this.replacedFileName = this.file.name;
      this.patientDocumentReq.documentUrl =
        'https://drjcvfmhd5pya.cloudfront.net/' +
        this.s3FolderName +
        '/' +
        this.dateTime +
        '-' +
        this.file.name;
    }

    this.labService
      .uploadFile(this.patientDocumentReq)
      .subscribe((response: any) => {
        //this.file = {};
        this.isUploading = false;
        console.log('addPatientDocument', response);

        this.uploadFileToS3(
          this.file,
          this.s3FolderName,
          response['payLoad'],
          this.dateTime,
          this.replacedFileName
        );

        this.isFastQ = false;
        this.isBam = false;
        this.isVcf = false;
        this.isOthers = false;
        this.getDocuments();

        this.repeatationMethod();
        this.patientDocumentReq = {} as PatientDocumentReq;
        // this.isuploadModal = !this.isuploadModal;

        this.spinner.hide();
      });
  }

  async uploadFileToS3(
    file: File,
    folderName: string,
    patientDocumentReq: PatientDocumentReq,
    dateTime: number,
    replacedFileName: string
  ) {
    console.log('s3 service patientDocumentReq', patientDocumentReq);

    const params = {
      Bucket: 'oncobench-files',
      Key: folderName + '/' + dateTime + '-' + replacedFileName,
      Body: file,
      ContentType: file.type,
    };

    switch (patientDocumentReq.type) {
      case 'DNA R1':
        this.dnaR1Progress[0].fileName = params.Key;
        break;

      case 'DNA R2':
        this.dnaR2Progress[0].fileName = params.Key;
        break;

      case 'RNA R1':
        this.rnaR1Progress[0].fileName = params.Key;
        break;

      case 'RNA R2':
        this.rnaR2Progress[0].fileName = params.Key;
        break;

      case 'HISTOPATHOLOGY':
        this.histoProgress[0].fileName = params.Key;
        break;

      case 'BAM':
        this.bamProgress[0].fileName = params.Key;
        break;

      case 'BAI':
        this.baiProgress[0].fileName = params.Key;
        break;

      case 'VCF':
        this.vcfProgress[0].fileName = params.Key;
        break;

      default:
        console.log('no file selected................');
        break;
    }

    try {
      const response = await this.bucket
        .upload(params, (err: any, res: any) => {
          if (err != null) {
            patientDocumentReq.status = 'Failed to upload';
            this.labService
              .updatePatientDocumentStatus(patientDocumentReq)
              .subscribe((response:any) => {
                console.log('updatePatientDocumentStatus', response);
              });
          } else {
            patientDocumentReq.status = 'Uploaded';
            this.labService
              .updatePatientDocumentStatus(patientDocumentReq)
              .subscribe((response:any) => {
                console.log('updatePatientDocumentStatus', response);
              });
          }
        })
        .on('httpUploadProgress', (progress: any) => {
          let percentage = this.getUploadingProgress(
            progress.loaded,
            progress.total
          );

          if (this.bamProgress[0].fileName == progress['key']) {
            this.bamProgress[0].percentage = percentage;
          }

          if (this.baiProgress[0].fileName == progress['key']) {
            this.baiProgress[0].percentage = percentage;
          }

          if (this.dnaR1Progress[0].fileName == progress['key']) {
            this.dnaR1Progress[0].percentage = percentage;
          }

          if (this.dnaR2Progress[0].fileName == progress['key']) {
            this.dnaR2Progress[0].percentage = percentage;
          }

          if (this.rnaR1Progress[0].fileName == progress['key']) {
            this.rnaR1Progress[0].percentage = percentage;
          }

          if (this.rnaR2Progress[0].fileName == progress['key']) {
            this.rnaR2Progress[0].percentage = percentage;
          }

          if (this.histoProgress[0].fileName == progress['key']) {
            this.histoProgress[0].percentage = percentage;
          }

          if (this.vcfProgress[0].fileName == progress['key']) {
            this.vcfProgress[0].percentage = percentage;
          }

          console.log(
            'key .. ' + progress['key'] + ' progress.............' + percentage
          );
        });
    } catch (error) {
      console.log('FAILURE', error);
      patientDocumentReq.status = 'Failed to upload';
      this.labService
        .updatePatientDocumentStatus(patientDocumentReq)
        .subscribe((response:any) => {
          console.log('updatePatientDocumentStatus', response);
        });
    }
  }

  getUploadingProgress(uploadSize: any, totalSize: any) {
    let uploadProgress = (uploadSize / totalSize) * 100;
    return Number(uploadProgress.toFixed(0));
  }
  isTargetFirst: boolean = false;
  // isProcessing: boolean = false;
  ecrfId: any;
  sampleInfo = {} as SampleInfoReq;
  ecrfPatientId: any;
  isUpdated = false;
  getPatient() {
    this.reqId.id = this.patientId;
    this.labService.getPatient(this.reqId).subscribe((response: any) => {
      console.log('response==>', response);
      this.patient = response['payLoad'];
      if (this.appUser.is4BaseCare == true) {
        this.sampleInfo.somaticType = this.patient.sampleSourceType;
        this.dnaAndSomaticTypeDataReq.somaticType =
          this.patient.sampleSourceType;
        this.isTargetFirst = true;
        let testName: string[] = [];
        if (this.patient.patientTestPackages != null) {
          this.patient.patientTestPackages.forEach((element: any) => {
            testName.push(element?.testPackageName);
          });
          this.patient.testPackageName = testName.toString();
        }
      } else {
        this.sampleInfo = response['bio'];
      }
      this.ecrfId = response['payLoad']['patientId'];
      console.log('get patient', this.patient);
      if (response['bio'] != null) {
        this.somaticType = response['bio'].somaticType;
        this.registrationOn = response['bio'].sampleRegistrationOn;
        // console.log("HIII",moment(this.registrationOn, "YYYY-MM-DD").format('MMMM_YY'))
      }
      this.dnaAndSomaticTypeDataReq.dnaKitUsed = this.patient.dnaKitUsed;
      if (
        this.dnaAndSomaticTypeDataReq.dnaKitUsed != null &&
        this.dnaAndSomaticTypeDataReq.somaticType != null
      ) {
        this.isUpdated = true;
      }
      if (this.appUser.organisation == 'Lifecell') {
        this.ecrfPatientId = this.patient.orgLabId;
      } else if (this.appUser.organisation == '4baseCare Onco Solutions') {
        this.ecrfPatientId = this.patient.patientId;
      } else {
        this.ecrfPatientId = this.patient.organisationPatientId;
      }
      if (
        this.patient.testPackageName == 'TarGT First' || this.patient.testPackageName =='Target First' ||
        this.appUser.is4BaseCare
      ) {
        this.isTargetFirst = true;
        if (
          this.patient.oncobenchAnalysisStatus != null &&
          this.patient.oncobenchAnalysisStatus == 'COMPLETED'
        ) {
          this.isTargetFirst = false;
          this.showAlertMessage = true;
          this.alertType = 'success';
          this.analysisMessage = 'Analysis Completed';
          if (this.analysisStatusReload) {
            clearInterval(this.analysisStatusReload);
          }
        }
        if (
          this.patient.oncobenchAnalysisStatus != null &&
          this.patient.oncobenchAnalysisStatus == 'STARTED'
        ) {
          // this.isStartAnalysisDisabled = true;
          this.showAlertMessage = true;
          this.alertType = 'warning';
          this.analysisMessage = 'Analysis in process';
          this.analysisStatusReload = setInterval(() => {
            this.reload();
          }, 300000);
        }
        if (
          this.patient.oncobenchAnalysisStatus != null &&
          this.patient.oncobenchAnalysisStatus == 'FAILED'
        ) {
          // this.isStartAnalysisDisabled = false;
          this.showAlertMessage = true;
          this.alertType = 'danger';
          this.analysisMessage =
            'Analysis is failed you can start again or contact to team';
          if (this.analysisStatusReload) {
            clearInterval(this.analysisStatusReload);
          }
        }
        if (this.patient.oncobenchAnalysisStatus == null) {
          // this.isStartAnalysisDisabled = false;
        }
      } else {
        this.isTargetFirst = false;
      }

      if (this.patient.testPackageName == 'TarGt Indiegene Solid') {
        this.isDna = true;
        this.isRna = true;
      } else {
        this.isDna = true;
        this.isRna = false;
      }
      if (this.patient.patientDocumentStatus == 'Sample Registered') {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }

      // if (this.patient.patientDocumentStatus == "Report Processing" || this.patient.patientDocumentStatus == 'Report Generated') {
      //   this.isDisabled = true;
      // }else{
      //   this.isDisabled=false
      // }
    });
  }

  reload() {
    window.location.reload();
  }
  // isStartAnalysisDisabled: boolean = false;




  showSuccess(message: string) {
    this.toastr.success(message, '', { timeOut: 2000 });
  }

  getDocuments() {
    console.log('getDocuments method');

    this.labService.getDocuments(this.patientId).subscribe((response: any) => {
      this.documentsList = response['payLoad'];
      console.log(this.documentsList);

      if (
        (this.documentsList.length > 0 &&
          this.patient.patientDocumentStatus != 'Report Processing') ||
        this.patient.patientDocumentStatus !== 'Report Generated'
      ) {
        // this.isDisabled = false;

        this.dnaR1Documents = this.documentsList.filter(function (document) {
          return document.type == 'DNA R1';
        });

        this.dnaR2Documents = this.documentsList.filter(function (document) {
          return document.type == 'DNA R2';
        });

        this.rnaR1Documents = this.documentsList.filter(function (document) {
          return document.type == 'RNA R1';
        });

        this.rnaR2Documents = this.documentsList.filter(function (document) {
          return document.type == 'RNA R2';
        });

        this.histoDocuments = this.documentsList.filter(function (document) {
          return document.type == 'HISTOPATHOLOGY';
        });

        this.vcfDocuments = this.documentsList.filter(function (document) {
          return document.type == 'VCF';
        });

        this.bamDocuments = this.documentsList.filter(function (document) {
          return document.type == 'BAM';
        });

        this.baiDocuments = this.documentsList.filter(function (document) {
          return document.type == 'BAI';
        });

        this.bindAvailableFilesTypes();
      }
      let analysisFlag: boolean = false;
      if (
        this.dnaR1Documents &&
        this.dnaR1Documents.length > 0 &&
        this.dnaR1Documents[0].status == 'Uploaded'
      ) {
        // if(this.dnaR1Documents[0].documentUrl.includes('.fastq.gz')){
        //   analysisFlag=true
        // }
        analysisFlag = true;
      }
      if (
        this.dnaR2Documents &&
        this.dnaR2Documents.length > 0 &&
        this.dnaR2Documents[0].status == 'Uploaded'
      ) {
        // if(this.dnaR2Documents[0].documentUrl.includes('.fastq.gz')){
        //   analysisFlag=true
        // }
        analysisFlag = true;
      }


    });
    console.log('dnaR2Documents==>', this.dnaR2Documents);
    console.log('dnaR1Documents==>', this.dnaR1Documents);
  }

  //confirmation popup.
  confirm() {
    let swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success mr-2',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'you want to submit for processing?',
        icon: 'warning',
        showCancelButton: true,
        backdrop: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
      })
      .then((result) => {
        if (result.isConfirmed) {
          //click on yes then.
          this.submitUploadedData();
        }
      });
  }
  reportsEmailList: reportsEmailDto[] = [];
  reportsEmail: string[] = [];
  isAccessAllowedButtom = false;
  getAccessibleEmailIdsList() {
    this.labService.getAccessibleEmailIdsList().subscribe((res: any) => {
      console.log('=>', res['payLoad']);
      this.reportsEmailList = res['payLoad'];
      for (let i = 0; i < this.reportsEmailList.length; i++) {
        this.reportsEmail.push(this.reportsEmailList[i]?.emailId);
      }
      if (this.reportsEmail.includes(this.appUser.email)) {
        this.isAccessAllowedButtom = true;
      }
    });
  }

  submitUploadedData() {
    console.log('submitUploadedData');
    this.labService.ChangePatientStatus(this.patient.id).subscribe(
      (response: any) => {
        console.log(response);
        this.toastr.success(response['message']);
        // this.getPatient();
        this.router.navigate(['/patients']);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
