import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { patientListDto, PaginationReqInterface } from 'src/app/_interfaces/patient.model';
import { User } from 'src/app/_interfaces/auth.model';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from '@progress/kendo-file-saver';

import { Patient, PatientListDto, ReqId, reportsEmailDto } from 'src/app/_interfaces/lab-model';
import { LabService } from 'src/app/_services/oncobench-service/lab.service';
export interface SampleDetails {
  title: string;
  dataValue: number;
}


@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss']
})
export class PatientsListComponent implements OnInit {
  paginationReq = {} as PaginationReqInterface;
  patientList!: PatientListDto[];
  reqId = {} as ReqId;
  patient = {} as Patient;
  appUser!: User;
  sampleDetails: SampleDetails[] = [];
  patientSpinner = true;
  totalPages!: number;
  currentPage!: number;
  length!: number;
  collectionSize!: number;

  doctorName = "";

  constructor(
    private labService: LabService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer

  ) { }

  statusMsg!: string;
  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  showError() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 3000 }
    );
  }


  downloadFinalReport(url: string) {
    window.open(url);
  }


  workbenchUrl!: string;
  reportsEmail:string[] = [];
  reportsEmailList:reportsEmailDto[] = [];
  isReportButtom = false;
  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem("user") || '{}');
    this.getAccessibleEmailIdsList();
    console.log("reportsEmail=>",this.reportsEmail)
    this.paginationReq.pageSize = 30;
    this.paginationReq.reqPageNo = 0;
    this.paginationReq.sortBy = "lastModified";
    this.paginationReq.sortOrder = "DESC";
    this.getPatientList();
    this.workbenchUrl = 'http://52.66.98.251:9500';

  }
  getAccessibleEmailIdsList() {
    this.labService.getAccessibleEmailIdsList().subscribe((res: any) => {
      console.log("=>",res['payLoad']);
      this.reportsEmailList=res['payLoad'];
      for(let i=0;i<this.reportsEmailList.length;i++){
        this.reportsEmail.push(this.reportsEmailList[i]?.emailId)
      }
      if(this.reportsEmail.includes(this.appUser.email)){
        this.isReportButtom = true;
      }
    });
  }
  changePage(event: any) {
    this.patientSpinner=true
    console.log("event", event);
    this.patientList=[];
    this.currentPage = event;
    this.paginationReq.reqPageNo = this.currentPage - 1;
    this.getPatientList();
  }

  getPatientList() {
    if (this.currentPage == null) {
      this.currentPage = 0;
      this.paginationReq.reqPageNo = this.currentPage;
    }
    this.paginationReq.isMainList=true;
    this.labService.getPatientList( this.paginationReq).subscribe(
      (data :any) => {
        this.patientSpinner = false;
        this.patientList = data["payLoad"]["patientList"];

        console.log("this.patientList", data);
        this.length = this.patientList?.length;
        this.totalPages = data["payLoad"]["totalPages"];
        this.currentPage = data["payLoad"]["currentPage"] + 1;
        this.collectionSize = this.length * this.totalPages;
      },
      (error:any) => {
        console.log(error);
      }
    );
  }

  deletePatientId!: any;

  //confirmation popup.
  confirm(patientId: number) {
    this.deletePatientId = patientId;
    let swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success mr-2',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "you want to Delete patient?",
      icon: 'warning',
      showCancelButton: true,
      backdrop:true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        //click on yes then.
        this.deletePatient();
      } else {
        this.deletePatientId = undefined;
      }
    });
  }


  deletePatient(){
    console.log(this.deletePatientId);
    this.labService.deletePatient(this.deletePatientId).subscribe(
      (response : any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.deletePatientId = undefined;
        this.showSuccess();
        this.getPatientList();
      },
      (error: any) => {
        console.log(error);
        this.statusMsg = error['message'];
        this.showError();
      }
    );

  }
  url!:any;

  downloadRecord(url:any){
    let filename:any
    let fileFormat:any
    if(url){
      filename=url.split("/").pop()
    }
    fileFormat=filename.split('.').pop().toLowerCase()
    if(fileFormat=='pdf'){
      this.labService.downloadRecord(url).subscribe((res:any)=>{
        let blob= new Blob([res], { type: 'application/pdf' })
       saveAs(blob,filename)
      })
    }else{
        window.open(url);
    }
  }
  public modelChange(str:any): void {
    console.log(str.target.value);
    this.paginationReq.searchKey = str.target.value;
    console.log(this.paginationReq);
    this.getPatientList();
  }
  // getPatient(pid:any) {
  //   this.reqId.id = pid;
  //   this.labService.getPatient(this.reqId).subscribe((response) => {
  //     console.log("patient info", response);

  //     this.patient = response["payLoad"];
  //     this.patient.ffpeBlockValues = response["bio"]["ffpeBlockValues"];
  //     this.doctorName = response["payLoad"]["medical"]["doctorName"];
  //     this.registrationMessage();
  //   });
  // }
}
