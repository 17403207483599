<!-- <link
href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
rel="stylesheet"
integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
crossorigin="anonymous"
/>
<link
rel="stylesheet"
href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"
/> -->
<h6 class="clr_text2">SNV/InDels</h6>
<div class="slected_rows ">

  <div class="toggle_main" *ngIf="showDiv">
    <label class="switch">
      <span class="left">Show all</span>
      <span class="right">Selected Rows</span>
      <input type="checkbox" (click)="switchToggle($event)">
      <span class="slider round"></span>
    </label>
  </div>
</div>
<div class="slected_rows mt-1">
  <div class="levels_section row py-3 rounded mx-0">
    <div class="col">
      <p class="flex-container">
        <span class="pinkCircle"></span>
        <span style="padding-left: 5px!important;">Pathogenic</span>
      </p>
    </div>
    <div class="col text-right">
      <p class="flex-container">
        <span class="yellowCircle"></span>
        <span style="padding-left: 5px!important;">VUS</span>
      </p>
    </div>
    <div class="col text-right">
      <p class="flex-container">
        <span class="greenCircle"></span>
        <span style="padding-left: 5px!important;">Benign</span>
      </p>
    </div>
    <div class="col text-right">
      <p class="flex-container">
        <span class="greyCircle"></span>
        <span style="padding-left: 5px!important;">Unknown/Undefined</span>
      </p>
    </div>
  </div>
</div>

<table class="table ">
  <thead>
    <tr style="background-color:#d0e9f0">
      <th></th>
      <th>Gene</th>
      <th>Variant</th>
      <th>Variant Detail</th>
      <th>Population Frequency</th>
      <th>Clinical Significance</th>
      <!-- <th>Reference</th> -->
      <th>OB Predictions</th>
      <th>In-Silico Predictions</th>
      <th>Actions</th>
      <th class="item pri_order" *ngIf="switchToggleButton"><span>Priority Order</span></th>
    </tr>
  </thead>
  <div *ngIf="variantsListSpinner" class="w-100 spinner_position">
    <div class="spinner-border  m-auto d-block" role="status">
      <span></span>
    </div>
  </div>

  <tbody *ngIf="!variantsListSpinner">
    <ng-container  *ngFor="let item of filteredCSV; let i = index">
      <tr>
        <td style="background-color:rgb(195, 193, 193)" (click)="toggleExpand(item)">
          <i *ngIf="!item.isExpand" class="bi-chevron-right"></i>
          <i *ngIf="item.isExpand" class="bi-chevron-up"></i>
        </td>
        <td><i>{{ item.refGeneX ? item.refGeneX.split(';')[0] : item.refGeneX }}</i></td>
        <td style="word-break: break-word;overflow-wrap: break-word;"><p style="font-size: 12px!important;">{{ item?.genomicAlteration }}</p><p>{{ item?.exonicFuncknownGene }}</p></td>
        <td><p>{{ item?.pdot }}</p><p style="word-break: break-word;overflow-wrap: break-word;">{{ item.cdot }}</p></td>
        <td><span><p><b>gnomAD</b>: {{ item?.genome_ALL }}</p><p><b>1000g</b>: {{ item?.kg2015AugAll }}</p></span></td>
        <!-- <td class="item" style="word-break: break-word;overflow-wrap: break-word;">
          <span>
            <p>{{item.clinvar}}</p><p *ngIf="item.avsnp150!=null && item.avsnp150!='.'">{{item.avsnp150}}</p>
          </span>
          <span *ngIf="item.isExpand">
            <p></p>
          </span>
        </td> -->
        <td>
          <ul class="clr_letters d-flex pl-0">

            <!-- clinvar -->
            <li *ngIf="item.clinvar == ('Pathogenic') ||
            item.clinvar == ('Pathogenic,_protective') ||
            item.clinvar == ('Pathogenic/ Likely Pathogenic') ||
            item.clinvar == ('Pathogenic|drug_response|other') ||
            item.clinvar == ('Likely_pathogenic') ||
            item.clinvar == ('drug_response')" class="pink"  title="ClinVar">CL</li>
            <li *ngIf="item.clinvar == ('Likely_benign') ||
            item.clinvar == ('Benign/Likely_benign,_risk_factor') ||
            item.clinvar == ('Benign/Likely_benign,_protective') ||
            item.clinvar == ('Benign/Likely_benign') ||
            item.clinvar == ('Likely_benign,_drug_response,_other') ||
            item.clinvar == ('Benign,_association') ||
            item.clinvar == ('Likely_benign,_other') ||
            item.clinvar == ('Likely_benign,_other') ||
            item.clinvar == ('Benign')" class="green" title="ClinVar">CL</li>
            <li *ngIf="item.clinvar == ('Conflicting_interpretations_of_pathogenicity') ||
            item.clinvar == ('Conflicting_interpretations_of_pathogenicity,_other') ||
            item.clinvar == ('Conflicting_interpretations_of_pathogenicity,_Affects') ||
            item.clinvar == ('association,_risk_factor') ||
            item.clinvar == ('risk_factor') ||
            item.clinvar == ('Uncertain_significance') ||
            item.clinvar == ('association') ||
            item.clinvar == ('Affects,_risk_factor') ||
            item.clinvar == ('Affects,_association') ||
            item.clinvar == ('protective') ||
            item.clinvar == ('Affects') " class="yellow" title="ClinVar">CL</li>
            <li *ngIf="item.clinvar == ('not_provided') ||
            item.clinvar == ('not_provided') ||
            item.clinvar == ('UNK') " class="grey" title="ClinVar">CL</li>
            <!-- cancerVar -->
            <li *ngIf="item.cancerVar>=11 || (item.cancerVar>=8 && item.cancerVar<=10)" class="pink" title="CancerVar">C</li>
            <li *ngIf="item.cancerVar>=3 && item.cancerVar<8" class="yellow" title="CancerVar">C</li>
            <li *ngIf="item.cancerVar <=2" class="green" title="CancerVar">C</li>
            <!-- interVarAutomated  -->
            <li *ngIf="item.interVarAutomated =='PATHOGENIC' || item.interVarAutomated =='LIKELY_PATHOGENIC'" class="pink" title="Intervar">I</li>
            <li *ngIf="item.interVarAutomated =='BENIGN' " class="green" title="Intervar">I</li>
            <li *ngIf="item.interVarAutomated =='UNCERTAIN_SIGNIFICANCE' || item.interVarAutomated =='LIKELY_BENIGN'" class="yellow" title="Intervar">I</li>
            <li *ngIf="item.interVarAutomated =='.'" class="grey" title="Intervar">I</li>
          </ul>
        </td>
        <!-- <td class="item" style="word-break: break-word;overflow-wrap: break-word;">
          <span>
            <p *ngIf="item.avsnp150!=null && item.avsnp150!='.'">{{item.avsnp150}}</p>
          </span>
          <span *ngIf="item.isExpand">
            <p></p>
          </span>
        </td> -->
        <!-- <td><span><p>{{ item.avsnp150 }}</p></span></td> -->
        <td>
          <ul class="clr_letters d-flex pl-0">
            <!-- tagIdenifier -->
            <li *ngIf="item.tagIdenifier == 'Pathogenic'" class="pink" title="TARGT Identifier">TI</li>
            <li *ngIf="item.tagIdenifier == '-'" class="green" title="TARGT Identifier">TI</li>
            <li *ngIf="item.tagIdenifier == 'Likely Pathogenic' || item.tagIdenifier == 'VUS' || item.tagIdenifier == 'Likely Pathogenic/VUS'" class="yellow" title="TARGT Identifier">TI</li>
            <!-- clnsig -->
            <li *ngIf="item.clnsig == 'DRUG RESPONSE' || item.clnsig == 'RISK FACTOR' || item.clnsig == 'DRUG RESPONSE/PATHOGENIC' || item.clnsig == 'LIKELY PATHOGENIC' || item.clnsig == 'PATHOGENIC; DRUG RESPONSE' || item.clnsig == 'PATHOGENIC'" class="pink" title="ClinSig Inhouse Knowledge base">Kb</li>
            <!-- <li *ngIf="item.clnsig == '.'" class="grey" title="ClinSig Inhouse Knowledge base">Kb</li> -->
            <li *ngIf="item.clnsig == 'UNCERTAIN SIGNIFICANCE' || item.clnsig == 'VUS'" class="yellow" title="ClinSig Inhouse Knowledge base">Kb</li>

          </ul>
        </td>
        <td>
          <ul class="clr_letters d-flex pl-0">
            <!-- siftPred -->
            <li *ngIf="item.siftPred == 'D'" class="pink" title="SIFT">S</li>
            <li *ngIf="item.siftPred == 'T'" class="green" title="SIFT">S</li>
            <li *ngIf="item.siftPred != 'D' && item.siftPred != 'T'" class="grey" title="SIFT">S</li>
            <!--polyphen2HvarPred  -->
            <li *ngIf="item.polyphen2HvarPred == 'B' || item.polyphen2HvarPred == 'D'" class="pink" title="Polyphen">P</li>
            <li *ngIf="item.polyphen2HvarPred == 'P'" class="green" title="Polyphen">P</li>
            <li *ngIf="item.polyphen2HvarPred != 'B' && item.polyphen2HvarPred != 'D' && item.polyphen2HvarPred != 'P'" class="grey" title="Polyphen">P</li>
            <!-- mutationTasterPred -->
            <li *ngIf="item.mutationTasterPred == 'A' || item.mutationTasterPred == 'D'" class="pink" title="Mutation Taster">M</li>
            <li *ngIf="item.mutationTasterPred == 'N' || item.mutationTasterPred == 'P'" class="green" title="Mutation Taster">M</li>
            <li *ngIf="item.mutationTasterPred != 'A' && item.mutationTasterPred != 'D' && item.mutationTasterPred != 'N' && item.mutationTasterPred != 'P'" class="grey" title="Mutation Taster">M</li>
          </ul>
        </td>
        <td>
          <ul class="actions_list pl-0 d-flex align-items-center">
            <li>
              <a (click)="seletedRow(item.selectedForReport, item)" *ngIf="!item.selectedForReport"><i class="zmdi zmdi-bookmark-outline"></i></a>
              <a (click)="seletedRow(item.selectedForReport, item)" *ngIf="item.selectedForReport"><i style="color: #2292cf;" class="zmdi zmdi-bookmark"></i></a>
            </li>
            <li *ngIf="item.selectedForReport">
              <a (click)="seletedStarRow(item.selectedStarForReport, item)" *ngIf="!item.selectedStarForReport"><i class="zmdi zmdi-star-outline"></i></a>
              <a (click)="seletedStarRow(item.selectedStarForReport, item)" *ngIf="item.selectedStarForReport"><i style="color: #2292cf;" class="zmdi zmdi-star"></i></a>
            </li>
            <li *ngIf="!item.selectedForReport">
              <a *ngIf="report.oncobenchAnalysisStatus == 'COMPLETED'" href="{{igvUrl}}/home/{{ item.id }}" target="_blank">View <span class="igv">IGV</span></a>
              <a [routerLink]="['/wb', reportId, 'snv', item.id]" target="_blank">View Details</a>
            </li>
            <li *ngIf="item.selectedForReport" class="px-0 act_view-det">
              <a *ngIf="report.oncobenchAnalysisStatus == 'COMPLETED'" href="{{igvUrl}}/home/{{ item.id }}" target="_blank">View <span class="igv">IGV</span></a>
              <a [routerLink]="['/wb', reportId, 'snv', item.id]" target="_blank">View Details</a>
            </li>
          </ul>
        </td>
        <td class="item pri_order" *ngIf="switchToggleButton">
          <input type="number" class="form-control" name="priorityOrderNumber_{{ i }}" [(ngModel)]="item.priorityOrder" (change)="priorityOrder(item.priorityOrder, item.id)" />
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
