<div class=" main_wrapper ">
  <div class="sidebar">
    <app-side-nav></app-side-nav>
  </div>
  <div class="content-wrapper">
    <div class="top_bar">
      <app-header></app-header>
    </div>

    <div class="main_content">
      <div class="view_snv-details">
        <div class="d-flex justify-content-end"
          *ngIf="this.clinicalTrialResList!=null && this.clinicalTrialResList.length>0">
          <a [routerLink]="[ '/wb/snv_trail_list', reportedVariantId]"
          class="drug_title">Select Hotspot Drugs</a>
        </div>
        <div class="d-flex justify-content-end"
          *ngIf="this.clinicalTrialResList==null || this.clinicalTrialResList.length<=0">
          <a  class="drug_title">No Clinical Trials</a>
        </div>
        <div *ngIf="snvSpinner" class="w-100 spinner_position">
          <div class="spinner-border  m-auto d-block" role="status">
            <span></span>
          </div>
        </div>
        <h6 class="clr_text"><span style="font-size: 20px!important;">SNV Variant Details : </span>
          {{ecrfPatientId}}
          <span>[<i>{{reportedVariant?.refGeneX}}</i>
            <span *ngIf="reportedVariant.pdot != null">
              ({{reportedVariant.pdot}})</span>]</span>
        </h6>

        <div class="snv_info mt-4">
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Gene</p>
            </div>
            <div class="col w-adjust">
              <div class="form-group">
                <!-- <label for=""></label> --><i>
                <input type="text" class="form-control" style="font-style: italic!important;" [(ngModel)]="reportedVariant.refGeneX" [disabled]="true"></i>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Variant</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="" class="">Chromosome Number</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.chrom" >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Start position</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.pos" >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">End position</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.end" >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Reference_x</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.refX" >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Alteration_x</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.altX" >
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Consequences</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Variant Consequences</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.funcknownGene" >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Variant Type</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.exonicFuncknownGene" >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">c. position</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.cdot" >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">p. position</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.pdot" >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Exon No.</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.exonNumber" >
                  </div>
                </div>
                <div class="w-100 mb-3"></div>

                <div class="col w-adjust">
                  <div class="form-group">
                    <label for="">Gene Role</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.role" >
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Clinical Significance</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">rs ID</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.avsnp150" [disabled]="true">
                  </div>
                </div>
                <!-- <div class="col">
                  <div class="form-group">
                    <label for="">VCV ID</label>
                    <input type="text" class="form-control">
                  </div>
                </div> -->
                <div class="col">
                  <div class="form-group">
                    <label for="">clinvar</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.clinvar" [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">intervar Inhouse</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.intervarInhouse" [disabled]="true">
                  </div>
                </div>
                <!-- <div class="col">
                  <div class="form-group">
                    <label for="">clndisdb</label>
                    <input type="text" class="form-control">
                  </div>
                </div> -->
                <!-- <div class="w-100 mb-3"></div> -->

                <!-- <div class="col w-adjust">
                  <div class="form-group">
                    <label for="">Annovar</label>
                    <input type="text" class="form-control">
                  </div>
                </div> -->

                <div class="col w-adjust">
                  <div class="form-group">
                    <label for="">interVarAutomated</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.interVarAutomated" [disabled]="true">
                  </div>
                </div>

                <div class="col w-adjust">
                  <div class="form-group">
                    <label for="">cancerVar</label>
                    <input type="text" class="form-control" [(ngModel)]="cancerVar" [disabled]="true">
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Population Frequency</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">esp6500siv2All</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.esp6500siv2All"
                      [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">exAC_ALL</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.exAC_ALL" [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">exAC_SAS</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.exAC_SAS" [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">af</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.af" [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">afSas</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.afSas" [disabled]="true">
                  </div>
                </div>
                <div class="w-100 mb-3"></div>
                <div class="col">
                  <div class="form-group">
                    <label for="">kg2015AugAll</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.kg2015AugAll"
                      [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">kg2015AugSAS</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.kg2015AugSAS"
                      [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Gnomad ALL</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.genome_ALL"
                    [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Gnomad EAS</label>
                    <input type="text" class="form-control"  [(ngModel)]="reportedVariant.genome_EAS"
                    [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <!-- <div class="form-group">
                    <label for="">1000genomes</label>
                    <input type="text" class="form-control">
                  </div> -->
                </div>
              </div>

            </div>

          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Insilico Prediction</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">CADD Score</label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.cadd13Phred" [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">SIFT
                      <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
                        <span class="tooltiptext">
                          <ul class="mb-0 list-unstyled">
                            <li>Deleterious - D</li>
                            <li>Tolerated - T</li>
                            <li>Unknown - </li>
                          </ul>
                        </span>
                      </div>
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.siftPred" [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Polyphen
                      <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
                        <span class="tooltiptext">
                          <ul class="mb-0 list-unstyled">
                            <li>Probably Damaging - B</li>
                            <li>Possibly Damaging - D</li>
                            <li>Benign -  P  </li>
                            <li>Unknown - </li>
                          </ul>
                        </span>
                      </div>
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.polyphen2HvarPred"
                      [disabled]="true">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Mutation Taster
                      <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
                        <span class="tooltiptext">
                          <ul class="mb-0 list-unstyled">
                            <li>disease_causing_automatic - A</li>
                            <li>disease_causing  - D</li>
                            <li>polymorphism -  N  </li>
                            <li>polymorphism_automatic - P</li>
                            <li>Unknown - </li>
                          </ul>
                        </span>
                      </div>
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.mutationTasterPred"
                      [disabled]="true">
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label for="">FATHMM
                      <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
                        <span class="tooltiptext">
                          <ul class="mb-0 list-unstyled">
                            <li>Deleterious - D</li>
                            <li>Tolerated - T</li>
                            <li>Unknown - </li>
                          </ul>
                        </span>
                      </div>
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.FATHMM_pred"
                      [disabled]="true">
                  </div>
                </div>
                <div class="w-100 mb-3"></div>

                <div class="col w-adjust">
                  <div class="form-group">
                    <label for="">MetaSVM
                      <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
                        <span class="tooltiptext">
                          <ul class="mb-0 list-unstyled">
                            <li>Deleterious - D</li>
                            <li>Tolerated - T</li>
                            <li>Unknown - </li>
                          </ul>
                        </span>
                      </div>
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.MetaSVM_pred"
                      [disabled]="true">
                  </div>
                </div>

                <div class="col w-adjust">
                  <div class="form-group">
                    <label for="">MetaLR
                      <div class="tooltip"><i class="zmdi zmdi-info font-20"></i>
                        <span class="tooltiptext">
                          <ul class="mb-0 list-unstyled">
                            <li>Deleterious - D</li>
                            <li>Tolerated - T</li>
                            <li>Unknown - </li>
                          </ul>
                        </span>
                      </div>
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="reportedVariant.MetaLR_pred"
                      [disabled]="true">
                  </div>
                </div>
                <div class="col">

                </div>
              </div>

            </div>
          </div>

                    <!--  -->
                    <div class="row pt-3 pb-4 border_line">
                      <div class="col-2">
                        <p class="clr_text">Other information</p>
                      </div>
                      <div class="col">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label for="">Ensemble Value</label>
                              <input type="text" class="form-control" [(ngModel)]="reportedVariant.ensembleValue"
                                [disabled]="true">
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label for="">IGV Link</label>
                              <input type="text" class="form-control" [(ngModel)]="reportedVariant.igvLink" [disabled]="true">
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label for="">Genomic Alteration</label>
                              <input type="text" class="form-control" [(ngModel)]="reportedVariant.genomicAlteration"
                                [disabled]="true">
                            </div>
                          </div>
                          <!-- <div class="col">
                            <div class="form-group">
                              <label for="">Cosmic ID</label>
                              <input type="text" class="form-control">
                            </div>
                          </div> -->
                          <div class="col">
                            <div class="form-group">
                              <label for=""> Gene Role</label>
                              <input type="text" class="form-control" [(ngModel)]="reportedVariant.role"
                              [disabled]="true">
                            </div>
                          </div>
                          <!-- <div class="w-100 mb-3"></div> -->
                          <div class="col w-adjust">
                            <div class="form-group">
                              <label for="">Functional Domain</label>
                              <input type="text" class="form-control" [(ngModel)]="reportedVariant.functionalDomain"
                              [disabled]="true">
                              <br>
                            </div>
                          </div>
                        
                          <!-- <div class="col">
                      <div class="form-group">
                        <label for=""></label>
                        <input type="text" class="form-control" placeholder="Description" [(ngModel)]="reportedVariant.">
                      </div>
                    </div> -->
                        </div>
                        <div *ngIf="domainFunction?.domainFunction" class="col">
                          <div class="form-group">
                            <label for="">Functional Domain Description</label>
                            <p>{{plainTextDomainFunction}}</p>
                          </div>
                        </div>

                      </div>

                    </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line" *ngIf="isFirst72">
            <div class="col-2">
              <p class="clr_text">Prognostic</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Prognostic Significance</label>
                    <select class="form-control" name="significance"
                      [(ngModel)]="reportedVariant.prognasticSignificance" (ngModelChange)="getPrognasticTag($event)">
                      <option value="Poor">Poor</option>
                      <option value="Good">Good</option>
                      <option value="NA">NA</option>

                    </select>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label for="">Prognostic Tag</label>
                    <ng-multiselect-dropdown [settings]="prognasticBiomarkerDropdownSettings" [data]="prognasticTagList"
                      [(ngModel)]="selectedPrognasticBiomarker" (ngModelChange)="selectPrognasticBiomarker($event)"
                      name="prognasticTag"></ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="w-100 mb-3"> </div>
                <div class="col">
                  <div class="form-group">
                    <!-- <label for="">mutationTasterPred</label> -->
                    <!-- <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Discription"></textarea> -->

                    <label for="">Add Prognostic Description</label>
                    <!-- <textarea type="text" class="form-control" style="height: 200px;" name="prognasticDescription"
                  [(ngModel)]="reportedVariant.prognasticDescription"></textarea> -->
                    <div
                      *ngIf="reportedVariant.prognasticDescription!=null ||reportedVariant.prognasticDescription!=undefined">
                      <P *ngIf="reportedVariant.prognasticDescription.length">
                        <strong>[{{editorText?.length}}/2000]</strong></P>
                    </div>
                    <ckeditor [editor]="editor"
                      [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                      name="addPrognasticDescription" [(ngModel)]="reportedVariant.prognasticDescription"
                      (keyup)="textcount('addPrognasticDescription-editor')"></ckeditor>
                    <div class="addPrognasticDescription-editor" hidden="true"
                      [innerHtml]="reportedVariant.prognasticDescription"></div>
                    <p class="pt-2"><strong>*[</strong>A<sup>b</sup> →
                      {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>

                  </div>
                </div>

              </div>

            </div>
          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line" *ngIf="reportedVariant.significance!='NA'">
            <div class="col-2">
              <p class="clr_text">Depth</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Total Depth</label>
                    <input type="text" class="form-control" name="bamDepth" [(ngModel)]="totalDept">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Alternative Allele Depth</label>
                    <input type="text" class="form-control" name="bam_ALT_Depth" [(ngModel)]="alternativeAlleleDepth">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Allelic burden (%)</label>
                    <input type="text" class="form-control" name="allelicBurden" [(ngModel)]="allelicBurden">
                  </div>
                </div>
                <!-- <div class="col"></div>
        <div class="col"></div> -->

              </div>

            </div>
          </div>
          <!--  -->
          <div class="row pt-3 pb-4 border_line">
            <div class="col-2">
              <p class="clr_text">Gene</p>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Clinical Significance</label>

                    <select class="form-control" name="significance" [(ngModel)]="reportedVariant.significance"
                      (click)="getGeneDescription()">
                      <option value="Benign">Benign</option>
                      <option value="Pathogenic">Pathogenic</option>
                      <option value="Likely Pathogenic">Likely Pathogenic</option>
                      <option value="VUS">VUS</option>
                      <option value="Not Classified">Not Classified</option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Reference</label>
                    <input type="text" class="form-control" name="reference" [(ngModel)]="reportedVariant.reference">
                  </div>
                </div>
                <div class="w-100 mb-3"> </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Gene Description (<i>{{gene?.name}}</i>)</label>
                    <div style="font-size: small;" [innerHtml]="gene?.description"></div><br/>
                    <!-- <textarea type="text" class="form-control" style="height: 200px;" name="addGeneDescription"
                  [(ngModel)]="reportedVariant.addGeneDescription"></textarea> -->
                    <div
                      *ngIf="reportedVariant.addGeneDescription!=null ||reportedVariant.addGeneDescription!=undefined">
                      <P *ngIf="reportedVariant.addGeneDescription.length"><strong>[{{editorText.length}}/2000]</strong>
                      </P>
                    </div>
                    <ckeditor [editor]="editor"
                      [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                      name="addGeneDescription" [(ngModel)]="reportedVariant.addGeneDescription"
                      (keyup)="textcount('addGeneDescription-editor')"></ckeditor>
                    <div class="addGeneDescription-editor" hidden="true"
                      [innerHtml]="reportedVariant.addGeneDescription"></div>
                    <p class="pt-2"><strong>*[</strong>A<sup>b</sup> →
                      {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
                  </div>
                </div>
                <div class="w-100 mb-3"> </div>

                <div class="col">
                  <div class="form-group">
                    <label for="">PMIDS:</label>
                    <input *ngIf="isFirst72" type="text" class="form-control"
                      placeholder="separate each pmId by comma(,)" name="pmIds"
                      [(ngModel)]="reportedVariant.variantPmIds">
                    <input *ngIf="!isFirst72" type="text" class="form-control"
                      placeholder="separate each pmId by comma(,)" name="pmIds" [(ngModel)]="pmIds">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--  -->

          <!--  -->
          <div class="save_buton py-3 ">
            <button type="button" [disabled]="isSubmited" (click)="submitDetails()" class="btn btn_cyan float-right">
              <span *ngIf="!isSubmited" style="color: white;"> Save </span>
              <div *ngIf="isSubmited" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
