<div class="patient_info bg-shade p-4 rounded">
  <div class="item">
    <ul class="p-0">
      <li><span>Sample ID : </span> <span> {{ecrfPatientId || "NA"}} </span></li>
      <li><span>Input file name R1 : </span> <span> {{dnaR1Documents[0]?.title || "NA"}} </span></li>
      <li><span>Input file name R2 : </span> <span> {{dnaR2Documents[0]?.title || "NA"}} </span></li>
      <li><span>Age/DOB : </span> <span> {{patient?.dateOfBirth}} ({{patient?.age}}years) </span></li>
    </ul>
  </div>
  <div class="item">
    <ul class="p-0">
      <li><span>Test name : </span> <span> {{patient?.testPackageName || "NA"}} </span></li>
      <li><span>Gender : </span> <span> {{patient?.gender || "NA"}}</span></li>
      <li><span>Capturing Kit : </span> <span> {{patient?.dnaKitUsed || "NA"}} </span></li>
      <li><span>Registration Date : </span> <span> {{sampleInfo?.sampleRegistrationOn || "NA"}} </span></li>
    </ul>
  </div>
  <div class="item">
    <ul class="p-0">
      <li><span>Cancer type : </span> <span> {{patient?.medical?.wbCancerType || "NA"}} </span></li>
      <li><span> Sample source : </span> <span> {{sampleInfo?.somaticType || "NA"}} </span> </li>
      <li><span>Sample date : </span> <span> {{sampleInfo?.sampleRegistrationOn || "NA"}} </span></li>
    </ul>
  </div>
</div>
<!-- 4bc DNA kit and sample type data -->
<div class="row" *ngIf="appUser.is4BaseCare" style="padding: 15px!important;">
  <div class="col-4">
    <h6 class="clr_text">DNA Kit Used :</h6>
    <select class="form-control" [disabled]="isUpdated" style="font-size: 15px!important;" name="dnaKitUsed"
      [(ngModel)]="dnaAndSomaticTypeDataReq.dnaKitUsed">
      <option value="FEV2F2both">FEV2F2both</option>
      <option value="CDS">CDS</option>
      <option value="FUS">FUS</option>
    </select>
  </div>
  <div class="col-4">
    <h6 class="clr_text">Sample Type :</h6>
    <select class="form-control" [disabled]="isUpdated" style="font-size: 15px!important;" name="somaticType"
      [(ngModel)]="dnaAndSomaticTypeDataReq.somaticType">
      <option value="FFPE">FFPE</option>
      <option value="Fresh Frozen">Fresh Frozen</option>
      <option value="Blood (cfDNA)">Blood (cfDNA)</option>
      <option value="Slides">Slides</option>
    </select>

  </div>
  <div class="col-1" *ngIf="!isUpdated">
    <h5 class="font-weight-bold text-cyan" style="color: transparent;">----</h5>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
      <button type="button" class="btn btn-primary ml-1"
        (click)="updateDnaAndSomaticTypeDetailsFor4bcPatients()">Save</button>
    </div>
  </div>
</div>
<!--  -->

<div class="upload_main-div mt-4" *ngIf="!isuploadModal">
  <div class="row py-4 border-bottom"
    *ngIf="(!isuploadModal && !appUser.is4BaseCare) || (isUpdated && !isuploadModal && appUser.is4BaseCare)">
    <div class="col-3">
      <h6 class="clr_text">Select File type</h6>
      <p>File format for NGS Data</p>
    </div>
    <div class="col">
      <div class="form-group w-60">
        <label for="">File Format</label>
        <!-- <select class="form-control">
          <option value="">-- selct one --</option>
          <option value="Fastq">Fastq</option>
          <option value="Bam">Bam</option>
          <option value="VCF">VCF</option>
          <option value="Others">Others</option>
        </select> -->
        <ng-multiselect-dropdown class="drop" name="fileType" [(ngModel)]="selectedItems"
          [placeholder]="'Select File Type'" [settings]="fileDropdownSettings" [data]="fileTypeList"
          (onSelect)="onItemSelect($event)" (onDeSelect)="onDeSelect($event)"
          [disabled]="patient.patientDocumentStatus === 'Report Processing' || patient.patientDocumentStatus === 'Report Generated'">
        </ng-multiselect-dropdown>
      </div>
    </div>
  </div>
  <!--FastQ DNA  -->
  <div *ngIf="isFastQ && !isuploadModal && isDna" class="row py-4 border-bottom">
    <div class="col-3">
      <h6 class="clr_text">Upload Fastq files</h6>
      <p>Upload DNA R1 and R2 file</p>
    </div>
    <div class="col">
      <div class="form-group w-60">
        <div class="row">
          <div *ngIf="dnaR1Documents.length > 0" class="col add_icon">
            <div class="selct_input">
              <label for="">DNA R1 Fastq file</label>
              <input type="text" class="form-control">
              <span class="check_success"
                *ngIf="100 == dnaR1Progress[0].percentage && dnaR1Documents[0]?.status != 'Failed to upload'"><i
                  class="zmdi zmdi-check-circle"></i> {{dnaR1Documents[0].title}}</span>
              <span class="check_success"
                *ngIf="100 > dnaR1Progress[0].percentage && dnaR1Documents[0]?.status != 'Failed to upload'">
                <div class="spinner-border spinner-small" role="status">
                  <span class="visually-hidden"></span>
                </div>
                {{dnaR1Documents[0].title}}
              </span>
              <span class="check_fail" *ngIf="dnaR1Documents[0]?.status == 'Failed to upload'"><i
                  class="zmdi zmdi-close-circle" title="Failed to upload"></i> {{dnaR1Documents[0].title}}</span>
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('DNA R1')"> <i
                  *ngIf="100 == dnaR1Progress[0].percentage || dnaR1Documents[0]?.status == 'Failed to upload'"
                  title="replace" class="zmdi zmdi-repeat"></i></a>
                </div>
                <div class="mt-1" *ngIf="100 > dnaR1Progress[0].percentage && dnaR1Documents[0].status != 'Failed to upload'">
                  <ngb-progressbar [value]="dnaR1Progress[0].percentage" [showValue]="true" [striped]="true"
                    [animated]="true"></ngb-progressbar>
                </div>
          </div>
          <div *ngIf="dnaR2Documents.length > 0" class="col add_icon">
            <div class="selct_input ">
              <label for="">DNA R2 Fastq file</label>
              <input type="text" class="form-control">
              <span class="check_success"
                *ngIf="100 == dnaR2Progress[0].percentage && dnaR2Documents[0]?.status != 'Failed to upload'"><i
                  class="zmdi zmdi-check-circle"></i> {{dnaR2Documents[0].title}}</span>
              <span class="check_success"
                *ngIf="100 > dnaR2Progress[0].percentage && dnaR2Documents[0]?.status != 'Failed to upload'">
                <div class="spinner-border spinner-small" role="status">
                  <span class="visually-hidden"></span>
                </div>
                {{dnaR2Documents[0].title}}
              </span>
              <span class="check_fail" *ngIf="dnaR2Documents[0]?.status == 'Failed to upload'"><i
                  class="zmdi zmdi-close-circle" title="Failed to upload"></i> {{dnaR2Documents[0].title}}</span>
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('DNA R2')"> <i
                  *ngIf="100 == dnaR2Progress[0].percentage || dnaR2Documents[0]?.status == 'Failed to upload'"
                  title="replace" class="zmdi zmdi-repeat"></i></a>
                </div>
                <div class="mt-1" *ngIf="100 > dnaR2Progress[0].percentage && dnaR2Documents[0].status != 'Failed to upload'">
                  <ngb-progressbar [value]="dnaR2Progress[0].percentage" [showValue]="true" [striped]="true"
                    [animated]="true"></ngb-progressbar>
                </div>
          </div>

          <div *ngIf="dnaR1Documents.length == 0" class="col add_icon">
            <div class="form-group ">
              <label for="">DNA R1 Fastq file</label>
              <input type="text" class="form-control" placeholder="Please upload R1 file">
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('DNA R1')" class="plus_icon"> <i class="zmdi zmdi-plus"></i></a>
            </div>
          </div>
          <div *ngIf="dnaR2Documents.length == 0" class="col add_icon">
            <div class="form-group ">
              <label for="">DNA R2 Fastq file</label>
              <input type="text" class="form-control" placeholder="Please upload R2 file">
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('DNA R2')" class="plus_icon"> <i class="zmdi zmdi-plus"></i></a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  -->

  <!--FastQ RNA  -->
  <div *ngIf="isFastQ && !isuploadModal && isRna" class="row py-4 border-bottom">
    <div class="col-3">
      <h6 class="clr_text">Upload Fastq files</h6>
      <p>Upload RNA R1 and R2 file</p>
    </div>
    <div class="col">
      <div class="form-group w-60">
        <div class="row">
          <div *ngIf="rnaR1Documents.length > 0" class="col add_icon">
            <div class="selct_input ">
              <label for="">RNA R1 Fastq file</label>
              <input type="text" class="form-control pl-5">

              <span class="check_success"
                *ngIf="100 == rnaR1Progress[0].percentage && rnaR1Documents[0]?.status != 'Failed to upload'"><i
                  class="zmdi zmdi-check-circle"></i> {{rnaR1Documents[0].title}}</span>
              <span class="check_success"
                *ngIf="100 > rnaR1Progress[0].percentage && rnaR1Documents[0]?.status != 'Failed to upload'">
                <div class="spinner-border spinner-small" role="status">
                  <span class="visually-hidden"></span>
                </div>
                {{rnaR1Documents[0].title}}
              </span>
              <span class="check_fail" *ngIf="rnaR1Documents[0]?.status == 'Failed to upload'"><i
                  class="zmdi zmdi-close-circle" title="Failed to upload"></i> {{rnaR1Documents[0].title}}</span>
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('RNA R1')"> <i
                  *ngIf="100 == rnaR1Progress[0].percentage || rnaR1Documents[0]?.status == 'Failed to upload'"
                  title="replace" class="zmdi zmdi-repeat"></i></a>
                </div>
                <div class="mt-1" *ngIf="100 > rnaR1Progress[0].percentage && rnaR1Documents[0].status != 'Failed to upload'">
                  <ngb-progressbar [value]="rnaR2Progress[0].percentage" [showValue]="true" [striped]="true"
                    [animated]="true"></ngb-progressbar>
                </div>
          </div>
          <div *ngIf="rnaR2Documents.length > 0" class="col add_icon">
            <div class="selct_input ">
              <label for="">RNA R2 Fastq file</label>
              <input type="text" class="form-control">
              <span class="check_success"
                *ngIf="100 == rnaR2Progress[0].percentage && rnaR2Documents[0]?.status != 'Failed to upload'"><i
                  class="zmdi zmdi-check-circle"></i> {{rnaR2Documents[0].title}}</span>
              <span class="check_success"
                *ngIf="100 > rnaR2Progress[0].percentage && rnaR2Documents[0]?.status != 'Failed to upload'">
                <div class="spinner-border spinner-small" role="status">
                  <span class="visually-hidden"></span>
                </div>
                {{rnaR2Documents[0].title}}
              </span>
              <span class="check_fail" *ngIf="rnaR2Documents[0]?.status == 'Failed to upload'"><i
                  class="zmdi zmdi-close-circle" title="Failed to upload"></i> {{rnaR2Documents[0].title}}</span>
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('RNA R2')"> <i
                  *ngIf="100 == rnaR2Progress[0].percentage || rnaR2Documents[0]?.status == 'Failed to upload'"
                  title="replace" class="zmdi zmdi-repeat"></i></a>
                </div>
                <div class="mt-1" *ngIf="100 > rnaR2Progress[0].percentage && rnaR2Documents[0].status != 'Failed to upload'">
                  <ngb-progressbar [value]="rnaR2Progress[0].percentage" [showValue]="true" [striped]="true"
                    [animated]="true"></ngb-progressbar>
                </div>
          </div>

          <div *ngIf="rnaR1Documents.length == 0" class="col add_icon">
            <div class="form-group ">
              <label for="">RNA R1 Fastq file</label>
              <input type="text" class="form-control pl-5" placeholder="Please upload R1 file">
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('RNA R1')" class="plus_icon"> <i class="zmdi zmdi-plus"></i></a>
            </div>
          </div>
          <div *ngIf="rnaR2Documents.length == 0" class="col add_icon">
            <div class="form-group ">
              <label for="">RNA R2 Fastq file</label>
              <input type="text" class="form-control" placeholder="Please upload R2 file">
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('RNA R2')" class="plus_icon"> <i class="zmdi zmdi-plus"></i></a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Histopathology -->
  <div *ngIf="isOthers && !isuploadModal" class="row py-4 border-bottom">
    <div class="col-3">
      <h6 class="clr_text">Upload Histopathology file here</h6>
      <p>Upload Histopathology file</p>
    </div>
    <div class="col">
      <div class="form-group w-60">
        <div class="row">
          <div class="col add_icon" *ngIf="histoDocuments.length == 0">
            <div class="form-group  w-60">
              <label for="">Histopathology (Highly recommended)</label>
              <input type="text" class="form-control" placeholder="Please upload histopathology file">
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('HISTOPATHOLOGY')"><i class="zmdi zmdi-plus plus"></i></a>
            </div>
          </div>
          <div class="col add_icon" *ngIf="histoDocuments.length > 0">
            <div class="selct_input">
              <p class="mb-0">Histopathology file</p>
              <input type="text" class="form-control" placeholder="">
              <span class="check_success"
                *ngIf="100 == histoProgress[0].percentage && histoDocuments[0]?.status != 'Failed to upload'"><i
                  class="zmdi zmdi-check-circle"></i> {{histoDocuments[0].title}}</span>
              <span class="check_success"
                *ngIf="100 > histoProgress[0].percentage && histoDocuments[0]?.status != 'Failed to upload'">
                <div class="spinner-border spinner-small" role="status">
                  <span class="visually-hidden"></span>
                </div> {{histoDocuments[0].title}}
              </span>
              <span class="check_fail" *ngIf="histoDocuments[0]?.status == 'Failed to upload'"><i
                  class="zmdi zmdi-close-circle" title="Failed to upload"></i> {{histoDocuments[0].title}}</span>
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('HISTOPATHOLOGY')"> <i
                  *ngIf="100 == histoProgress[0].percentage || histoDocuments[0]?.status == 'Failed to upload'" title="replace"
                  class="zmdi zmdi-repeat"></i></a>
            </div>
            <div class="mt-1" *ngIf="100 > histoProgress[0].percentage && histoDocuments[0]?.status != 'Failed to upload'">
              <ngb-progressbar [value]="histoProgress[0].percentage" [showValue]="true" [striped]="true"
                [animated]="true"></ngb-progressbar>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!--  Bam and Bai -->
  <div *ngIf="isBam && !isuploadModal" class="row py-4 border-bottom">
    <div class="col-3">

      <h6 class="clr_text">Upload Bam files</h6>
      <p>Upload Bam and Bai files</p>
    </div>
    <div class="col">
      <div class="form-group w-60">
        <div class="row">
          <div class="col add_icon" *ngIf="bamDocuments.length == 0">
            <div class="form-group ">
              <label for="">Bam file</label>
              <input type="text" class="form-control" placeholder="Please upload Bam file">
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('BAM')"><i class="zmdi zmdi-plus plus"></i></a>
            </div>
          </div>
          <div class="col add_icon" *ngIf="baiDocuments.length == 0">
            <div class="form-group ">
              <label for="">Bai file</label>
              <input type="text" class="form-control" placeholder="Please upload Bai file">
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('BAI')"><i class="zmdi zmdi-plus plus"></i></a>
            </div>
          </div>

          <div class="col add_icon" *ngIf="bamDocuments.length > 0">
            <div class="selct_input">
              <label for="">Bam file</label>
              <input type="text" class="form-control" placeholder="">
              <span class="check_success"
                *ngIf="100 == bamProgress[0].percentage && bamDocuments[0]?.status != 'Failed to upload'"><i
                  class="zmdi zmdi-check-circle"></i> {{bamDocuments[0].title}}</span>
              <span class="check_success"
                *ngIf="100 > bamProgress[0].percentage && bamDocuments[0]?.status != 'Failed to upload'">
                <div class="spinner-border spinner-small" role="status">
                  <span class="visually-hidden"></span>
                </div> {{bamDocuments[0].title}}
              </span>
              <span class="check_fail" *ngIf="bamDocuments[0]?.status == 'Failed to upload'"><i
                  class="zmdi zmdi-close-circle" title="Failed to upload"></i> {{bamDocuments[0].title}}</span>
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('BAM')"> <i
                  *ngIf="100 == bamProgress[0].percentage || bamDocuments[0]?.status == 'Failed to upload'"
                  title="replace" class="zmdi zmdi-repeat"></i></a>
                </div>
                <div class="mt-1" *ngIf="100 > bamProgress[0].percentage && bamDocuments[0]?.status != 'Failed to upload'">
                  <ngb-progressbar [value]="bamProgress[0].percentage" [showValue]="true" [striped]="true"
                    [animated]="true"></ngb-progressbar>
                </div>
          </div>
          <div class="col add_icon" *ngIf="baiDocuments.length > 0">
            <div class="selct_input">
              <label for="">Bai file</label>
              <input type="text" class="form-control" placeholder="">
              <span class="check_success"
                *ngIf="100 == baiProgress[0].percentage && baiDocuments[0]?.status != 'Failed to upload'"><i
                  class="zmdi zmdi-check-circle"></i> {{baiDocuments[0].title}}</span>
              <span class="check_success"
                *ngIf="100 > baiProgress[0].percentage && baiDocuments[0]?.status != 'Failed to upload'">
                <div class="spinner-border spinner-small" role="status">
                  <span class="visually-hidden"></span>
                </div> {{baiDocuments[0].title}}
              </span>
              <span class="check_fail" *ngIf="baiDocuments[0]?.status == 'Failed to upload'"><i
                  class="zmdi zmdi-close-circle" title="Failed to upload"></i> {{baiDocuments[0].title}}</span>
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('BAI')"> <i
                  *ngIf="100 == baiProgress[0].percentage || baiDocuments[0]?.status == 'Failed to upload'"
                  title="replace" class="zmdi zmdi-repeat"></i></a>
            </div>
            <div class="mt-1" *ngIf="100 > baiProgress[0].percentage && baiDocuments[0]?.status != 'Failed to upload'">
              <ngb-progressbar [value]="baiProgress[0].percentage" [showValue]="true" [striped]="true"
                [animated]="true"></ngb-progressbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- VCF -->
  <div *ngIf="isVcf && !isuploadModal" class="row py-4 border-bottom">
    <div class="col-3">
      <h6 class="clr_text">Upload VCF file</h6>
      <p>Upload VCF file</p>
    </div>
    <div class="col">
      <div class="form-group w-60">
        <div class="row">
          <div class="col add_icon" *ngIf="vcfDocuments.length == 0">
            <div class="form-group ">
              <label for="">VCF file</label>
              <input type="text" class="form-control" placeholder="Please upload VCF file">
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('VCF')"><i class="zmdi zmdi-plus plus"></i></a>
            </div>
          </div>
          <div class="col add_icon" *ngIf="vcfDocuments.length > 0">
            <div class="selct_input ">
              <label for="">VCF file</label>
              <input type="text" class="form-control" placeholder="">
              <span class="check_success"
                *ngIf="100 == vcfProgress[0].percentage && vcfDocuments[0]?.status != 'Failed to upload'"><i
                  class="zmdi zmdi-check-circle"></i> {{vcfDocuments[0].title}}</span>
              <span class="check_success"
                *ngIf="100 > vcfProgress[0].percentage && vcfDocuments[0]?.status != 'Failed to upload'">
                <div class="spinner-border spinner-small" role="status">
                  <span class="visually-hidden"></span>
                </div> {{vcfDocuments[0].title}}
              </span>
              <span class="check_fail" *ngIf="vcfDocuments[0]?.status == 'Failed to upload'"><i
                  class="zmdi zmdi-close-circle" title="Failed to upload"></i> {{vcfDocuments[0].title}}</span>
              <a *ngIf="patient.patientDocumentStatus == 'Sample Registered' || patient.patientDocumentStatus == 'Failure Report'"
                (click)="uploadFileModal('VCF')"> <i
                  *ngIf="100 == vcfProgress[0].percentage || vcfDocuments[0]?.status == 'Failed to upload'"
                  title="replace" class="zmdi zmdi-repeat"></i></a>
            </div>
            <div class="mt-1" *ngIf="100 > vcfProgress[0].percentage && vcfDocuments[0]?.status != 'Failed to upload'">
              <ngb-progressbar [value]="vcfProgress[0].percentage" [showValue]="true" [striped]="true"
                [animated]="true"></ngb-progressbar>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!--  -->

  <div class="row">
    <!-- <div class="col-3">
      <div *ngIf="(isAccessAllowedButtom && showStatAnalysis && isTargetFirst && isProcessing)"
        class="d-grid gap-2 d-md-flex justify-content-md-start mt-2">
        <button type="button" class="btn btn-primary ml-1" [disabled]="isStartAnalysisDisabled"
          (click)="startAnalysis()">Start Analysis</button>
      </div>
    </div> -->
    <div class="col">
      <div *ngIf="(isFastQ || isOthers || isBam || isVcf) && (!isuploadModal)"
        class="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
        <!-- <button type="button" class="btn btn-secondary">Cancel</button> -->
        <button type="button" class="btn btn-primary ml-1 btn_cyan submit_process-btn" [disabled]="isDisabled" (click)="confirm()">Submit for
          Proccessing</button>
      </div>
    </div>
  </div>
</div>




<!-- popup upload -->
<div *ngIf="isBioModel" class="popup_div border px-4 pb-4 mt-5 rounded">
  <div class="popup_card d-flex py-4 align-items-center">
    <i class="zmdi zmdi-upload clr_text"></i>
    <h6 class="clr_text mb-0 ml-2">BIOINFORMATICS ANALYSIS</h6>
  </div>
  <div class="mb-2 height_150">
    <p>Do you wish to continue with the analysis now?</p>
  </div>
  <div class="btn_sec mt-4 text-right">
    <button type="button" class="btn btn-secondary cancel_btn">Cancel</button>
    <button type="button" class="btn btn_cyan ml-2">Proceed</button>

  </div>
</div>
<!-- upload model -->

<div *ngIf="isuploadModal" class="popup_div border px-4 pb-4 mt-5 rounded">
  <div class="popup_card d-flex py-4 align-items-center">
    <i class="zmdi zmdi-upload clr_text"></i>
    <h6 class="clr_text mb-0 ml-2">Upload {{fileType}} File</h6>
  </div>
  <div class="mb-1 ">
    <div *ngIf="!isUploading" class="col-8 my-4">
      <label class="mb-0">Select a File</label>
      <input type="file" class="form-control" (change)="onFileChange($event)">
    </div>
    <div *ngIf="!isUploading" class="col-8 my-4">
      <label class="mb-0">Additional Comments for This file</label>
      <textarea class="form-control " placeholder="Additional Comments" name="comments"
        [(ngModel)]="patientDocumentReq.comments"></textarea>
    </div>

    <div *ngIf="isUploading" class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
  </div>
  <div class="btn_sec mt-4 text-right">
    <button type="button" class="btn btn-secondary" (click)="uploadFileModal('')">Cancel</button>
    <button type="button" class="btn btn_cyan ml-2" (click)="uploadFile()">Submit</button>


  </div>
</div>
