
<h6 class="title clr_text2 " *ngIf="fusionVariants.length>0">Fusion</h6>
<div class="slected_rows ">
  <div class="toggle_main" *ngIf="spinDiv && fusionVariants.length>0">
    <label class="switch">
      <span class="left">Show all</span>
      <span class="right">Selected Rows</span>
      <input type="checkbox" (click)="switchToggle($event)">
      <span class="slider round"></span>
    </label>
  </div>
</div>
<p *ngIf="fusionVariants.length==0 || fusionVariants==null" style="text-align: center;">
  Clinically relevant Genomic Fusions were not detected.
</p>
<nav class="accordion arrows mt-2" *ngIf="fusionVariants.length>0">
  <div class="panel_box thead d-flex rounded bg-light_cyan rounded mb-3">
    <div class="item"><span>Fusion Gene	 </span></div>
    <div class="item"><span>Read Support </span></div>
    <div class="item"><span>Left BP	 </span></div>
    <div class="item"><span>Right BP	 </span></div>
    <div class="item"><span>Actions	 </span></div>
    <div class="item pri_order"  *ngIf="switchToggleButton"><span>Priority Order </span></div>

  </div>
  <div *ngIf="fusionListSpinner" class="w-100 spinner_position">
    <div class="spinner-border  m-auto d-block" role="status">
      <span></span>
    </div>
  </div>
  <div *ngIf="!fusionListSpinner">

  <section class="box" *ngFor="let fusion of fusionVariants; let i = index">
    <label class="box-title">
      <div class="panel_box d-flex">
        <div class="item"><span><i>{{fusion.fusionGene}}</i></span></div>
        <div class="item"><span><p>{{fusion.score}}</p> </span></div>
        <div class="item"><span>{{fusion.leftBreakPoint}}</span></div>
        <div class="item"><span>{{fusion.rightBreakPoint}}</span></div>
        <div class="item">
          <ul class="actions_list pl-0 d-flex">
            <li>
              <a (click)="seletedRow(fusion.selectedForReport, fusion)"
              *ngIf="fusion.selectedForReport==false">
              <i class="zmdi zmdi-bookmark-outline"></i></a>
              <a (click)="seletedRow(fusion.selectedForReport, fusion)"
              *ngIf="fusion.selectedForReport==true">
              <i style="color: #2292cf;" class="zmdi zmdi-bookmark"></i></a>

            </li>
            <li *ngIf="fusion.selectedForReport==true">
              <a (click)="seletedStarRow(fusion.selectedStarForReport, fusion)"
              *ngIf="fusion.selectedStarForReport==false">
              <i class="zmdi zmdi-star-outline"></i></a>
              <a (click)="seletedStarRow(fusion.selectedStarForReport, fusion)"
              *ngIf="fusion.selectedStarForReport==true">
              <i style="color: #2292cf;" class="zmdi zmdi-star"></i></a>

            </li>
            <li>
              <a [routerLink]="['/wb',reportId,'fusion',fusion.id]" target="_blank">View<br/>Details</a>
            </li>
          </ul>
        </div>
        <div class="item pri_order" *ngIf="switchToggleButton">
					<input type="number" class="form-control" name="priorityOrderNumber_{{i}}" [(ngModel)]="fusion.priorityOrder"
					(change)="priorityOrder(fusion.priorityOrder, fusion.id)">
		</div>
      </div>

    </label>

  </section>
  </div>


</nav>


