import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OncoBenchAnalyticsResDto } from 'src/app/_interfaces/lab-model';
import { LabService } from 'src/app/_services/oncobench-service/lab.service';
import { SampleDetails } from '../patients-list/patients-list.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private router : Router,
    private _labService : LabService) { }

  ngOnInit(): void {
    this.getDashboardDetails()
  }

    sampleDetails: SampleDetails[] = [];

    getDashboardDetails() {

      this._labService.getDashboardDetails().subscribe(
        (response: any) => {
          console.log(response['payLoad']);
          this.sampleDetails = response['payLoad']['sampleDetails'];
        }
      );
    }
}
