import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportVariant } from 'src/app/_interfaces/excel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SnvService {
  constructor(
    private httpClient: HttpClient
  ) { }

  autoUploadFengFile(reportId: Number) {
    return this.httpClient.post(environment.wbApiUrl+'/report/' + reportId + '/auto_upload_feng_file',null,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getReportVariantById(reportVariantId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/report/' + reportVariantId + '/get_report_variant',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getAllReportVariantByReportId(reportId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/report/' + reportId + '/get_report_variants',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  changeReportVariantStarStatus(reportVariantId: number, aBoolean: boolean) {
    return this.httpClient.post(environment.wbApiUrl+'/report/' + reportVariantId + '/report_variant_star_status', aBoolean,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  changeReportVariantStatus(reportVariantId: number, aBoolean: boolean) {
    return this.httpClient.post(environment.wbApiUrl+'/report/' + reportVariantId + '/report_variant_status', aBoolean,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addReportVariantSignificance(reportVariantId: number, body: any) {
    return this.httpClient.post(environment.wbApiUrl+'/report/' + reportVariantId + '/add_significance', body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  updateReportVariant(variantId:number,body:ReportVariant){
    return this.httpClient.post(environment.wbApiUrl+'/report/' + variantId + "/updateVariant" , body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getSelectedReportedVariants(reportId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/report/' + reportId + '/get_selected_report_variants',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
  setPriorityOrder(reportVariantId: number, priorityOrderNumber: number) {
    return this.httpClient.post(environment.wbApiUrl+'/report/' + reportVariantId + '/priority_order', priorityOrderNumber,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
}
