import { DataAdminService } from 'src/app/_services/workbench-service/data-admin.service';
import { Component, OnInit } from '@angular/core';
import { DisclaimerVariants } from 'src/app/_interfaces/admin';
import { Reports } from 'aws-sdk/clients/codebuild';
import { ReportService } from 'src/app/_services/workbench-service/report.service';
import { ReportRes } from 'src/app/_interfaces/reports';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-additional-details',
  templateUrl: './additional-details.component.html',
  styleUrls: ['./additional-details.component.scss']
})
export class AdditionalDetailsComponent implements OnInit {
  disclaimerDropdownSettings = {};
  constructor(public dataAdminService:DataAdminService,
    public reportService:ReportService,
    private router: Router,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    ) { }
    reportId!:any;
    public editor:any = ClassicEditor;

  ngOnInit(): void {

    this.reportId = this.routerAct.snapshot.params['reportId'];
    if (this.reportId) {
      this.getReport();
      this.getDisclaimerList()

    }
    this.disclaimerDropdownSettings={
      singleSelection: false,
      idField: 'id',
      textField: 'disclaimerTag',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  disclaimerList:DisclaimerVariants[]=[]
  report = {} as ReportRes;
  clinicalSummary!: string;
  addNote: string='';
  disclaimertxt:any=''
  disclaimer:any=''
  disclaimerNote:any=''
  bracaInterpretation=false;
  pmIds!: string;
  originalPmids: string = '';

  getReport() {
    this.reportService.getReport(this.reportId).subscribe(
      (response: any) => {
        this.report = response['payLoad'];
        console.log("report ==>", this.report);

        this.clinicalSummary = this.report.clinicalSummary;
        this.addNote = this.report.addNotes;
        this.disclaimer=this.report.disclaimer;
        this.pmIds = this.report.pmIds.toString();
        if (this.pmIds != null || this.pmIds != undefined) {
          this.originalPmids = this.pmIds;
        }
        if (
          this.disclaimer == null ||
          (this.disclaimer == '<p></p>')
        ) {
          this.disclaimer = '';
        }         this.addNote = this.report.addNotes;

        if (
          this.addNote == null ||
          (this.addNote == '<div></div>')
        ) {
          this.addNote = '';
        }
        this.disclaimerNote=this.report.disclaimerNote;
        if(this.clinicalSummary!=null || this.disclaimerNote!=null || this.addNote!=null || this.pmIds!=null){
          this.isDataSubmited=true;
        }
    });
  }
  getDisclaimerList(){

      this.dataAdminService.disclaimerListByType("SOMATIC").subscribe((res:any)=>{
        this.disclaimerList=res["payLoad"]
      })

  }
  selectedDisclaimer:any=[]

  onDisclaimerItemSelect(disclaimer:any){
    console.log("DATA",disclaimer)

    let disclaimerData:any=this.disclaimerList.filter((element:any)=>{

      return element.id==disclaimer.id;
    })
    this.selectedDisclaimer.push(disclaimerData[0].disclaimerDetails)
      this.addNote=this.selectedDisclaimer.toString()

    console.log("JJJJJ",this.disclaimer,"kkk", this.selectedDisclaimer)

    setTimeout(()=>{

        this.textcount('addNote-editors')

    },500)
    setTimeout(()=>{

        this.textcount('disclaimer-editors')

    },500)
  }
  editorText:string=''

  textcount(classname:any){
    var dom=document.getElementsByClassName(classname);
      var data:any=dom[0]
      this.editorText= data?.outerText
  }

  onDisclaimerItemDeSelect(disclaimer:any){

    console.log("HHHHH",disclaimer)
    let disclaimerData:any=this.disclaimerList.filter((element:any)=>{

      return element.id==disclaimer.id;
    })
    const index = this.selectedDisclaimer.indexOf(
      disclaimerData[0].disclaimerDetails
    );
    this.selectedDisclaimer.splice(index,1)
    this.disclaimer=this.selectedDisclaimer.toString()
    setTimeout(()=>{

        this.textcount('disclaimer-editors')

    },1000)
  }
  isSubmited=false
  isDataSubmited=false;
  statusMsg!:string;
  submit() {
    if (this.clinicalSummary == null || this.clinicalSummary == undefined || this.clinicalSummary == '') {
      this.clinicalSummary = " ";
    }
    this.reportService.addClinicalSummary(this.reportId, this.clinicalSummary).subscribe(
      (response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
      }
    );
    if (this.addNote == null || this.addNote == undefined || this.addNote == '') {
      this.addNote = "<p></p>";
    }

      this.reportService.addAdditionalNotes(this.reportId, this.addNote).subscribe(
        (response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
        }
      );

      if (this.disclaimerNote && this.disclaimerNote?.length == 0) {
        this.disclaimerNote = " ";
      } else {
        console.log("add disclaimer", this.disclaimerNote?.length);
      }
      this.reportService.addDisclaimerNote(this.reportId, this.disclaimerNote).subscribe(
        (response: any) => {
          this.statusMsg = response['message'];
          this.showSuccess();
          console.log(response);
        }
      );
      if (this.pmIds.length > 0 ) {
        this.reportService.addPMIds(this.reportId, this.pmIds).subscribe(
          (response: any) => {
            console.log(response);
          }
        );
      }

    }
    showSuccess() {
      this.toastr.success(this.statusMsg, '',
        { timeOut: 2000 }
      );
    }

    showError() {
      this.toastr.error(this.statusMsg, '',
        { timeOut: 3000 }
      );
    }
}
