<h2>Dashboard</h2>
<div class="dashboard_panel ">
  <div class="row mt-4 dash_data">
    <div *ngFor="let sampleDetail of sampleDetails" class="col mb-3">
      <!-- <div class="card-body">
        <span *ngIf="sampleDetail.title == 'Total Patients'">{{sampleDetail.title}}</span>
        <span *ngIf="sampleDetail.title == 'Sample Registered'" style="color: blue;">{{sampleDetail.title}} (NGS data pending)</span>
        <span *ngIf="sampleDetail.title == 'Report Processing'" style="color: orange;">{{sampleDetail.title}} (NGS data Uploaded)</span>
        <span *ngIf="sampleDetail.title == 'Report Generated'" style="color:green;">{{sampleDetail.title}}</span>
        <span *ngIf="sampleDetail.title == 'Failure Report'" style="color:red;">{{sampleDetail.title}}</span>
        <h5 class="mt-1 mb-0">{{sampleDetail.dataValue}}</h5>
      </div> -->
      <div class="cust_card card_1 d-flex" *ngIf="sampleDetail.title == 'Total Patients'">
        <span >
          <i class="zmdi zmdi-info" ngbTooltip="{{sampleDetail.title}}"></i>
          {{sampleDetail.title}}
        </span>
        <h5 class="mt-1 mb-0">{{sampleDetail.dataValue}}</h5>
      </div>
      <div class="cust_card card_1 d-flex" *ngIf="sampleDetail.title == 'Sample Registered'" style="color: blue !important;">
        <span>
          <i class="zmdi zmdi-info" ngbTooltip="{{sampleDetail.title}} (NGS data pending)"></i>
          {{sampleDetail.title}}
        </span>
        <h5 class="mt-1 mb-0">{{sampleDetail.dataValue}}</h5>
      </div>
      <div class="cust_card card_1 d-flex" *ngIf="sampleDetail.title == 'Report Processing'" style="color: orange !important;">
        <span>
          <i class="zmdi zmdi-info" ngbTooltip="{{sampleDetail.title}} (NGS data Uploaded)"></i>
          {{sampleDetail.title}}
        </span>
        <h5 class="mt-1 mb-0">{{sampleDetail.dataValue}}</h5>
      </div>
      <div class="cust_card card_1 d-flex" *ngIf="sampleDetail.title == 'Report Generated'" style="color:green !important;">
        <span>
          <i class="zmdi zmdi-info" ngbTooltip="{{sampleDetail.title}}"></i>
          {{sampleDetail.title}}
        </span>
        <h5 class="mt-1 mb-0">{{sampleDetail.dataValue}}</h5>
      </div>
      <div class="cust_card card_1 d-flex" *ngIf="sampleDetail.title == 'Failure Report'" style="color:red !important;">
        <span>
          <i class="zmdi zmdi-info" ngbTooltip="{{sampleDetail.title}}"></i>
          {{sampleDetail.title}}
        </span>
        <h5 class="mt-1 mb-0">{{sampleDetail.dataValue}}</h5>
      </div>
    </div>


  </div>

</div>
