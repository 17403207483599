import { SnvService } from './../../_services/workbench-service/snv.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FilteresCSV } from 'src/app/_interfaces/excel';
import { ReportRes } from 'src/app/_interfaces/reports';
import { DataAdminService } from 'src/app/_services/workbench-service/data-admin.service';
import { ReportService } from 'src/app/_services/workbench-service/report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-snv-variant',
  templateUrl: './snv-variant.component.html',
  styleUrls: ['./snv-variant.component.scss'],
})
export class SnvVariantComponent implements OnInit {
  filteredCSV: FilteresCSV[] = [];

  constructor(
    private reportService: ReportService,
    private snvService: SnvService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private dataAdminService: DataAdminService
  ) {}
  organisationName!: any;
  reportId: any;
  switchToggleButton = false;
  igvUrl:any;
  ngOnInit(): void {
    this.organisationName = localStorage.getItem('organisationName') || '';
    this.reportId = this.routerAct.snapshot.params['reportId'];

    if (this.reportId) {
      this.getReport();
    }
    if (environment.wbApiUrl == 'https://www.api.workbench.4basecare.co.in'){
      this.igvUrl = 'https://igv.4basecare.co.in';
    } else {
      this.igvUrl = 'http://52.66.98.251:9505';
    }
  }

  autoUploadFengFile() {
    console.log('autoUploadFengFile');

    this.snvService
      .autoUploadFengFile(this.reportId)
      .subscribe((response: any) => {
        this.filteredCSV = response['payLoad'];
      });
  }

  testNameSelected: any;
  isTestNameSelected = false;
  isCancerSiteSelected = false;
  cancerSiteName: any;
  selectedRowfilteredCSV!: boolean;

  seletedRow(event: any, filteredCSV: FilteresCSV) {
    this.selectedRowfilteredCSV = !event;

    if (this.selectedRowfilteredCSV) {
      this.snvService
        .changeReportVariantStatus(filteredCSV.id, true)
        .subscribe((response: any) => {
          console.log(response);
          this.getReportedVariants();
        });
    } else if (!this.selectedRowfilteredCSV) {
      if (confirm('Are you sure you want unselect..!')) {
        this.snvService
          .changeReportVariantStatus(filteredCSV.id, false)
          .subscribe((response: any) => {
            console.log(response);
            this.getReportedVariants();
          });
      } else {
        this.getReportedVariants();
      }
    }
  }
  selectedStarVariant!: boolean;

  seletedStarRow(event: any, filteredCSV: FilteresCSV) {
    this.selectedStarVariant = !event;
    console.log(this.selectedStarVariant);
    this.snvService
      .changeReportVariantStarStatus(filteredCSV.id, this.selectedStarVariant)
      .subscribe((response: any) => {
        console.log(response);
        this.getReportedVariants();
      });
  }

  report = {} as ReportRes;
  fileName!: string;
  getReport() {
    this.reportService.getReport(this.reportId).subscribe((response: any) => {
      console.log(response);
      this.report = response['payLoad'];
      if (this.report.filteredCSVLink == null) {
        this.autoUploadFengFile();
      } else if (this.report.filteredCSVLink != null) {
        if (this.organisationName != null && this.organisationName != '') {
          this.fileName = this.report.filteredCSVLink.split('/')[8];
        } else {
          this.fileName = this.report.filteredCSVLink
            .split('/')[4]
            .substring(14);
        }
      }
      this.getReportedVariants();
    });
  }

  variantsListSpinner = true;
  showDiv = false;
  getReportedVariants() {
    this.variantsListSpinner = true;
    this.snvService
      .getAllReportVariantByReportId(this.reportId)
      .subscribe((response: any) => {
        this.variantsListSpinner = false;
        this.showDiv = true;
        console.log(response);
        this.filteredCSV = response['payLoad'];
        console.log("this.filteredCSV",this.filteredCSV)
        if (response['success'] == true) {
            if (this.switchToggleButton) {
              this.variantsListSpinner = true;
              this.getSelectedReportedVariants();
            }
          this.filteredCSV.forEach((csv: FilteresCSV) => {
            if(csv?.clinvar!=null){
              csv.clinvar=csv?.clinvar?.replace("clinvar: ","")?.trim();
            }else{
              csv.clinvar=".";

            }

          });
        }
      });
  }
  getSelectedReportedVariants() {
    this.snvService
      .getSelectedReportedVariants(this.reportId)
      .subscribe((response: any) => {
        this.variantsListSpinner = false;
        console.log(response);
        this.filteredCSV = response['payLoad'];
        this.filteredCSV.forEach((csv: FilteresCSV) => {
          if(csv?.clinvar!=null){
            csv.clinvar=csv?.clinvar?.replace("clinvar: ","")?.trim();
          }else{
            csv.clinvar=".";
          }
          if(csv?.cancerVar==null){
            csv.cancerVar=0;
          }
          if(csv.interVarAutomated==null){
            csv.interVarAutomated=".";
          }

          if(csv.tagIdenifier==null){
            csv.tagIdenifier=".";
          }
          if(csv.clnsig==null){
            csv.clnsig=".";
          }
          if(csv.siftPred==null){
            csv.siftPred=".";
          }
          if(csv.polyphen2HvarPred==null){
            csv.polyphen2HvarPred=".";
          }
          if(csv.mutationTasterPred==null){
            csv.mutationTasterPred=".";
          }
        });
      });
      this.variantsListSpinner = false;

  }

  switchToggle(event: any) {
    this.variantsListSpinner = true;
    this.switchToggleButton = event['target']['checked'];
    console.log('switchToggleButton', this.switchToggleButton);
    if (this.switchToggleButton) {
      this.getSelectedReportedVariants();
    } else if (!this.switchToggleButton) {
      this.getReportedVariants();
    }
  }

  showSuccess(msg: string) {
    this.toastr.success(msg, '', { timeOut: 2000 });
  }

  showError(msg: string) {
    this.toastr.error(msg, '', { timeOut: 2000 });
  }

  priorityOrder(orderNumber: number, reportedVariantId: number) {
    console.log(orderNumber, reportedVariantId);
    if (orderNumber != null && orderNumber > 0) {
      this.snvService
        .setPriorityOrder(reportedVariantId, orderNumber)
        .subscribe((response: any) => {
          console.log('setPriorityOrder', response);
        });
    }
  }
  currentExpandedItem: any = null;

  toggleExpand(item: any) {
    if (this.currentExpandedItem && this.currentExpandedItem !== item) {
      this.currentExpandedItem.isExpand = false;
    }
    item.isExpand = !item.isExpand;
    this.currentExpandedItem = item.isExpand ? item : null;
  }
}
