import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { User } from 'src/app/_interfaces/auth.model';
import { Documents, ReqId, SampleInfoReq } from 'src/app/_interfaces/lab-model';
import { ReportRes } from 'src/app/_interfaces/reports';
import { LabService } from 'src/app/_services/oncobench-service/lab.service';
import { WbService } from 'src/app/_services/workbench-service/wb.service';
import {
  DnaAndSomaticTypeDataReq,
  PatientRes,
} from 'src/app/pages/file-upload/file-upload.component';
import { ReportService } from 'src/app/_services/workbench-service/report.service';
import { CancerType } from 'src/app/_interfaces/admin';
import { ListItem } from 'ng-multiselect-dropdown/multiselect.model';
import { DataAdminService } from 'src/app/_services/workbench-service/data-admin.service';

@Component({
  selector: 'app-variant-dashboard',
  templateUrl: './variant-dashboard.component.html',
  styleUrls: ['./variant-dashboard.component.scss'],
})
export class VariantDashboardComponent implements OnInit {
  reqId = {} as ReqId;
  patient = {} as PatientRes;
  sampleInfo = {} as SampleInfoReq;
  dnaAndSomaticTypeDataReq = {} as DnaAndSomaticTypeDataReq;
  isTargetFirst = false;
  ecrfId!: any;
  ecrfPatientId!: any;
  dnaR1Documents: Documents[] = [];
  dnaR2Documents: Documents[] = [];
  cancerTypeDropdownSettings = {};
  cancerTypeList!: CancerType[];
  selectedcancertype: CancerType[] = [];

  constructor(
    private labService: LabService,
    private WbService: WbService,

    private router: Router,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private reportService: ReportService,
    private dataAdminService: DataAdminService
  ) {}
  appUser = {} as User;
  patientId!: any;
  reportId: any;
  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem('user') || '{}');
    console.log('appUser', this.appUser);
    this.patientId = this.routerAct.snapshot.params['id'];
    this.reportId = this.routerAct.snapshot.params['reportId'];
    this.getCancerTypes();
    if (this.reportId) {
      this.getReport();
    }
    if (this.patientId) {
      this.getPatient();
      this.getDocuments();

    }

    this.cancerTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }
  is4basecare = false;
  isTMH = false;
  isHCG = false;
  getPatient() {
    this.reqId.id = this.patientId;
    this.labService.getPatient(this.reqId).subscribe((response: any) => {
      this.patient = response['payLoad'];
      if (this.appUser.is4BaseCare == true) {
        this.sampleInfo.somaticType = this.patient.sampleSourceType;
        this.dnaAndSomaticTypeDataReq.somaticType =
          this.patient.sampleSourceType;
        this.isTargetFirst = true;
        let testName: string[] = [];
        if (this.patient.patientTestPackages != null) {
          this.patient.patientTestPackages.forEach((element: any) => {
            testName.push(element?.testPackageName);
          });
          this.patient.testPackageName = testName.toString();
        }
      } else {
        this.sampleInfo = response['bio'];
      }
      this.ecrfId = response['payLoad']['patientId'];
      console.log('get patient', this.patient);
      this.dnaAndSomaticTypeDataReq.dnaKitUsed = this.patient.dnaKitUsed;

      if (this.appUser.organisation == 'Lifecell') {
        this.ecrfPatientId = this.patient.orgLabId;
      } else if (this.appUser.organisation == '4baseCare Onco Solutions') {
        this.ecrfPatientId = this.patient.patientId;
      } else {
        this.ecrfPatientId = this.patient.organisationPatientId;
      }
      console.log('==>id', this.ecrfPatientId);
      if (
        this.patient.testPackageName == 'TarGT First' ||
        this.patient.testPackageName == 'Target First' ||
        this.appUser.is4BaseCare
      ) {
        this.isTargetFirst = true;
      }

      // if (this.patient.patientDocumentStatus == "Report Processing" || this.patient.patientDocumentStatus == 'Report Generated') {
      //   this.isDisabled = true;
      // }else{
      //   this.isDisabled=false
      // }
    });
  }
  subscription!: Subscription;
  documentsList: Documents[] = [];

  getDocuments() {
    console.log('getDocuments method');
    this.labService.getDocuments(this.patientId).subscribe((response: any) => {
      this.documentsList = response['payLoad'];
      if (this.documentsList.length > 0 ) {
        this.dnaR1Documents = this.documentsList.filter(function (document) {
          return document.type == 'DNA R1';
        });

        this.dnaR2Documents = this.documentsList.filter(function (document) {
          return document.type == 'DNA R2';
        });
      }
    });
  }

  report = {} as ReportRes;
  temp: any = 'temp1';
  tempSelection(event: any) {
    console.log('=>', event['target']['value']);
    this.temp = event['target']['value'];
  }
  isTemp1 = false;
  isTemp2 = false;
  isTemp3 = false;
  selectedFirstCancertype!: string;
  getReport() {
    this.WbService.getReport(this.reportId).subscribe((response: any) => {
      this.report = response['payLoad'];
      if (this.report?.patient?.organisationName != null) {
        if (
          this.report?.patient?.organisationName.includes('4baseCare') ||
          this.report?.patient?.organisationName.includes('4basecare')
        ) {
          this.is4basecare = true;
        } else if (
          this.report?.patient?.organisationName.includes('HCG') ||
          this.report?.patient?.organisationName.includes('HCG')
        ) {
          this.isHCG = true;
          this.is4basecare = false;
        } else if (
          this.report?.patient?.organisationName.includes('Tata') ||
          this.report?.patient?.organisationName.includes('Homi Bhabha')
        ) {
          console.log('TMH WORKING');
          this.isTMH = true;
          this.is4basecare = false;
        } else {
          this.is4basecare = true;
        }
      }
      if (this.report.firstCancerType != null) {
        this.selectedFirstCancertype = this.report.firstCancerType.name;
        this.selectedcancertype.push(this.report.firstCancerType);
      }
    });
  }
  cancerTypeSelect(cancerType: any) {
    console.log('select cancer', cancerType.id);
    this.reportService
      .addFirstCancerType(this.reportId, cancerType.id)
      .subscribe((response: any) => {
        console.log(response);
        this.isEditMode = false;
        this.getReport();
      });
  }
  cancerTypeDeSelect(cancerType: any) {
    console.log('de-select cancer', cancerType.id);
    this.reportService
      .addFirstCancerType(this.reportId, 0)
      .subscribe((response: any) => {
        console.log(response);
        this.isEditMode = false;
      });
  }
  isEditMode = false;
  getCancerTypes() {
    this.dataAdminService.cancerTypeList().subscribe((response: any) => {
      this.cancerTypeList = response['payLoad'];
    });
  }
  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }
}
