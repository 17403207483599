import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { environment } from 'src/environments/environment';
import { LabService } from './lab.service';
import { PatientDocumentReq } from 'src/app/pages/file-upload/file-upload.component';

@Injectable({
  providedIn: 'root'
})
export class S3ServiceService {

  private bucket: S3Client;

  constructor(
    private http: HttpClient,
    private labService: LabService
  ) {
    this.bucket = new S3Client(
      {
        credentials: {
          accessKeyId: environment.AWS_ACCESS_KEY_ID,
          secretAccessKey: environment.AWS_SECRET_ACCESS_KEY,
        },
        region: environment.AWS_REGION
      }
    );
  }


  async uploadFile(file: File, folderName: string, patientDocumentReq: PatientDocumentReq) {

    console.log("s3 service patientDocumentReq", patientDocumentReq);


    let date = new Date();

    const params = {
      Bucket: 'oncobench-files',
      Key: folderName + "/" + date.getTime() +  "-" + file.name ,
      Body: file,
      ContentType: file.type
    };

    try {
      const response = await this.bucket.send(new PutObjectCommand(params));
      if(response.$metadata.httpStatusCode == 200){
        patientDocumentReq.status = "Uploaded";
        this.labService.updatePatientDocumentStatus(patientDocumentReq).subscribe(
          (response:any) => {
            console.log("updatePatientDocumentStatus", response);
          }
        );
      } else {
        patientDocumentReq.status = "Failed to upload";
        this.labService.updatePatientDocumentStatus(patientDocumentReq).subscribe(
          (response:any) => {
            console.log("updatePatientDocumentStatus", response);
          }
        );
      }
      console.log("SUCCESS", response);
    } catch(error) {
      console.log("FAILURE", error);
      patientDocumentReq.status = "Failed to upload";
        this.labService.updatePatientDocumentStatus(patientDocumentReq).subscribe(
          (response:any) => {
            console.log("updatePatientDocumentStatus", response);
          }
        );
    }

  }
}
