import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  ClinicalTrialRes,
  DomainFunction,
  Gene,
  PrognasticBiomarkerRes,
} from 'src/app/_interfaces/admin';
import { FilteresCSV, ReportVariant } from 'src/app/_interfaces/excel';
import { DepthDetailsReqDto, ReportRes, ReportType } from 'src/app/_interfaces/reports';
import { StudyFieldsResponse } from 'src/app/_interfaces/trails';
import { ClinicalTrailService } from 'src/app/_services/workbench-service/clinical-trail.service';
import { DataAdminService } from 'src/app/_services/workbench-service/data-admin.service';
import { ReportService } from 'src/app/_services/workbench-service/report.service';
import { SnvService } from 'src/app/_services/workbench-service/snv.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { User } from 'src/app/_interfaces/auth.model';

@Component({
  selector: 'app-view-snv-variant',
  templateUrl: './view-snv-variant.component.html',
  styleUrls: ['./view-snv-variant.component.scss'],
})
export class ViewSnvVariantComponent implements OnInit {
  sup = 'A{b} → Superscript';
  sub = 'A~b! → Subscript';
  public editor:any = ClassicEditor;
    editorText: string = '';
  pmIds!: string;
  varient!: string;
  btnShow: boolean = false;
  showEdit: boolean = false;
  reportedVariantId!: number;
  reportId!: number;
  reportedVariant = {} as FilteresCSV;
  reportVariant = {} as ReportVariant;
  studyFieldsResponse = {} as StudyFieldsResponse;
  selectedPrognasticBiomarker: any;
  prognasticBiomarkerDropdownSettings: any;
  clinicalTrialResList: ClinicalTrialRes[] = [];
  hotspotList: ClinicalTrialRes[] = [];
  add_depthDetailsReqDto= {} as DepthDetailsReqDto;
  isSubmited!: boolean;
  statusMsg!: string;
  gene!: Gene;
  isGermline = false;
  prognasticTagList: PrognasticBiomarkerRes[] = [];
  domainFunction= {} as DomainFunction;
  plainTextDomainFunction: any;
  constructor(
    private reportService: ReportService,
    private snvService:SnvService,
    private routerAct: ActivatedRoute,
    private router: Router,
    private trailService: ClinicalTrailService,
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private titleService: Title
  ) {}
  appUser = {} as User;

  showSuccess() {
    this.toastr.success(this.statusMsg, '', { timeOut: 2000 });
  }
  organisationName!: string;
  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem('user') || '{}');
    console.log("appUser",this.appUser)
    this.organisationName = localStorage.getItem('organisationName') || '';
    let report = {} as ReportRes;
    let reportType = {} as ReportType;
    this.reportedVariant.report = report;
    this.reportedVariant.report.reportType = reportType;
    this.reportedVariant.report.reportType.name = '';
    this.selectedPrognasticBiomarker = [];
    this.reportedVariant.prognasticDescription = '';
    this.prognasticBiomarkerDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'prognasticTag',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.varient = '0';
    this.reportedVariant.significance = 'Not Classified';
    this.reportedVariantId = this.routerAct.snapshot.params['variantId'];
    this.reportId = this.routerAct.snapshot.params['reportId'];
    this.selectPrognasticBiomarkers();
    if (this.reportedVariantId) {
      this.getReportedVariant();
    }
  }

  selectPrognasticBiomarkers() {
    // this.reportedVariant.prognasticDescription=''
    // this.selectedPrognasticBiomarker=[]
    // console.log("idddd ",prognasticTag.id,this.selectedPrognasticBiomarker)
    this.dataAdminService.prognasticBiomarkerList().subscribe((res: any) => {
      this.prognasticTagList = res['payLoad'];
    });
  }
  snvSpinner=true;
  getPrognasticTag(significance: string) {
    this.dataAdminService.getPrognasticBySignificance(significance).subscribe((res:any)=>{
      this.prognasticTagList=res['payLoad']
      this.reportedVariant.prognasticDescription=''
      this.selectedPrognasticBiomarker=[]
    })
  }
  selectPrognasticBiomarker(prognasticTag: any) {
    // console.log("idddd ",prognasticTag.id,this.selectedPrognasticBiomarker)
    this.prognasticTagList.forEach((element: any) => {
      if (this.selectedPrognasticBiomarker.length > 0) {
        if (element.id == this.selectedPrognasticBiomarker[0].id) {
          this.reportedVariant.prognasticDescription = element.description;
          console.log('prognasticTagList ', element);
          let pmid: any;
          for (let i = 0; i < element?.geneVariants.length; i++) {
            if (pmid != null) {
              pmid += ',' + element?.geneVariants[i].referencePmIds;
            } else {
              pmid = element?.geneVariants[i].referencePmIds;
            }
          }
          console.log('pmid===>', pmid);
          if (this.reportedVariant.variantPmIds != null) {
            this.reportedVariant.variantPmIds += ',' + pmid;
          } else {
            this.reportedVariant.variantPmIds = pmid;
          }

          console.log('pmids', this.reportedVariant.variantPmIds);
        }
      }
    });
  }

  textcount(classname: any) {
    var dom = document.getElementsByClassName(classname);
    var data: any = dom[0];
    this.editorText = data?.outerText;
  }

  defaultdescription!: any;
  geneDescriptionMessage!: String;
  previousDescription!: any;
  getGeneDescription() {
    if (this.reportedVariant.significance == 'Benign') {
      this.reportedVariant.addGeneDescription = this.previousDescription;
    } else if (this.reportedVariant.significance == 'Pathogenic') {
      if (
        this.reportedVariant.funcknownGene == 'intronic' ||
        this.reportedVariant.funcknownGene == 'splicing' ||
        this.reportedVariant.funcknownGene == 'exonic ; splicing' ||
        this.reportedVariant.funcknownGene == 'intronic ; splicing'
      ) {
        this.geneDescriptionMessage =
          ' The (c.) variant of the (gene name) gene has a total depth of X and an alternative depth of X. In-silico prediction tools have predicted this variant to be ----- by MutationTaster. Further, in silico splice predictors, BDGP and Varseak has predicted this variant to be damaging.  Hence, this splice site variant located at the exon X - intron X junctions of (gene) is likely to result in disruption of acceptor/donor site splicing after exon X/intron X and may further, result in loss of ----- domains. Based on the available evidence the variant has been classified as Pathogenic. ';
      } else {
        this.geneDescriptionMessage =
          'The (p.) variant of the (gene name) gene has a total depth of X and an alternative depth of X. The In-silico prediction tools like SIFT, PolyPhen and MutationTaster have predicted this variant to be ----, ---- and -----, respectively. The variant is found at a frequency of ---- and --- in gnomAD and 1000 genome databases, respectively. The variant is present in exon X of (gene name) gene and ----- domain of ---- protein. (Functional evidence if any). Further the variant is reported as pathogenic/oncogenic in ClinVar/cBioportal/OncoKB. Based on the available evidence the variant has been classified as Pathogenic. ';
      }
      this.reportedVariant.addGeneDescription =
        this.previousDescription + `<br/>` + this.geneDescriptionMessage;
    } else if (this.reportedVariant.significance == 'VUS') {
      if (
        this.reportedVariant.funcknownGene == 'intronic' ||
        this.reportedVariant.funcknownGene == 'splicing' ||
        this.reportedVariant.funcknownGene == 'exonic ; splicing' ||
        this.reportedVariant.funcknownGene == 'intronic ; splicing'
      ) {
        this.geneDescriptionMessage =
          ' The (c.) variant of the (gene name) gene has a total depth of X and an alternative depth of X. In-silico prediction tools have predicted this variant to be (damaging/ benign) by MutationTaster. Further, in silico splice predictors, BDGP and Varseak has predicted this variant to be (damaging/benign).  Hence, the effect of this splice variant in the exon X/intron X boundary is unclear. Thus, this variant has been classified as Variant of Uncertain Significance (VUS) and must be clinically correlated with other findings for any further management. ';
      } else {
        this.geneDescriptionMessage =
          'The (p.) variant of the (gene name) gene has a total depth of X and an alternative depth of X. The In-silico prediction tools like SIFT, PolyPhen and MutationTaster have predicted this variant to be ----, ---- and ----- respectively. The variant is found at a frequency of ---- and --- in gnomAD and 1000 genome databases, respectively. The variant is present in exon X of (gene name) gene and ----- domain of ---- protein. Currently there are no/limited functional and clinical evidence available for this variant. Hence, this variant has been classified as Variant of Uncertain Significance (VUS) and must be clinically correlated with other findings for any further management. ';
      }
      this.reportedVariant.addGeneDescription =
        this.previousDescription + `<br/>` + this.geneDescriptionMessage;
    } else if (
      this.reportedVariant.significance == 'NA' ||
      this.reportedVariant.significance == 'Not Classified'
    ) {
      this.reportedVariant.addGeneDescription = this.previousDescription;
    } else if (this.reportedVariant.significance == 'Likely Pathogenic') {
      this.reportedVariant.addGeneDescription = this.previousDescription;
    }
  }
  isFirst72 = false;
  nstudiesFound = 0;
  testList: Array<string> = [];
  allelicBurden:any;
  alternativeAlleleDepth:any;
  totalDept:any;
  ecrfPatientId:any;
  cancerVar:any;
  interVarAutomated:any;
  getReportedVariant() {
    this.reportService.getReportVariantById(this.reportedVariantId).subscribe(
      (response: any) => {
        this.reportedVariant = response['payLoad'];
        this.snvSpinner=false;
        if (this.appUser.organisation == 'Lifecell') {
          this.ecrfPatientId = this.reportedVariant?.report?.patient.orgLabId;
        } else if (this.appUser.organisation == '4baseCare Onco Solutions') {
          this.ecrfPatientId = this.reportedVariant?.report?.patient?.patientId;
        } else {
          this.ecrfPatientId = this.reportedVariant?.report?.patient.organisationPatientId;
        }
        console.log("this.getReportVariantById =>",this.reportedVariant )
        if(this.reportedVariant.significance==null){
          this.reportedVariant.significance='Not Classified'
        }
        this.showEdit=this.reportedVariant.selectedForReport;
        this.testList.push("BRCA 1 and 2");
        this.testList.push("Family Testing");
        if(this.reportedVariant.report.reportType.name.includes("Germline") || this.testList.includes(this.reportedVariant.report.reportType.name)){
          this.isGermline = true;
        }
        if(this.reportedVariant.report.reportType.name=="First 72 Solid" || this.reportedVariant.report.reportType.name=="First 72 Liquid"){
          this.isFirst72 = true;
          console.log("Main work")
          this.getAllHotspotClinicalTrailByGene(this.reportedVariant.refGeneX.split(';', 1).toString(), 0);
        }
        if(this.reportedVariant.report.reportStatus == 'REPORTED'){
          this.statusMsg = "Report is already delivered no operations can be done";
          this.showSuccess();
          this.router.navigate(['/reports']);
          return;
        }
        if(this.reportedVariant.significance=='NA'){
          this.reportedVariant.significance='Not Classified'
        }
        if(this.reportedVariant.allelicBurden!=null){
        this.allelicBurden=Math.round(this.reportedVariant.allelicBurden);
        }else{
          this.allelicBurden=0;
        }
        if(this.reportedVariant.bam_ALT_Depth!=null){
          this.alternativeAlleleDepth=Math.floor(this.reportedVariant.bam_ALT_Depth);
        }else{
          this.alternativeAlleleDepth=0;
        }
        if(this.reportedVariant.bamDepth!=null){
          this.totalDept=Math.floor(this.reportedVariant.bamDepth);
        }else{
        this.totalDept=0;
        }
        // let cancerVarNumber: number = parseFloat(this. reportedVariant.cancerVar);

        if(this. reportedVariant.cancerVar>=11){
          this.cancerVar="Pathogenic";
        }else if(this. reportedVariant.cancerVar>=8 && this. reportedVariant.cancerVar<=10){
          this.cancerVar="Likely Pathogenic";
        }else if(this. reportedVariant.cancerVar>=3 && this. reportedVariant.cancerVar<8){
          this.cancerVar="VUS";
        }else if(this. reportedVariant.cancerVar<=3){
          this.cancerVar="Bengin";
        }
        this.reportedVariant.refGeneX = response['payLoad']['refGeneX'].split(';', 1).toString()
        this.titleService.setTitle(this.reportedVariant.report.patient.ecrfId + " - SNV/InDels - " + this.reportedVariant.refGeneX);
        this.pmIds = this.reportedVariant.report.pmIds.toString();
        if(response['payLoad']['variantStatus']=='UPDATED'){

        }else{
          this.reportedVariant.aachangeknownGeneList.forEach(aachangeknownGene => {
            if (aachangeknownGene.includes(this.reportedVariant.ensembleValue)) {
              this.reportedVariant.selectedAAChangeknownGene = aachangeknownGene.split(":")[3] + ", " + aachangeknownGene.split(":")[4];
            }
          });
        }



        this.dataAdminService.getGeneByName(this.reportedVariant.refGeneX).subscribe(
          (response: any) => {
            this.gene = response['payLoad'];
            console.log("gene details", this.gene);
            this.previousDescription = this.reportedVariant?.addGeneDescription;
            // this is to remove the <p></p> in gene.description the is being displayed above the add gene description
            if(this.gene.description!=null){
            this.gene.description = this.gene.description.replace(/<p>/g, '');
            this.gene.description = this.gene.description.replace(/<\/p>/g, '');
          }
          }
        );

        if (this.reportedVariant.significance == null) {
          this.reportedVariant.significance = "Benign";
        }
        if (this.reportedVariant?.prognasticSignificance == null){
          this.reportedVariant.prognasticSignificance = "NA";
        }

     if(this.reportedVariant.functionalDomain != null){
      this.dataAdminService.getDomainFunctionByName(this.reportedVariant.functionalDomain).subscribe(
        (response: any) => {
          this.domainFunction=response['payLoad'];
          console.log("domain function", this.domainFunction);
          // Strip HTML tags from domainFunction.domainFunction
          if (this.domainFunction?.domainFunction) {
            this.plainTextDomainFunction = this.domainFunction.domainFunction.replace(/<[^>]+>/g, '');
          }
        }
      )
    }

          if (response['success']) {
            this.dataAdminService.getAllHotspotClinicalTrailByGene(this.reportedVariant.refGeneX.split(';', 1).toString()).subscribe(
              (response: any) => {
                this.clinicalTrialResList = response['payLoad'];
                console.log("hotspot trail db",this.clinicalTrialResList);
              }
            );

        }
        setTimeout(()=>{
          this.textcount('addGeneDescription-editor')
        },3000)
      }
    );
  }


  getAllHotspotClinicalTrailByGene(gene: string, type: number) {
    this.dataAdminService
      .getAllHotspotClinicalTrailByGene(gene)
      .subscribe((response: any) => {
        this.hotspotList = response['payLoad'];
        console.log(
          '111 getAllHotspotClinicalTrailByGene db',
          this.hotspotList
        );
      });
  }
  submitDetails() {
    this.isSubmited = true;
    console.log(
      'this.reportedVariant.significance',
      this.reportedVariant.significance
    );
    this.snvService
      .addReportVariantSignificance(
        this.reportedVariantId,
        this.reportedVariant
      )
      .subscribe((response: any) => {
        this.isSubmited = false;
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
      });
    this.isSubmited = true;
    if (this.pmIds.length > 0) {
      this.pmIds = this.pmIds;
      this.reportService
        .addPMIds(this.reportId, this.pmIds)
        .subscribe((response: any) => {
          console.log(response);
          this.isSubmited = false;
        });
    }
    this.add_depthDetailsReqDto.variantType="snv";
    this.add_depthDetailsReqDto.totalDept=this.totalDept;
    this.add_depthDetailsReqDto.alternativeAlleleDepth=this.alternativeAlleleDepth;
    this.add_depthDetailsReqDto.allelicBurden=this.allelicBurden;
    this.reportService
        .addVariantDepthDetails(this.reportedVariantId, this.add_depthDetailsReqDto)
        .subscribe((response: any) => {
          console.log(response);
          this.isSubmited = false;
    });
    this.saveVariant();
  }

  edit(reportVarient: string) {
    this.btnShow = true;
    this.varient = reportVarient;
  }

  close() {
    this.varient = '0';
  }

  saveVariant() {
    this.reportVariant.chrom = this.reportedVariant.chrom;
    this.reportVariant.pos = this.reportedVariant.pos;
    this.reportVariant.end = this.reportedVariant.end;
    this.reportVariant.refX = this.reportedVariant.refX;
    this.reportVariant.altX = this.reportedVariant.altX;
    this.reportVariant.funcknownGene = this.reportedVariant.funcknownGene;
    this.reportVariant.exonicFuncknownGene = this.reportedVariant.exonicFuncknownGene;
    this.reportVariant.cdot = this.reportedVariant.cdot;
    this.reportVariant.pdot = this.reportedVariant.pdot;
    this.reportVariant.aaChangeknownGene = this.reportedVariant.selectedAAChangeknownGene;
    this.reportVariant.role = this.reportedVariant.role;
    this.reportVariant.refGeneX = this.reportedVariant.refGeneX;
    this.reportVariant.exonNumber = this.reportedVariant.exonNumber;
    this.reportVariant.ensembleValue = this.reportedVariant.ensembleValue;
    this.reportVariant.igvLink = this.reportedVariant.igvLink;
    this.reportVariant.igvLink = this.reportedVariant.genomicAlteration;
    console.log("this.reportVariant->"+this.reportVariant.pdot);
    console.log("this.reportVariant.cdot->"+this.reportVariant.cdot)

    if (this.reportedVariantId) {
      this.varient = '0';
      this.snvService
        .updateReportVariant(this.reportedVariantId, this.reportVariant)
        .subscribe(
          (res: any) => {
            this.statusMsg = res['message'];
            this.showSuccess();
          },
          (error) => {}
        );
    }
  }

}
