import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectedTrail } from 'src/app/_interfaces/trails';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClinicalTrailService {

  constructor(
    private http: HttpClient
  ) { }

  getTrailsCount(refGeneX: string){
    return this.http.get(environment.wbApiUrl+'/trails/trail_count/' + refGeneX,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }



  getTrails(refGeneX: string, pageNumber: number){
    return this.http.get(environment.wbApiUrl+'/trails/search/' + pageNumber + "/" + refGeneX,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addSelectedTrail(selectedTrail: SelectedTrail){
    return this.http.post(environment.wbApiUrl+'/trails/select_trail', selectedTrail,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  editSelectedTrail(selectedTrail: SelectedTrail){
    return this.http.post(environment.wbApiUrl+'/trails/edit_select_trail', selectedTrail,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  removeSelectedTrail(internalId: number, referenceId: number, type: number){
    return this.http.post(environment.wbApiUrl+'/trails/' + internalId + '/remove_select_trail/' + type, referenceId,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addStarSelectedTrail(internalId: number, referenceId: number, type: number){
    return this.http.post(environment.wbApiUrl+'/trails/' + internalId + '/select_star_trail/' + type, referenceId,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  removeStarSelectedTrail(internalId: number, referenceId: number, type: number){
    return this.http.post(environment.wbApiUrl+'/trails/' + internalId + '/remove_star_trail/' + type, referenceId,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addAssociation(selectedTrail: SelectedTrail){
    return this.http.post(environment.wbApiUrl+'/trails/add_association', selectedTrail,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  removeSelectedNCTIdTrail(nctId: string, referenceId: number, type: number){
    return this.http.get(environment.wbApiUrl+'/trails/' + nctId + '/remove_select_nct_trail/' + type + '/' + referenceId,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addStarSelectedNCTIdTrail(selectedTrail: SelectedTrail){
    return this.http.post(environment.wbApiUrl+'/trails/select_star_nct_trail', selectedTrail,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  starNCTTrail(selectedTrail: SelectedTrail){
    return this.http.post(environment.wbApiUrl+'/trails/star_nct_clinical_trail', selectedTrail,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  removeStarNCTTrail(selectedTrail: SelectedTrail){
    return this.http.post(environment.wbApiUrl+'/trails/remove_nct_clinical_trail', selectedTrail,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  removeStarSelectedNCTIdTrail(nctId: string, referenceId: number, type: number){
    return this.http.get(environment.wbApiUrl+'/trails/' + nctId + '/remove_star_nct_trail/'+ type + '/' + referenceId,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getSelectedTrails(referenceId: number, type: number, isInternal: boolean){
    return this.http.get(environment.wbApiUrl+'/trails/ '+ referenceId + '/get_selected_trail/' + type + "/" + isInternal,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addPDL1Trails(reportId: number, trailId: number, isSelected: boolean){
    return this.http.post(environment.wbApiUrl+'/trails/' + reportId + '/select_pdl1_trail/' + trailId,isSelected,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getSelectedPDL1Trails(reportId: number){
    return this.http.get(environment.wbApiUrl+'/trails/' + reportId  + '/get_selected_pdl1_trails',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
}

