<div class="add_patients">
  <div class="timeline mt-5 mt-lg-0">
    <ul class="list-unstyled d-flex mb-0">
      <li *ngIf="step1" class="step1_success">
        <a>
          <div class="label"><i class="zmdi zmdi-account-box-o"></i>
          </div>
        </a>
        <div class="progress_title">Patient Info</div>
      </li>
      <li *ngIf="!step1">
        <a  *ngIf="!isReadOnly">
          <div class="label"><i class="zmdi zmdi-account-box-o"></i>
          </div>
        </a>
        <a  *ngIf="isReadOnly"  [routerLink]="" (click)="stepOne()">
          <div class="label"><i class="zmdi zmdi-account-box-o"></i>
          </div>
        </a>
        <div *ngIf="!isReadOnly" class="progress_title">Patient Info</div>
        <div *ngIf="isReadOnly"  (click)="stepOne()" class="progress_title">Patient Info</div>
      </li>
      <li *ngIf="step2" class="step2_success">
        <a >
          <div class="label"><i class="zmdi zmdi-account-calendar"></i>
          </div>
        </a>

        <div class="progress_title">Clinician Info</div>
      </li>
      <li *ngIf="!step2">
        <a *ngIf="!isReadOnly">
          <div class="label"><i class="zmdi zmdi-account-calendar"></i>
          </div>
        </a>
        <a *ngIf="isReadOnly" [routerLink]="" (click)="stepTwo()">
          <div class="label"><i class="zmdi zmdi-account-calendar"></i>
          </div>
        </a>
        <div *ngIf="!isReadOnly" class="progress_title">Clinician Info</div>
        <div *ngIf="isReadOnly" (click)="stepTwo()" class="progress_title">Clinician Info</div>

      </li>
      <li *ngIf="step3" class="step3_success">
        <a>
          <div class="label "><i class="zmdi zmdi-calendar"></i>
          </div>
        </a>
        <div class="progress_title">Sample Info</div>
      </li>
      <li *ngIf="!step3">
        <a *ngIf="!isReadOnly">
          <div class="label"><i class="zmdi zmdi-calendar"></i>
          </div>
        </a>
        <a *ngIf="isReadOnly"   [routerLink]="" (click)="stepThree()">
          <div class="label"><i class="zmdi zmdi-calendar"></i>
          </div>
        </a>
        <div *ngIf="!isReadOnly"  class="progress_title">Sample Info</div>
        <div *ngIf="isReadOnly" (click)="stepThree()" class="progress_title">Sample Info</div>

      </li>


      <li *ngIf="!isTMHUser && !isLifeCell && step5" class="step5_success">
        <a>
          <div class="label "><i class="zmdi zmdi-filter-frames"></i>
          </div>
        </a>
        <div class="progress_title">Cancer Details</div>
      </li>
      <li *ngIf="!isTMHUser && !isLifeCell && !step5">
        <a *ngIf="!isReadOnly">
          <div class="label"><i class="zmdi zmdi-filter-frames"></i>
          </div>
        </a>
        <a  *ngIf="isReadOnly"  [routerLink]="" (click)="stepFive()">
          <div class="label"><i class="zmdi zmdi-filter-frames"></i>
          </div>
        </a>
        <div  *ngIf="!isReadOnly" class="progress_title">Cancer Details</div>
        <div  *ngIf="isReadOnly"  (click)="stepFive()"class="progress_title">Cancer Details</div>
      </li>


      <li *ngIf="(isTMHUser ) && step4" class="step4_success">
        <a>
          <div class="label"><i class="zmdi zmdi-filter-frames"></i>
          </div>
        </a>
        <div class="progress_title" *ngIf="isTMHUser && !isLifeCell && step4">Report Info</div>
      </li>
      <li *ngIf="(isTMHUser ) && !step4">
        <a *ngIf="!isReadOnly">
          <div class="label"><i class="zmdi zmdi-filter-frames"></i>
          </div>
        </a>
        <a *ngIf="isReadOnly"  [routerLink]="" (click)="stepFour()">
          <div class="label"><i class="zmdi zmdi-filter-frames"></i>
          </div>
        </a>
        <div class="progress_title" *ngIf="isTMHUser && !isLifeCell && !step4 && !isReadOnly">Report Info</div>
        <div (click)="stepFour()" class="progress_title" *ngIf="isTMHUser && !isLifeCell && !step4&& isReadOnly">Report Info</div>
      </li>


      <li *ngIf="(isLifeCell) && step4  && isHrrGermline" class="step4_success">
        <a>
          <div class="label"><i class="zmdi zmdi-filter-frames"></i>
          </div>
        </a>
        <div class="progress_title" >Family History</div>
      </li>
      <li *ngIf="(isLifeCell) && !step4 && isHrrGermline">
        <a *ngIf="!isReadOnly">
          <div class="label"><i class="zmdi zmdi-filter-frames"></i>
          </div>
        </a>
        <a  *ngIf="isReadOnly"  [routerLink]="" (click)="stepFour()">
          <div class="label"><i class="zmdi zmdi-filter-frames"></i>
          </div>
        </a>
        <div class="progress_title" *ngIf="!isTMHUser && isLifeCell && !step4 && isHrrGermline && !isReadOnly">Family History</div>
        <div (click)="stepFour()" class="progress_title" *ngIf="!isTMHUser && isLifeCell && !step4 && isHrrGermline && isReadOnly">Family History</div>

      </li>


      <li *ngIf="(isTMHUser || isLifeCell) && step5" class="step4_success">
        <a>
          <div *ngIf="isTMHUser" class="label"><i class="zmdi zmdi-filter-frames"></i></div>
          <div *ngIf="isLifeCell && !isHrrGermline"  class="label"><i class="zmdi zmdi-filter-frames"></i></div>
          <div *ngIf="isLifeCell && isHrrGermline" class="label"><i class="zmdi zmdi-filter-frames"></i></div>
        </a>
        <div class="progress_title" >Cancer Details</div>
      </li>

      <li *ngIf="(isTMHUser  || isLifeCell) && !step5" >
        <a *ngIf="!isReadOnly">
          <div *ngIf="isTMHUser" class="label"><i class="zmdi zmdi-filter-frames"></i></div>
          <div *ngIf="isLifeCell && !isHrrGermline"  class="label"><i class="zmdi zmdi-filter-frames"></i></div>
          <div *ngIf="isLifeCell && isHrrGermline" class="label"><i class="zmdi zmdi-filter-frames"></i></div>

        </a>
        <a  *ngIf="isReadOnly"  [routerLink]="" (click)="stepFive()">
          <div *ngIf="isTMHUser" class="label"><i class="zmdi zmdi-filter-frames"></i></div>
          <div *ngIf="isLifeCell && !isHrrGermline"  class="label"><i class="zmdi zmdi-filter-frames"></i></div>
          <div *ngIf="isLifeCell && isHrrGermline" class="label"><i class="zmdi zmdi-filter-frames"></i></div>

        </a>
        <div *ngIf="!isReadOnly" class="progress_title" >cancer Details</div>
        <div *ngIf="isReadOnly" class="progress_title" (click)="stepFive()">cancer Details</div>

      </li>
    </ul>


    <div class="timeline_form mt-3">
      <div class="row " *ngIf="step1">
          <!-- Patinet Info -->
        <div class="col-2">
          <h6 class="text-cyan mb-0 timeline_h6">Personal Details</h6>
          <p>Provide Patient Info</p>
        </div>
        <div class="col">
        <div class="form_main">
          <form  #addPatientInfo="ngForm" class="px-5">
            <div class="row mb-2">

              <div class="col-lg-12 col-md-12 col-sm-12"  *ngIf="!isLifeCell && !isHCG">
                <label for="">Patient Id</label>
                <input type="text" class="form-control" [disabled]="isReadOnly" placeholder="Patient Id" name="organisationPatientId"
                  [(ngModel)]="patient.organisationPatientId">
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6"  *ngIf="!isLifeCell && isHCG">
                <label for="">Patient Id</label>
                <input type="text" class="form-control" [disabled]="isReadOnly" placeholder="Patient Id" name="organisationPatientId"
                  [(ngModel)]="patient.organisationPatientId">
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6"  *ngIf="!isLifeCell && isHCG">
                <label for="">MRN No</label>
                <input type="text" class="form-control" [disabled]="isReadOnly" placeholder="MRN No" name="orgMRNNo"
                  [(ngModel)]="patient.orgMRNNo">
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6"  *ngIf="isLifeCell">
                <label for="">Lab Id</label>
                <input type="text" class="form-control" [disabled]="isReadOnly" placeholder="lab Id" name="lab"
                  [(ngModel)]="patient.orgLabId">
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6"  *ngIf="isLifeCell">
                <label for="">CRM No</label>
                <input type="text" class="form-control" [disabled]="isReadOnly" placeholder="Crm No" name="crmNo"
                  [(ngModel)]="patient.orgCrmId">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                <label for="">First Name *</label>
                <input type="text" class="form-control" [disabled]="isReadOnly" placeholder="First Name" name="patientName"
                  [(ngModel)]="patient.patientName" required #patientName="ngModel">
                <div *ngIf="patientName.invalid && (patientName.dirty || patientName.touched)" class="alert alert-danger">
                  <div *ngIf="patientName.errors?.required">First Name is required.</div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                <label for="">Last Name *</label>
                <input [disabled]="isReadOnly" type="text" class="form-control" placeholder="Last Name" name="familyName"
                  [(ngModel)]="patient.familyName" required #familyName="ngModel">
                <div *ngIf="familyName.invalid && (familyName.dirty || familyName.touched)" class="alert alert-danger">
                  <div *ngIf="familyName.errors?.required">Last Name is required.</div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                <label for="">Email *</label>
                <input [disabled]="isReadOnly" type="email" class="form-control" placeholder="Email" name="patientEmail"
                  [(ngModel)]="patient.patientEmail" #patientEmail="ngModel" required>
                <div *ngIf="patientEmail.invalid && (patientEmail.dirty || patientEmail.touched)"
                  class="alert alert-danger">
                  <div *ngIf="patientEmail.errors?.required">Email is required.</div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                <label for="">Contact Number *</label>
                <input [disabled]="isReadOnly" type="text" class="form-control" placeholder="Contact " name="patientMobileNumber"
                  [(ngModel)]="patient.patientMobileNumber" #patientMobileNumber="ngModel" required>
                <div *ngIf="patientMobileNumber.invalid && (patientMobileNumber.dirty || patientMobileNumber.touched)"
                  class="alert alert-danger">
                  <div *ngIf="patientMobileNumber.errors?.required">
                    number is required.
                  </div>
                  <div *ngIf="patientMobileNumber.errors?.minlength">
                    number must be at least 10 digits long.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">

                <div *ngIf="!isAge">
                  <label for="">Date Of Birth *</label>
                  <input [disabled]="isReadOnly" type="date" class="form-control" placeholder="" (change)="CalculateAge()" name="dateOfBirth"
                    [(ngModel)]="patient.dateOfBirth" required #dateOfBirth="ngModel">
                  <div *ngIf="dateOfBirth.invalid && (dateOfBirth.dirty || dateOfBirth.touched)"
                    class="alert alert-danger">
                    <div *ngIf="dateOfBirth.errors?.required">DOB required.</div>
                  </div>
                </div>

                <div *ngIf="isAge">
                  <label for="">Age *</label>
                  <input [disabled]="isReadOnly" type="number" class="form-control" placeholder="" (keyup)="CalculateDOB()" name="age"
                    [(ngModel)]="patient.age" required #age="ngModel">
                  <div *ngIf="age.invalid && (age.dirty || age.touched)" class="alert alert-danger">
                    <div *ngIf="age.errors?.required">Age required.</div>
                  </div>
                </div>

                <div class="form-check cust_form-check mt-1 pl-0" >
                  <!-- <label class="form-check-label mb-0">
                    <input type="checkbox" class="form-check-input" >
                  </label> -->
                  <label class="switch">
                    <input [disabled]="isReadOnly" type="checkbox" (click)="clickedOnAge()"  [checked]="isAge">
                    <span class="slider round"></span>
                  </label>
                 <span class="toggle_label"> Click if only age is available</span>
                </div>
              </div>

              <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                <div class="form-group mb-0">
                  <label>Gender *</label>
                  <select class="form-control" name="gender" [disabled]="isReadOnly" [(ngModel)]="patient.gender" #gender="ngModel" required>
                    <option value="MALE">MALE</option>
                    <option value="FEMALE">FEMALE</option>
                    <option value="OTHERS">OTHERS</option>
                    <option value="UNKNOWN">UNKNOWN</option>
                  </select>
                  <div *ngIf="gender.invalid &&(gender.dirty || gender.touched)" class="alert alert-danger">
                    <div *ngIf="gender.errors?.required">Gender is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="step_btns">
          <button type="button" class="btn btn-secondary "  [routerLink]="['/patient_list']">Cancel</button>
          <button type="button" *ngIf="!isReadOnly" class="btn btn_cyan ml-2" (click)="stepTwo()" [disabled]="!addPatientInfo.form.valid ">Next</button>
          <button type="button" *ngIf="isReadOnly" class="btn btn_cyan ml-2" (click)="stepTwo()" >Next</button>
          </div>

        </div>
        </div>
        </div>

          <!-- Clinician Info -->
        <div class="row" *ngIf="step2">
          <div class="col-2">
            <h6 class="text-cyan mb-0 timeline_h6">Clinician Info</h6>
            <p>Provide Clinician details</p>
          </div>
         <div class="col">
          <div class="form_main">
            <form #hospitalForm="ngForm" class="px-5">
              <div class="row mb-2">
                <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                  <div class="form-group  mb-0">
                    <label>Consultant Hospital *</label>
                    <select class="form-control" [disabled]="isReadOnly" (change)="getHospitalLocations(medicalInfo.hospitalId)" name="hospitalId"
                      [(ngModel)]="medicalInfo.hospitalId" required #hospitalId="ngModel">
                      <option *ngFor="let hospital of hospitals" [selected]="hospital.id === medicalInfo.hospitalId"
                        value="{{ hospital.id }}">
                        {{ hospital.name }}
                      </option>
                    </select>
                    <div *ngIf="hospitalId.invalid && (hospitalId.dirty || hospitalId.touched)" class="alert alert-danger">
                      <div *ngIf="hospitalId.errors?.required">Consultant Hospital is required.</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0" *ngIf="isOtherHospital">
                  <label for="">Hospital Name*</label>
                  <input type="text" class="form-control"  name="hospitalName" [(ngModel)]="medicalInfo.hospitalName"
                    required #doctorEmailId="ngModel">
                  <div *ngIf="doctorEmailId.invalid && (doctorEmailId.dirty || doctorEmailId.touched)"
                    class="alert alert-danger">
                    <div *ngIf="doctorEmailId.errors?.required">Hospital Name is required.</div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0"*ngIf="!isOtherHospital">
                  <label for="">Hospital Location</label>
                  <select class="form-control" [disabled]="isReadOnly" name="hospitalLocationId" [(ngModel)]="medicalInfo.hospitalLocationId"
                    (change)="getHospitalLocation(medicalInfo.hospitalLocationId)">
                    <option *ngFor="let hospitalLocation of hospitalLocations"
                      [selected]="hospitalLocation.id === medicalInfo.hospitalLocationId" value="{{ hospitalLocation.id }}">
                      {{ hospitalLocation.branchName }}, {{hospitalLocation.address.city}},
                      {{hospitalLocation.address.state}}
                    </option>
                  </select>
                </div>
              </div>


              <div class="row mb-2">
                <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                  <label for="">Consulting Doctor *</label>
                  <input type="text" class="form-control" [disabled]="isReadOnly" name="doctorName" [(ngModel)]="medicalInfo.doctorName" required
                    #doctorName="ngModel">
                  <div *ngIf="doctorName.invalid && (doctorName.dirty || doctorName.touched)" class="alert alert-danger">
                    <div *ngIf="doctorName.errors?.required">Consulting Doctor is required.</div>
                  </div>
                </div>

                <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0" *ngIf="!isHCG">
                  <label for="">Doctor Email *</label>
                  <input type="text" class="form-control" [disabled]="isReadOnly" name="doctorEmailId" [(ngModel)]="medicalInfo.doctorEmailId"
                    required #doctorEmailId="ngModel">
                  <div *ngIf="doctorEmailId.invalid && (doctorEmailId.dirty || doctorEmailId.touched)"
                    class="alert alert-danger">
                    <div *ngIf="doctorEmailId.errors?.required">Doctor Email is required.</div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0" *ngIf="isHCG">
                  <label for="">Doctor Email</label>
                  <input type="text" class="form-control" [disabled]="isReadOnly" name="doctorEmailId" [(ngModel)]="medicalInfo.doctorEmailId">
                </div>

              </div>
              <div class="row mb-2">
                <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                  <label for="">Doctor Phone Number</label>
                  <input type="text" [disabled]="isReadOnly" class="form-control" name="doctorPhoneNumber"
                    [(ngModel)]="medicalInfo.doctorPhoneNumber">
                </div>
              </div>

            </form>
            <div class="step_btns">
              <button type="button" class="btn btn-info  cust_btn2 " (click)="stepOne()">Back</button>
              <button type="button" *ngIf="!isReadOnly" class="btn btn_cyan ml-2" (click)=" stepThree()" [disabled]="!hospitalForm.form.valid">Next</button>
              <button type="button" *ngIf="isReadOnly" class="btn btn_cyan ml-2" (click)=" stepThree()">Next</button>

            </div>
          </div>
         </div>


  <!-- Sample Details -->
          </div>
          <div class="row" *ngIf="step3">
            <div class="col-2">
              <h6 class="text-cyan mb-0 timeline_h6">Sample Details</h6>
              <p>Provide Sample Details</p>
            </div>
           <div class="col">
            <div class="form_main">
              <form #testDetails="ngForm" class="px-5">

                <div class="row mb-2">
                  <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                    <label for="">Test Name *</label>
                    <div *ngIf="!patientId" class="">
                      <select class="form-control" [disabled]="isReadOnly" name="packageName" [(ngModel)]="packageName" (ngModelChange)="selectTest($event)" required #testName="ngModel">
                        <option *ngFor="let labTestPackage of labTestPackages" value="{{ labTestPackage.name }}">
                          {{ labTestPackage.name }}
                        </option>
                      </select>
                      <div *ngIf="testName.invalid &&(testName.dirty || testName.touched)" class="alert alert-danger">
                        <div *ngIf="testName.errors?.required">Test Name is required.</div>
                      </div>
                    </div>
                    <div *ngIf="patientId" class="">
                      <input type="text" [disabled]="isReadOnly" class="form-control" name="packageName" [(ngModel)]="packageName" readonly>
                    </div>
                  </div>
                  <div *ngIf="appUser.organisation.includes('LifeCell')" class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                    <label for="">Sub Test Name</label>
                    <select class="form-control" [disabled]="isReadOnly"  name="subTestName" [(ngModel)]="patient.subTestName">
                      <option value="Homologous recombination repair (HRR)">Homologous recombination repair (HRR)</option>
                      <option value="Comprehensive Targeted gene ">Comprehensive Targeted gene</option>
                    </select>
                  </div>
                  <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                    <label for="">Sample Type</label>
                    <select class="form-control" [disabled]="isReadOnly" name="somaticType" [(ngModel)]="sampleInfo.somaticType"  required #somaticType="ngModel">
                      <option value="FFPE">FFPE</option>
                      <option value="Fresh Frozen">Fresh Frozen</option>
                      <option value="Blood (cfDNA)">Blood (cfDNA)</option>
                      <!-- <option value="Slides">Slides</option> -->
                    </select>
                    <div *ngIf="somaticType.invalid &&(somaticType.dirty || somaticType.touched)" class="alert alert-danger">
                      <div *ngIf="somaticType.errors?.required">somatic Type is required.</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="packageName=='TarGT First' || packageName=='Target First'" class="row mb-2">
                <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                  <label for="">DNA Kit</label>
                  <select class="form-control" [disabled]="isReadOnly" name="dnaKitUsed" [(ngModel)]="patient.dnaKitUsed" required #somaticType="ngModel">
                    <option value="FEV2F2both">FEV2F2both</option>
                    <option value="CDS">CDS</option>
                    <option value="FUS">FUS</option>
                  </select>
                  <div *ngIf="somaticType.invalid &&(somaticType.dirty || somaticType.touched)" class="alert alert-danger">
                    <div *ngIf="somaticType.errors?.required">DNA Kit Used is required.</div>
                  </div>
                </div>
              </div>
                <div class="row mb-2">
                  <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                    <label for="">Block / Tube/ Slide ID (If Available)</label>
                    <input type="text" [disabled]="isReadOnly" class="form-control" name="blockId" [(ngModel)]="sampleInfo.blockId">
                  </div>
                  <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                    <label><i class="zmdi zmdi-info"
                        title="Sample registration date means the date in which sample was received and acknowledged by your lab"></i>
                      Sample Registraion Date</label>
                    <input type="date" [disabled]="isReadOnly" class="form-control" name="sampleRegistrationOn"
                      [(ngModel)]="sampleInfo.sampleRegistrationOn">
                  </div>
                </div>
                  <div class="row mb-2">

                  <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0" *ngIf="isHCG">
                    <label for="">Tumor Content</label>
                    <input type="text" [disabled]="isReadOnly" class="form-control" name="tumorContent" [(ngModel)] = "tmhReportInfoReq.tumorContent">
                  </div>
                  <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0" *ngIf="isHCG">
                    <label for="">Biopsy Site</label>
                    <input type="text" [disabled]="isReadOnly" class="form-control" name="biopsySite" [(ngModel)] = "tmhReportInfoReq.biopsySite">
                  </div>


                </div>
              </form>
              <div class="step_btns">
                <button type="button" class="btn btn_cyan" (click)="stepTwo()">Previous</button>
                <button *ngIf="isLifeCell && !isHrrGermline && !isReadOnly" type="button" class="btn btn_cyan ml-2" (click)="stepFive()" [disabled]="!testDetails.form.valid">Next</button>
                <button *ngIf="isLifeCell && isHrrGermline && !isReadOnly " type="button" class="btn btn_cyan ml-2" (click)="stepFour()" [disabled]="!testDetails.form.valid">Next</button>
                <button *ngIf="isTMHUser && !isReadOnly" type="button" class="btn btn_cyan ml-2" (click)="stepFour()" [disabled]="!testDetails.form.valid">Next</button>
                <button *ngIf="!isTMHUser && !isLifeCell && !isReadOnly" type="button" class="btn btn_cyan ml-2" (click)="stepFive()" [disabled]="!testDetails.form.valid">Next</button>
                <button *ngIf="isLifeCell && !isHrrGermline && isReadOnly" type="button" class="btn btn_cyan ml-2" (click)="stepFive()" >Next</button>
                <button *ngIf="isLifeCell && isHrrGermline && isReadOnly " type="button" class="btn btn_cyan ml-2" (click)="stepFour()" >Next</button>
                <button *ngIf="isTMHUser && isReadOnly" type="button" class="btn btn_cyan ml-2" (click)="stepFour()">Next</button>
                <button *ngIf="!isTMHUser && !isLifeCell && isReadOnly" type="button" class="btn btn_cyan ml-2" (click)="stepFive()" >Next</button>

               </div>
            </div>
           </div>
            </div>



              <!-- TMH Report Info -->
            <div class="row" *ngIf="isTMHUser && step4">
              <div class="col-2">
                <h6 class="text-cyan mb-0 timeline_h6">TMH Report Info</h6>
                <p>Provide TMH Report Info</p>
              </div>
             <div class="col">
              <div class="form_main">
                <form  #tmhReportForm="ngForm" class="px-5">
                  <div class="row mb-2">
                    <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                      <div class="form-group  mb-0">
                        <label>Case No</label>
                        <input type="text" [disabled]="isReadOnly" class="form-control" name="caseNo" [(ngModel)] = "tmhReportInfoReq.caseNo">
                      </div>
                    </div>
                    <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                      <label for="">Path No</label>
                      <input type="text" [disabled]="isReadOnly" class="form-control" name="pathNo" [(ngModel)] = "tmhReportInfoReq.pathNo">
                    </div>
                  </div>


                  <div class="row mb-2">
                    <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                      <label for="">Category</label>
                      <input type="text" [disabled]="isReadOnly" class="form-control" name="category" [(ngModel)] = "tmhReportInfoReq.category">
                    </div>
                    <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                      <label for="">Tumor content</label>
                      <input type="text" [disabled]="isReadOnly" class="form-control" name="tumorContent" [(ngModel)] = "tmhReportInfoReq.tumorContent">
                    </div>
                    <!-- <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                      <label for="">Signing Authority</label>
                      <input type="text" [disabled]="isReadOnly" class="form-control" name="signingAuthority" [(ngModel)] = "tmhReportInfoReq.signingAuthority">
                    </div> -->

                  </div>
                  <div class="row">
                    <!-- <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                      <label for="">Designation</label>
                      <input type="text" [disabled]="isReadOnly" class="form-control" name="designation" [(ngModel)] = "tmhReportInfoReq.designation">
                    </div> -->

                  </div>


                </form>
                <div class="step_btns">
                  <button type="button" class="btn btn_cyan" (click)=" stepThree()">Previous</button>
                  <button type="button" class="btn btn_cyan ml-2" (click)=" stepFive()">Next</button>
                </div>
              </div>
             </div>
              </div>


  <!--Family History-->
              <div class="row" *ngIf="isLifeCell && step4">
                <div class="col-2">
                  <h6 class="text-cyan mb-0 timeline_h6">Family History</h6>
                  <p>Provide Family History Info</p>
                </div>
               <div class="col">
                <div class="form_main">
                  <form  #familyHistoryForm="ngForm" class="px-5">
                    <div class="form-group row mb-2">

                      <div class="col-sm-12 ">
                        <div *ngFor="let add_family of addFamilyHistory.addFamilyHistorys; let i = index " class="w-75 bg-white">
                      <div class="form-group row p-4 mx-0 pr-0" style="border: 1px solid #d5dadf ;padding:5px;border-radius: 5px;">
                        <label for="" class="col-sm-3 col-form-label">Family Type :</label>
                        <div class="col-sm-3 position-relative mb-1 pr-0">
                          <select type="text" class="form-control" [disabled]="isReadOnly" name="familyType_{{ i }}" [(ngModel)]="add_family.familyType">
                            <option  value="Proband">Proband</option>
                            <option  value="Immediate Relatives">Immediate Relatives</option>
                            <option  value="Son/Daughter">Son/Daughter</option>
                            <option  value="Paternal Relatives">Paternal Relatives</option>
                            <option  value="Maternal Relatives">Maternal Relatives</option>
                          </select>
                        </div>
                        <label class="col-sm-3 col-form-label mb-1">Relationship :</label>
                        <div class="col-sm-3 position-relative pl-0">
                          <input type="text" name="relationship_{{ i }}" [disabled]="isReadOnly" class="form-control" [(ngModel)]="add_family.relationship" />
                        </div>
                        <div class="w-100"></div>
                        <label for="" class="col-sm-3 col-form-label">CancerType :</label>
                        <div class="col-sm position-relative" style="margin-bottom:4px;">
                          <input type="text" [disabled]="isReadOnly" name="cancerType_{{ i }}" class="form-control" [(ngModel)]="add_family.cancerType" />
                        </div>
                        <div class="w-100"></div>
                        <label for="" class="col-sm-3 col-form-label">Diagnosis Age :</label>
                        <div class="col-sm position-relative">
                          <input type="text" [disabled]="isReadOnly" class="form-control" name="diagnosisAge_{{ i }}"
                          [(ngModel)]="add_family.diagnosisAge">
                        </div>
                        <!-- btn close -->
                        <div class="btn_close" [hidden]="isReadOnly"  (click)="removeFamily(i,add_family)">
                          <a ><i class="zmdi zmdi-close"></i></a>
                        </div>
                      </div>


                      </div>

                      </div>
                  </div>


                  </form>
                  <div class="step_btns">
                    <button type="button" class="btn btn_cyan" (click)=" stepThree()">Previous</button>
                    <button type="button" class="btn btn_cyan ml-2" (click)=" stepFive()">Next</button>
                       </div>
                </div>
               </div>

                </div>
  <!-- Cancer Details -->

            <div class="row" *ngIf="step5">
              <div class="col-2">
                <h6 class="text-cyan mb-0 timeline_h6">Cancer Details</h6>
                <p>Provide Cancer Details</p>
              </div>
             <div class="col">
              <div class="form_main">
                <form action="" class="px-5">
                  <div *ngFor="let add_cancer of addCancer.addCancers; let i = index">
                    <div class="row mb-2">
                      <div class=" col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                        <label for="">Clinical Indication *</label>
                        <input type="text" [disabled]="isReadOnly" class="form-control" name="cancerIdentifier" [(ngModel)]="cancerIdentifier">
                      </div>
                      <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                        <div class="form-group mb-0">
                          <label>Cancer Site *</label>
                          <select class="form-control" [disabled]="isReadOnly" name="cancerSite_{{ i }}" [(ngModel)]="add_cancer.cancerSite"
                            (change)="getCancerTypes(add_cancer.cancerSite)">
                            <option *ngFor="let cancerSite of cancerSites" value="{{ cancerSite.id }}">
                              {{ cancerSite.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                        <div class="form-group mb-0">
                          <label>Cancer Location</label>
                          <select class="form-control" [disabled]="isReadOnly" name="cancerLocation_{{ i }}" [(ngModel)]="add_cancer.cancerLocation"
                            (change)="getCancerSubTypes(add_cancer.cancerLocation)">
                            <option *ngFor="let cancerType of cancerTypes" value="{{ cancerType.id }}">
                              {{ cancerType.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                        <div class="form-group mb-0">
                          <label>Morphology </label>
                          <select class="form-control" [disabled]="isReadOnly" name="morphologyType_{{ i }}" [(ngModel)]="add_cancer.morphologyType">
                            <option *ngFor="let morphology of morphologies" value="{{ morphology.id }}">
                              {{ morphology.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                        <label for="">Laterality</label>
                        <select class="form-control" [disabled]="isReadOnly" name="laterality_{{ i }}" [(ngModel)]="add_cancer.laterality">
                          <option *ngFor="let lateralities of lateralities" value="{{ lateralities.id }}">
                            {{ lateralities.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                        <label for="">Biomarker</label>
                        <input type="text" class="form-control" [disabled]="isReadOnly" name="biomarker" [(ngModel)]="biomarker">
                      </div>
                      </div>
                      <div class="row mb-2">

                      <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0" *ngIf="isHCG">
                        <label for="">Hormonal Changes</label>
                        <input type="text" class="form-control" [disabled]="isReadOnly" name="hormonalChanges" [(ngModel)]="hormonalChanges">
                      </div>
                    </div>
                    <div class="row mb-2" *ngIf="isKokilaben">
                      <div class="col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                        <label for="">Tumor Content </label>
                        <input type="text" class="form-control" [disabled]="isReadOnly" name="tumorContent" [(ngModel)]="tmhReportInfoReq.tumorContent">

                      </div>
                      </div>
                  </div>
                  <div class="row mb-2"  *ngIf="isAccessAllowedButtom && (packageName=='TarGT First' || packageName=='Target First')">
                    <div class=" col-lg-6 col-sm-6  col-md-6 mt-2 mt-lg-0 mt-md-0 mt-sm-0">
                      <label>Cancer Type *</label>
                      <select class="form-control" [disabled]="isReadOnly" name="wbCancerType"  [(ngModel)]="medicalInfo.wbCancerType">
                        <option *ngFor="let cancerSite of cancerTypeList" value="{{ cancerSite.name }}">
                          {{ cancerSite.name }}
                        </option>
                      </select>
                    </div>
                  </div>





                </form>
                <div class="row mt-4">
                  <div class="col d-flex">

                    <div class="btns ml-auto text-right d-flex">
                      <div *ngIf="patientId" class="ml-2">
                        <button  [disabled]="isReadOnly" type="button" class="btn btn_cyan mr-2" [routerLink]="['/upload', patientId]">Upload Files</button>
                      </div>
                      <button *ngIf="!isTMHUser" type="button"  class="btn btn_cyan" (click)="stepThree()">Previous</button>
                      <button *ngIf="isTMHUser" type="button" class="btn btn_cyan" (click)="stepFour()">Previous</button>
                      <button *ngIf="(isReadOnly?!isReadOnly:(!isSaving && !patientId))"  type="button" class="btn btn_cyan ml-2 save_button" (click)="addPatient()">Save and Upload Files</button>
                      <button *ngIf="(isReadOnly?!isReadOnly:(!isSaving && patientId))"  type="button" class="btn btn_cyan ml-2" (click)="updatePatient()">Update</button>
                      <button *ngIf="isSaving" class="btn btn-primary ml-2" disabled>
                        <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Saving
                    </button>
                          </div>
                  </div>
                </div>
              </div>
             </div>

              </div>
    </div>


  </div>
</div>
