import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Documents, ReqId, SampleInfoReq } from 'src/app/_interfaces/lab-model';
import { LabService } from 'src/app/_services/oncobench-service/lab.service';
import { DnaAndSomaticTypeDataReq, PatientRes } from '../file-upload/file-upload.component';
import { User } from 'src/app/_interfaces/auth.model';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bio-informatics',
  templateUrl: './bio-informatics.component.html',
  styleUrls: ['./bio-informatics.component.scss']
})
export class BioInformaticsComponent implements OnInit {

  constructor(
    private labService: LabService,
    private router: Router,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService

  ) { }
  patientId!:number;
  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem('user') || '{}');

    this.patientId = this.routerAct.snapshot.params["id"];
    if (this.patientId) {
      this.getPatient();
      this.getDocuments();
      this.repeatationStop();
      this.analysisStatusInterval = setInterval(() => {
        this.getAnalysisStatus();
      }, 3000);
    }
  }
  isProcessing: boolean = false;
  ecrfId: any;
  sampleInfo = {} as SampleInfoReq;
  ecrfPatientId: any;
  isUpdated = false;
  reqId = {} as ReqId;
  patient = {} as PatientRes;
  dnaAndSomaticTypeDataReq = {} as DnaAndSomaticTypeDataReq;
  appUser = {} as User;
  documentsList: Documents[] = [];
  dnaR1Documents: Documents[] = [];
  dnaR2Documents: Documents[] = [];
  rnaR1Documents: Documents[] = [];
  rnaR2Documents: Documents[] = [];
  histoDocuments: Documents[] = [];
  vcfDocuments: Documents[] = [];
  isBioModel=false;
  bamDocuments: Documents[] = [];
  baiDocuments: Documents[] = [];
  isStartAnalysisDisabled:boolean=false;
  analysisStatusInterval:any
  analysisStatus:any=[{
    name:'uploadFileStatus',
    value:'File upload',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },

  {
    name:'dragon3_9Status',
    value:'Dragon 3.9',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },

  {
    name:'basespaceAnalysisTestStatus',
    value:'Basespace Analysis Test',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },
  {
    name:'basespaceMountStatus',
    value:'Basespace Mount',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },
  {
    name:'cnvStatus',
    value:'CNV',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },

  {
    name:'dnaQcStatus',
    value:'DNA QC',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },
  {
    name:'annotationStatus',
    value:'Annotation',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },

  {
    name:'uploadDirAwsStatus',
    value:'Upload to AWS',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },
  {
    name:'downloadDirAwsStatus',
    value:'Download From AWS CGI',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },
  {
    name:'cgiStatus',
    value:'CGI',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },
  {
    name:'filterEngineStatus',
    value:'Filter Engine',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  },
  {
    name:'cgiUploadDirAwsStatus',
    value:'CGI upload to AWS',
    icon:'zmdi zmdi-spinner',
    status:'',
    class:''
  }
]

analysisStatusFEV2F2both:any=[{
  name:'uploadFileStatus',
  value:'File upload',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'SequenceAlignmentAndVariantCalling',
  value:'Sequence alignment and Variant calling',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'dnaQcStatus',
  value:'DNA QC',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'dnaFusionStatus',
  value:'DNA Fusion',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'cnvStatus',
  value:'CNV Analysis',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'variantAnnotation',
  value:'Variant Annotation',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},{
  name:'variantFilterationAndPrioritization',
  value:'Variant Filteration and Prioritization',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
}
];

analysisStatusCDS:any=[{
  name:'uploadFileStatus',
  value:'File upload',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'SequenceAlignmentAndVariantCalling',
  value:'Sequence alignment and Variant calling',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'dnaQcStatus',
  value:'DNA QC',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'cnvStatus',
  value:'CNV Analysis',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'variantAnnotation',
  value:'Variant Annotation',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},{
  name:'variantFilterationAndPrioritization',
  value:'Variant Filteration and Prioritization',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
}
];

analysisStatusFUS:any=[{
  name:'uploadFileStatus',
  value:'File upload',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'SequenceAlignmentAndVariantCalling',
  value:'Sequence alignment and Variant calling',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'dnaQcStatus',
  value:'DNA QC',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
},
{
  name:'dnaFusionStatus',
  value:'DNA Fusion',
  icon:'zmdi zmdi-spinner',
  status:'',
  class:''
}
];
subscription!: Subscription;

repeatationStop() {
  this.subscription && this.subscription.unsubscribe();
}
getPatient() {
  this.reqId.id = this.patientId;
  this.labService.getPatient(this.reqId).subscribe(
    (response: any) => {
      console.log("response==>",response)
      this.patient = response['payLoad'];
      if(this.appUser.is4BaseCare==true){
        this.sampleInfo.somaticType=this.patient.sampleSourceType;
        this.dnaAndSomaticTypeDataReq.somaticType=this.patient.sampleSourceType;
        this.isTargetFirst=true;
        let testName:string[]=[];
        if(this.patient.patientTestPackages!=null){
          this.patient.patientTestPackages.forEach((element:any) => {
            testName.push(element?.testPackageName);
          });
          this.patient.testPackageName=testName.toString();
        }
      }else{
      this.sampleInfo = response["bio"];
    }
      this.ecrfId=response['payLoad']['patientId'];
      console.log("get patient", this.patient);

      this.dnaAndSomaticTypeDataReq.dnaKitUsed=this.patient.dnaKitUsed;
      if(this.dnaAndSomaticTypeDataReq.dnaKitUsed!=null && this.dnaAndSomaticTypeDataReq.somaticType!=null){
        this.isUpdated=true;
      }
      if(this.appUser.organisation=="Lifecell"){
        this.ecrfPatientId=this.patient.orgLabId;
      }else if(this.appUser.organisation=="4baseCare Onco Solutions"){
        this.ecrfPatientId=this.patient.patientId;
      }else{
        this.ecrfPatientId=this.patient.organisationPatientId;
      }
      if(this.patient.testPackageName =='TarGT First'|| this.patient.testPackageName =='Target First' ||this.appUser.is4BaseCare){
        this.isTargetFirst=true
        if(this.patient.oncobenchAnalysisStatus!=null  && this.patient.oncobenchAnalysisStatus=="COMPLETED"){
          this.isStartAnalysisDisabled=true
          this.showStatAnalysis=false

          this.isTargetFirst=false
          this.showAlertMessage=true
          this.alertType='success'
          this.analysisMessage="Analysis Completed"
          if(this.analysisStatusReload){
            clearInterval(this.analysisStatusReload)
          }
        }
        if(this.patient.oncobenchAnalysisStatus!=null  && this.patient.oncobenchAnalysisStatus=="STARTED"){
          this.showStatAnalysis=false
          this.isStartAnalysisDisabled=true
          this.showAlertMessage=true
          this.alertType='warning'
          this.analysisMessage="Analysis in process"
          this.analysisStatusReload=setInterval(()=>{
            this.reload()
          },300000)
        }
        if(this.patient.oncobenchAnalysisStatus!=null  && this.patient.oncobenchAnalysisStatus=="FAILED"){
          this.isStartAnalysisDisabled=true
          this.showStatAnalysis=false;
          this.showAlertMessage=true
          this.alertType='danger'
          this.analysisMessage="Analysis is failed you can start again or contact to team"
          if(this.analysisStatusReload){
            clearInterval(this.analysisStatusReload)
          }
        }
        if(this.patient.oncobenchAnalysisStatus==null){
          this.isStartAnalysisDisabled=false
        }
      }else{
        this.isTargetFirst=false
      }
    });
}

  showAlertMessage:boolean=false
  alertType='success'
  analysisMessage:string=''
  analysisStatusReload:any

  showSuccess(message:string) {
    this.toastr.success(message,'',
    { timeOut: 2000}
    );
  }

  reload(){
    window.location.reload();
  }
  isAccessAllowedButtom=false
  showStatAnalysis:boolean=false
  isTargetFirst=false
  startAnalysis(){
    this.labService.startAnalysis(this.patientId).subscribe((res:any)=>{
      this.isStartAnalysisDisabled=true
      this.showStatAnalysis=false

      this.showAlertMessage=true
      this.alertType='warning'
      this.analysisMessage="Analysis in process"
      this.showSuccess("Analysis Started")
      this.analysisStatusInterval=setInterval(()=>{
        this.getAnalysisStatus();
      },3000)
      this.analysisStatusReload=setInterval(()=>{
        this.reload()
      },300000)
    })
  }
  confirm() {
    let swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success mr-2',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'BIOINFORMATICS ANALYSIS',
        text: 'Do you wish to continue with the analysis now?',
        icon: 'warning',
        showCancelButton: true,
        backdrop: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Proceed',
      })
      .then((result:any) => {
        if (result.isConfirmed) {
          //click on Proceed then.
          this.startAnalysis();
        }
      });
  }
  getAnalysisStatus(){
    this.labService.getAnalysisStatus(this.patientId).subscribe((res:any)=>{
      console.log("Analysis status => ",res)
      if(res["payLoad"]!=null){
        let analysisStatusData=res["payLoad"]

        if (this.patient.dnaKitUsed == 'FEV2F2both') {
          this.analysisStatusFEV2F2both.forEach((element: any) => {
            if (
              element.name == 'uploadFileStatus' ||
              element.name == 'dnaQcStatus' ||
              element.name == 'dnaFusionStatus' ||
              element.name == 'cnvStatus'
            ) {
              let data = analysisStatusData[element.name];
              if (data == 'STARTED') {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (data == 'FAILED' || data == 'ERROR') {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (data == 'COMPLETED') {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            } else if (element.name == 'SequenceAlignmentAndVariantCalling') {
              let rename1 = analysisStatusData['renameFastqFileStatus1'];
              let dragon = analysisStatusData['dragon3_9Status'];
              let rename2 = analysisStatusData['renameFastqFileStatus2'];
              let bats = analysisStatusData['basespaceAnalysisTestStatus'];
              let bma = analysisStatusData['basespaceMountStatus'];
              if (
                dragon == 'STARTED' ||
                bats == 'STARTED' ||
                bma == 'STARTED' ||
                rename1 == 'STARTED' ||
                rename2 == 'STARTED'
              ) {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (
                dragon == 'FAILED' ||
                bats == 'FAILED' ||
                bma == 'FAILED' ||
                dragon == 'ERROR' ||
                bats == 'ERROR' ||
                bma == 'ERROR' ||
                rename1 == 'FAILED' ||
                rename1 == 'ERROR' ||
                rename1 == 'FAILED' ||
                rename2 == 'ERROR'
              ) {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (
                dragon == 'COMPLETED' &&
                bats == 'COMPLETED' &&
                bma == 'COMPLETED' &&
                rename1 == 'COMPLETED' &&
                rename2 == 'COMPLETED'
              ) {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            } else if (element.name == 'variantAnnotation') {
              let igv = analysisStatusData['igvStatus'];
              let annotation = analysisStatusData['annotationStatus'];
              let uploadAws = analysisStatusData['uploadDirAwsStatus'];
              let downloadAws = analysisStatusData['downloadDirAwsStatus'];
              // let cgiBMS = analysisStatusData['cgiBasespaceMountStatus'];
              let cgi = analysisStatusData['cgiStatus'];

              if (
                annotation == 'STARTED' ||
                uploadAws == 'STARTED' ||
                downloadAws == 'STARTED' ||
                cgi == 'STARTED' ||
                // cgiBMS == 'STARTED' ||
                igv == 'STARTED'
              ) {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (
                annotation == 'FAILED' ||
                uploadAws == 'FAILED' ||
                downloadAws == 'FAILED' ||
                cgi == 'FAILED' ||
                // cgiBMS == 'FAILED' ||
                igv == 'FAILED' ||
                annotation == 'ERROR' ||
                uploadAws == 'ERROR' ||
                downloadAws == 'ERROR' ||
                cgi == 'ERROR' ||
                // cgiBMS == 'ERROR' ||
                igv == 'ERROR'
              ) {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (
                annotation == 'COMPLETED' &&
                uploadAws == 'COMPLETED' &&
                downloadAws == 'COMPLETED' &&
                cgi == 'COMPLETED' &&
                // cgiBMS == 'COMPLETED' &&
                igv == 'COMPLETED'
              ) {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            } else if (
              element.name == 'variantFilterationAndPrioritization'
            ) {
              let fes = analysisStatusData['filterEngineStatus'];
              let cgiudas = analysisStatusData['cgiUploadDirAwsStatus'];

              if (fes == 'STARTED' || cgiudas == 'STARTED') {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (
                fes == 'FAILED' ||
                cgiudas == 'FAILED' ||
                fes == 'ERROR' ||
                cgiudas == 'ERROR'
              ) {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (fes == 'COMPLETED' && cgiudas == 'COMPLETED') {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            }
          });
        }
        if (this.patient.dnaKitUsed == 'CDS') {
          this.analysisStatusCDS.forEach((element: any) => {
            if (
              element.name == 'uploadFileStatus' ||
              element.name == 'dnaQcStatus' ||
              element.name == 'cnvStatus'
            ) {
              let data = analysisStatusData[element.name];
              if (data == 'STARTED') {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (data == 'FAILED' || data == 'ERROR') {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (data == 'COMPLETED') {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            } else if (element.name == 'SequenceAlignmentAndVariantCalling') {
              let rename1 = analysisStatusData['renameFastqFileStatus1'];
              let dragon = analysisStatusData['dragon3_9Status'];
              let rename2 = analysisStatusData['renameFastqFileStatus2'];
              let bats = analysisStatusData['basespaceAnalysisTestStatus'];
              let bma = analysisStatusData['basespaceMountStatus'];

              if (
                dragon == 'STARTED' ||
                bats == 'STARTED' ||
                bma == 'STARTED' ||
                rename1 == 'STARTED' ||
                rename2 == 'STARTED'
              ) {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (
                dragon == 'FAILED' ||
                bats == 'FAILED' ||
                bma == 'FAILED' ||
                dragon == 'ERROR' ||
                bats == 'ERROR' ||
                bma == 'ERROR' ||
                rename1 == 'FAILED' ||
                rename1 == 'ERROR' ||
                rename1 == 'FAILED' ||
                rename2 == 'ERROR'
              ) {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (
                dragon == 'COMPLETED' &&
                bats == 'COMPLETED' &&
                bma == 'COMPLETED' &&
                rename1 == 'COMPLETED' &&
                rename2 == 'COMPLETED'
              ) {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            } else if (element.name == 'variantAnnotation') {
              let igv = analysisStatusData['igvStatus'];
              let annotation = analysisStatusData['annotationStatus'];
              let uploadAws = analysisStatusData['uploadDirAwsStatus'];
              let downloadAws = analysisStatusData['downloadDirAwsStatus'];
              // let cgiBMS = analysisStatusData['cgiBasespaceMountStatus'];
              let cgi = analysisStatusData['cgiStatus'];

              if (
                annotation == 'STARTED' ||
                uploadAws == 'STARTED' ||
                downloadAws == 'STARTED' ||
                cgi == 'STARTED' ||
                // cgiBMS == 'STARTED' ||
                igv == 'STARTED'
              ) {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (
                annotation == 'FAILED' ||
                uploadAws == 'FAILED' ||
                downloadAws == 'FAILED' ||
                cgi == 'FAILED' ||
                // cgiBMS == 'FAILED' ||
                igv == 'FAILED' ||
                annotation == 'ERROR' ||
                uploadAws == 'ERROR' ||
                downloadAws == 'ERROR' ||
                cgi == 'ERROR' ||
                // cgiBMS == 'ERROR' ||
                igv == 'ERROR'
              ) {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (
                annotation == 'COMPLETED' &&
                uploadAws == 'COMPLETED' &&
                downloadAws == 'COMPLETED' &&
                cgi == 'COMPLETED' &&
                // cgiBMS == 'COMPLETED' &&
                igv == 'COMPLETED'
              ) {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            } else if (
              element.name == 'variantFilterationAndPrioritization'
            ) {
              let fes = analysisStatusData['filterEngineStatus'];
              let cgiudas = analysisStatusData['cgiUploadDirAwsStatus'];

              if (fes == 'STARTED' || cgiudas == 'STARTED') {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (
                fes == 'FAILED' ||
                cgiudas == 'FAILED' ||
                fes == 'ERROR' ||
                cgiudas == 'ERROR'
              ) {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (fes == 'COMPLETED' && cgiudas == 'COMPLETED') {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            }
          });
        }
         if (this.patient.dnaKitUsed == 'FUS') {

          this.analysisStatusFUS.forEach((element: any) => {
            if (
              element.name == 'uploadFileStatus' ||
              element.name == 'dnaQcStatus'
            ) {
              let data = analysisStatusData[element.name];
              if (data == 'STARTED') {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (data == 'FAILED' || data == 'ERROR') {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (data == 'COMPLETED') {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            } else if (element.name == 'SequenceAlignmentAndVariantCalling') {
              let rename1 = analysisStatusData['renameFastqFileStatus1'];
              let dragon = analysisStatusData['dragon3_9Status'];
              let rename2 = analysisStatusData['renameFastqFileStatus2'];
              let bats = analysisStatusData['basespaceAnalysisTestStatus'];
              let bma = analysisStatusData['basespaceMountStatus'];

              if (
                dragon == 'STARTED' ||
                bats == 'STARTED' ||
                bma == 'STARTED' ||
                rename1 == 'STARTED' ||
                rename2 == 'STARTED'
              ) {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (
                dragon == 'FAILED' ||
                bats == 'FAILED' ||
                bma == 'FAILED' ||
                dragon == 'ERROR' ||
                bats == 'ERROR' ||
                bma == 'ERROR' ||
                rename1 == 'FAILED' ||
                rename1 == 'ERROR' ||
                rename1 == 'FAILED' ||
                rename2 == 'ERROR'
              ) {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (
                dragon == 'COMPLETED' &&
                bats == 'COMPLETED' &&
                bma == 'COMPLETED' &&
                rename1 == 'COMPLETED' &&
                rename2 == 'COMPLETED'
              ) {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            } else if (element.name == 'dnaFusionStatus') {
              let dna = analysisStatusData['dnaFusionStatus'];
              let uploadAWs = analysisStatusData['uploadDirAwsStatus'];

              if (dna == 'STARTED' || uploadAWs == 'STARTED') {
                element.class = 'spin';
                element.icon = 'zmdi zmdi-spinner';
              } else if (
                dna == 'FAILED' ||
                uploadAWs == 'FAILED' ||
                dna == 'ERROR' ||
                uploadAWs == 'ERROR'
              ) {
                element.class = 'error';
                element.icon = 'zmdi-close-circle';
              } else if (dna == 'COMPLETED' && uploadAWs == 'COMPLETED') {
                element.class = 'check';
                element.icon = 'zmdi-check-circle';
              } else {
                element.class = '';
                element.icon = 'zmdi zmdi-spinner';
              }
            }
          });
        }
        if(analysisStatusData["finalStatus"]=="FAILED" || analysisStatusData["finalStatus"]=="COMPLETED"){
          if(this.analysisStatusInterval){
            clearInterval(this.analysisStatusInterval)
          }
        }
      }

    })
  }
  getDocuments() {
    console.log("getDocuments method");

    this.labService.getDocuments(this.patientId).subscribe(
      (response: any) => {
        this.documentsList = response['payLoad'];
        console.log(this.documentsList);

        if (this.documentsList.length > 0 && this.patient.patientDocumentStatus != "Report Processing" || this.patient.patientDocumentStatus !== 'Report Generated') {
          this.dnaR1Documents = this.documentsList.filter(function (document) {
            return document.type == 'DNA R1';
          });

          this.dnaR2Documents = this.documentsList.filter(function (document) {
            return document.type == 'DNA R2';
          });

        }
        if(this.dnaR1Documents && this.dnaR1Documents.length>0 && this.dnaR1Documents[0].status=='Uploaded' &&
          this.dnaR2Documents && this.dnaR2Documents.length>0 && this.dnaR2Documents[0].status=='Uploaded'){
          this.showStatAnalysis=true;
        }

      }
    );
  }

}

