import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/_interfaces/auth.model';
import { reportsEmailDto } from 'src/app/_interfaces/lab-model';
import { LabService } from 'src/app/_services/oncobench-service/lab.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  appUser = {} as User;
  workbenchUrl!: string;

  reportsEmail:string[] = [];
  reportsEmailList:reportsEmailDto[] = [];
  isReportButtom = false;

  constructor(private router: Router,
    private labService: LabService,
    ) { }
  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem("user") || '{}');
    this.getAccessibleEmailIdsList();
    this.workbenchUrl = 'http://52.66.98.251:9500';
  }
  getAccessibleEmailIdsList() {
    this.labService.getAccessibleEmailIdsList().subscribe((res: any) => {
      console.log("=>",res['payLoad']);
      this.reportsEmailList=res['payLoad'];
      for(let i=0;i<this.reportsEmailList.length;i++){
        this.reportsEmail.push(this.reportsEmailList[i]?.emailId)
      }
      if(this.reportsEmail.includes(this.appUser.email)){
        this.isReportButtom = true;
      }
    });
  }
  logOut(){
    localStorage.clear();
    this.router.navigate(['/auth']);
  }

}
