import { SnvService } from './../../_services/workbench-service/snv.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {  HotspotVariant } from 'src/app/_interfaces/excel';
import { ReportRes } from 'src/app/_interfaces/reports';
import { DataAdminService } from 'src/app/_services/workbench-service/data-admin.service';
import { HotspotService } from 'src/app/_services/workbench-service/hotspot.service';
import { ReportService } from 'src/app/_services/workbench-service/report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hotspot-variant',
  templateUrl: './hotspot-variant.component.html',
  styleUrls: ['./hotspot-variant.component.scss']
})
export class HotspotVariantComponent implements OnInit {
  hotspotVariant: HotspotVariant[] = [];

  constructor(
    private reportService: ReportService,
    private hotspotService: HotspotService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private dataAdminService: DataAdminService
  ) {}
  organisationName!: any;
  reportId: any;
  switchToggleButton = false;
  igvUrl:any;
  ngOnInit(): void {
    this.organisationName = localStorage.getItem('organisationName') || '';
    this.reportId = this.routerAct.snapshot.params['reportId'];

    if (this.reportId) {
      this.getReport();
    }
    if (environment.wbApiUrl == 'https://www.api.workbench.4basecare.co.in'){
      this.igvUrl = 'https://igv.4basecare.co.in';
    } else {
      this.igvUrl = 'http://52.66.98.251:9505';
    }
  }

  checkAutoUploadHostspotFile() {
    console.log('autoUploadFengFile');

    this.hotspotService
      .checkAutoUploadHostspotFile(this.reportId)
      .subscribe((response: any) => {
        this.hotspotVariant = response['payLoad'];
      });
  }

  testNameSelected: any;
  isTestNameSelected = false;
  isCancerSiteSelected = false;
  cancerSiteName: any;
  selectedRowfilteredCSV!: boolean;

  hotspotVariantStatus(event: any, hotspotVariant: HotspotVariant) {
    this.selectedRowfilteredCSV = !event;

    if (this.selectedRowfilteredCSV) {
      this.hotspotService
        .hotspotVariantStatus(hotspotVariant.id, true)
        .subscribe((response: any) => {
          console.log(response);
          this.getAllHotspotVariantsByReportId();
        });
    } else if (!this.selectedRowfilteredCSV) {
      if (confirm('Are you sure you want unselect..!')) {
        this.hotspotService
          .hotspotVariantStatus(hotspotVariant.id, false)
          .subscribe((response: any) => {
            console.log(response);
            this.getAllHotspotVariantsByReportId();
          });
      } else {
        this.getAllHotspotVariantsByReportId();
      }
    }
  }
  selectedStarVariant!: boolean;

  hotspotVariantStarStatus(event: any, hotspotVariant: HotspotVariant) {
    this.selectedStarVariant = !event;
    console.log(this.selectedStarVariant);
    this.hotspotService
      .hotspotVariantStarStatus(hotspotVariant.id, this.selectedStarVariant)
      .subscribe((response: any) => {
        console.log(response);
        this.getAllHotspotVariantsByReportId();
      });
  }

  report = {} as ReportRes;
  fileName!: string;
  getReport() {
    this.reportService.getReport(this.reportId).subscribe((response: any) => {
      console.log(response);
      this.report = response['payLoad'];
      console.log("this.report.filteredHotspotLink =>"+this.report.filteredHotspotLink )
      if (this.report.filteredHotspotLink == null) {
        this.checkAutoUploadHostspotFile();
      } else if (this.report.filteredHotspotLink != null) {
        if (this.organisationName != null && this.organisationName != '') {
          this.fileName = this.report.filteredHotspotLink.split('/')[8];
        } else {
          this.fileName = this.report.filteredHotspotLink
            .split('/')[4]
            .substring(14);
        }
      }
      this.getAllHotspotVariantsByReportId();
    });
  }

  variantsListSpinner = true;
  showDiv = false;
  getAllHotspotVariantsByReportId() {
    this.variantsListSpinner = true;
    this.hotspotService
      .getAllHotspotVariantsByReportId(this.reportId)
      .subscribe((response: any) => {
        this.variantsListSpinner = false;
        this.showDiv = true;
        console.log(response);
        this.hotspotVariant = response['payLoad'];
        console.log("this.hotspotVariant",this.hotspotVariant)
        if (response['success'] == true) {
            if (this.switchToggleButton) {
              this.variantsListSpinner = true;
              this.getSelectedHotspotVariants();
            }
        }
      });
  }

  getSelectedHotspotVariants() {
    this.hotspotService
      .getSelectedHotspotVariants(this.reportId)
      .subscribe((response: any) => {
        this.variantsListSpinner = false;
        console.log(response);
        this.hotspotVariant = response['payLoad'];

      });
  }

  switchToggle(event: any) {
    this.variantsListSpinner = true;
    this.switchToggleButton = event['target']['checked'];
    console.log('switchToggleButton', this.switchToggleButton);
    if (this.switchToggleButton) {
      this.getSelectedHotspotVariants();
    } else if (!this.switchToggleButton) {
      this.getAllHotspotVariantsByReportId();
    }
  }

  showSuccess(msg: string) {
    this.toastr.success(msg, '', { timeOut: 2000 });
  }

  showError(msg: string) {
    this.toastr.error(msg, '', { timeOut: 2000 });
  }

  setHotspotVariantPriorityOrder(orderNumber: number, variantId: number) {
    console.log(orderNumber, variantId);
    if (orderNumber != null && orderNumber > 0) {
      this.hotspotService
        .setHotspotVariantPriorityOrder(variantId, orderNumber)
        .subscribe((response: any) => {
          console.log('setPriorityOrder', response);
        });
    }
  }
}
