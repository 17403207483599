import { AnalysisComponent } from './analysis/analysis.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientsListComponent } from './patients-list/patients-list.component';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { PagesComponent } from './pages.component';
import { BioInformaticsComponent } from './bio-informatics/bio-informatics.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AuthGuard } from '../_services/auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: PagesComponent,
    children: [
      {
        path : '',
        component: DashboardComponent
      },
  {
    path : 'patients',
    component: PatientsListComponent
  },

  {
    path : 'dashboard',
    component: DashboardComponent
  },
  {
    path : 'add_patient',
    component: AddPatientComponent
  },
  {
    path : 'analysis',
    component:AnalysisComponent
  },
  {
    path : 'bioinformatics',
    component:BioInformaticsComponent
  },
  {
    path : 'upload',
    component:FileUploadComponent
  },
  {
    path: 'upload/:id',
    component: FileUploadComponent
  },
  {
    path: 'edit_patient/:id',
    component: AddPatientComponent
  },
  {
    path: 'view_patient/:id',
    component: AddPatientComponent
  },

  {
    path : 'bioinformatics/:id',
    component:BioInformaticsComponent
  },
]
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
