import { LoginService } from './../../_services/login.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model: any = {};
  wbModel: any = {};
  loginSpinner = false;

  constructor(
     private _loginService: LoginService,
     private router: Router,
     private toastr: ToastrService,
    ) { }

    showSuccess() {
      this.toastr.success('Welcome','',
      { timeOut: 2000}
      );
    }

    // showError(msg:any) {
    //   this.toastr.error(msg, '',
    //     { timeOut: 2000 }
    //   );
    // }


  ngOnInit(): void {

  }

  submitLogin(){
    this.loginSpinner = true;
    console.log("login model", this.model);
    this._loginService.login(this.model).subscribe(
      (data: any) => {
        this.showSuccess();
        console.log(data);
        localStorage.setItem('token', JSON.stringify(data['payLoad']['authToken']));
        localStorage.setItem('user', JSON.stringify(data['payLoad']));
        this.wbModel={'email': "integration@mailinator.com",
        'password': "123"}
        this.submitWBLogin(this.wbModel);
        this.router.navigate(['/patients']);
        // this.router.navigate(['/patients'])
        this.loginSpinner = false;
      },
      (error:any) =>{
        this.loginSpinner = false;
      // this.showError(error.error['message'])
      });
  }

  submitWBLogin(wbModel:any){
    this._loginService.wbLogin(wbModel).subscribe(
      (data: any) => {
        console.log(data);
        localStorage.setItem('wbToken', JSON.stringify(data['payLoad']['authToken']));
        localStorage.setItem('wbUser', JSON.stringify(data['payLoad']));
      },
      (error:any) =>{
      });
  }



}
