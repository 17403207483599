<div class="sidemenu">
  <div class="logo">
    <img src="../../../../assets/logo_icon.png" alt="">
    <div class="color_text">
      <a routerLink="login"><span class="color_1">Onco</span><span class="color_2">bench</span></a>
    </div>
  </div>
  <div class="nav_list ">
    <ul class="ml-0 pl-0">
      <li>
        <a routerLink="dashboard" [routerLinkActive]="'is-active'"><i class="zmdi zmdi-view-dashboard"></i>
          Dashboard</a>
      </li>
      <li>
        <a routerLink="patients" [routerLinkActive]="'is-active'"><i class="zmdi zmdi-accounts-list-alt"></i>
          Patients</a>
      </li>
      <li>
        <a routerLink="analysis" [routerLinkActive]="'is-active'"><i class="zmdi zmdi-format-line-spacing"></i>
          Analysis</a>
      </li>
      <!-- <li>
        <a routerLink="bioinformatics" [routerLinkActive]="'is-active'"><i class="zmdi zmdi-format-line-spacing"></i>
          Bioinformatics</a>
      </li> -->

    </ul>
  </div>

</div>
