import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pluck, share, shareReplay, tap } from 'rxjs/operators';
import { CancerSite, DisclaimerVariant, Drug, GenePattern, HotspotClinicalTrailReqDto, InterpretationReqDto, PrognasticBiomarkerReqDto, TestTypeGene } from 'src/app/_interfaces/admin';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataAdminService {

  constructor(
    private httpClient: HttpClient
  ) { }
  getGeneByName(geneName: string){
    return this.httpClient.get(environment.wbApiUrl+'/admin/' + geneName + '/get_gene',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getAllClinicalTrailByGene(refGeneX: string){
    return this.httpClient.get(environment.wbApiUrl+'/admin/' + refGeneX + '/clinical_trial_list',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getAllHotspotClinicalTrailByGene(refGeneX: string){
    return this.httpClient.get(environment.wbApiUrl+'/admin/' + refGeneX + '/hotspot_clinical_trial_list',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  prognasticBiomarkerList(){
    return this.httpClient.get(environment.wbApiUrl+'/admin/prognastic_biomarker_list',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  deletePrognasticBiomarker(id: number){
    return this.httpClient.get(environment.wbApiUrl+'/admin/'+id+'/delete_prognastic_biomarker',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getPrognasticBiomarkerById(id: number){
    return this.httpClient.get(environment.wbApiUrl+'/admin/' + id + '/get_prognastic_biomarker',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });;
  }

  getPrognasticBySignificance(type: string){
    return this.httpClient.get(environment.wbApiUrl+'/admin/' + type + '/get_prognastic_by_significance',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  uploadAndGetGenesFromExcel(params: any){
    return this.httpClient.post(environment.wbApiUrl+'/admin/get_genes_upload',params,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  disclaimerListByType(type:string){
    return this.httpClient.get(environment.wbApiUrl+`/admin/${type}/disclaimer_list`,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  cancerTypeList() {
    return this.httpClient.get(environment.wbApiUrl+'/admin/get_cancer_types',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getAllHotspotClinicalTrailsCount(refGeneX: string){
    return this.httpClient.get(environment.wbApiUrl+'/admin/hotspot_clinical_trial_count/' + refGeneX,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getAllHotspotClinicalTrailForFusionGenes(geneList: any){
    return this.httpClient.get(environment.wbApiUrl+'/admin/' + geneList + '/hotspot_clinical_trial_list_for_fusion',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getAllClinicalTrailForFusionGene(geneList: any){
    return this.httpClient.get(environment.wbApiUrl+'/admin/' + geneList + '/clinical_trial_list_for_fusion',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
  
  getDomainFunctionByName(functionalDomainName : string){
    return this.httpClient.get(environment.wbApiUrl+'/report/get_domain_function_by_name/'+functionalDomainName,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
}

