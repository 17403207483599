import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HospitalLocation, PaginationReqInterface, Patient, ReqId } from 'src/app/_interfaces/lab-model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LabService {
   url=environment.apiUrl;
  constructor(private _http : HttpClient) { }

  // getPatientList(paginationReqInterface: PaginationReqInterface) {
  //   return this._http.post("/patient/patient_list",paginationReqInterface);
  // }
  // public downloadRecord(url:any): any {
  //   return this._http.get(url, {responseType: 'arraybuffer'},)
  // }

  addPatient(body: Patient){
    return this._http.post(this.url+'/patient/add_patient',body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    }
    );
  }

  updatePatient(body: Patient){
    return this._http.post(this.url+'/patient/update_patient',body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  deletePatient(patientId: number){
    return this._http.get(this.url+"/patient/" + patientId + "/delete_patient",{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  ChangePatientStatus(patientId: number){
    return this._http.get(this.url+"/patient/" + patientId + "/change_status",{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  getPatientList(paginationReqInterface: PaginationReqInterface) {
    return this._http.post(this.url+"/onco_bench/patient_list",paginationReqInterface,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  getOncoBenchAnalyticsData() {
    return this._http.get(this.url+"/onco_bench/onco_analytics_data_overview",{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }


  getPatient(reqId: ReqId){
    return this._http.post(this.url+"/patient/get", reqId,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  getHospitals() {
    return this._http.get(this.url+"/medical/get_hospitals",{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  getWBCancerTypeList() {
    return this._http.get(this.url+"/patient/get_wb_cancer_type_list",{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  getHospitalLocations(hospitalId: number): Observable<any> {
      return this._http.get<HospitalLocation>(this.url+"/medical/" + hospitalId + "/get_hospital_locations",{
        headers:{
          'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
        }
      });
  }

  getHospitalLocation(hospitalLocationId: number): any {
    return this._http.get(this.url+"/medical/" + hospitalLocationId + "/get_hospital_location",{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  getLabTestPackages(){
    return this._http.get(this.url+'/lab/test_packages',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  getCancerSites() {
    return this._http.get(this.url+"/medical/cancer_sites",{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  // locations
  getCancerTypes(body: ReqId){
    return this._http.post(this.url+"/medical/cancer_types", body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  // morphologyTypes
  getCancerSubTypes(body: ReqId) {
    return this._http.post(this.url+"/medical/cancer_sub_types", body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  getLateralities() {
    return this._http.get(this.url+"/laterality/getLaterality",{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  uploadFile(body: any){
    return this._http.post(this.url+'/file/add_patient_document',body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  updatePatientDocumentStatus(body: any){
    return this._http.post(this.url+'/file/update_document_status',body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }

  getDocuments(patientId: number){
    return this._http.get(this.url+'/file/get_documents/' + patientId,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }
  getDashboardDetails() {
    return this._http.get(this.url+"/dashboard/details",{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }


  public downloadRecord(url:any): any {
    return this._http.get(url, {responseType: 'arraybuffer'},)
  }

  startAnalysis(id:number){
    return this._http.get(this.url+`/analysis/startAnalysis/${id}`,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }
  getAnalysisStatus(id:number){
    return this._http.get(this.url+`/analysis/analysis-status/${id}`,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }
  getAccessibleEmailIdsList(){
    return this._http.get(this.url+'/internal/email_list',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    }
    );
  }
  updateDnaAndSomaticTypeDetailsFor4bcPatients(body:any,id:number){
    return this._http.post(this.url+'/internal/update_dna_and_sample_details/'+id,body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('token')||'{}')
      }
    });
  }
}
