import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportVariant } from 'src/app/_interfaces/excel';
import { PaginationReqInterface } from 'src/app/_interfaces/lab-model';
import { DnaAndRnaDetailsReq, ImmunoDetails, SampleIdData } from 'src/app/_interfaces/reports';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(
    private httpClient: HttpClient
  ) { }



  getGeneByName(geneName: string){
    return this.httpClient.get(environment.wbApiUrl+'/admin/' + geneName + '/get_gene',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  setTailsPriorityOrder(selectedTrailsId: number, priorityOrderNumber: number) {
    return this.httpClient.post(environment.wbApiUrl+'/report/' + selectedTrailsId + '/trail_priority_order', priorityOrderNumber,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getReport(reportId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/report/' + reportId + '/get_report',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addPMIds(reportId: number, pmIds: string) {
    let requestBody:any={
      pmid:pmIds
    }
    return this.httpClient.post(environment.wbApiUrl+'/report/' + reportId + '/add_pmIds', requestBody,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addReportType(reportId: number, reportType: string) {
    return this.httpClient.get(environment.wbApiUrl+'/report/' + reportId + '/add_report_type/' + reportType,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
  addClinicalSummary(reportId: number, clinicalSummary: string) {
    return this.httpClient.post(environment.wbApiUrl+'/report/' + reportId + '/add_clinical_summary', clinicalSummary,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addAdditionalNotes(reportId: number, addNotes: string) {
    return this.httpClient.post(environment.wbApiUrl+'/report/' + reportId + '/add_additional_notes', addNotes,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }


  getReportVariantById(reportVariantId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/report/' + reportVariantId + '/get_report_variant',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  reportVariantDeSelectAllTrails(reportVariantId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/report/' + reportVariantId + '/report_variant_deselect_all',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addDisclaimerNote(reportId: number, disclaimerNote: string) {
    return this.httpClient.post(environment.wbApiUrl+'/report/' + reportId + '/add_disclaimer_note', disclaimerNote,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  first72ReportPdfResponse(reportId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/report/' + reportId + '/first72_pdf_response',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addVariantDepthDetails(variantId: number, body: any) {
    return this.httpClient.post(environment.wbApiUrl+'/report/' + variantId + '/add_depth_details', body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addFirstCancerType(reportId: number, cancerTypeId: number) {
    return this.httpClient.get(environment.wbApiUrl + '/report/' + reportId + '/add_cancer_type/' + cancerTypeId,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
}
