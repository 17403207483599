import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-first72-solid',
  templateUrl: './first72-solid.component.html',
  styleUrls: ['./first72-solid.component.scss']
})
export class First72SolidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  downloadSpinner = false;

}
