
<h6 class="title clr_text2">CNA</h6>
<!-- <div class="switch_panel pt-2" *ngIf="spinDiv">
  <div class="switch_main ml-auto mr-5">
    <span class="switch_off">Show All</span>
    <span class="switch_on">Selected Rows</span>
    <label class="switch">
      <input type="checkbox" (click)="switchToggle($event)">
      <span class="slider round"></span>
    </label>
  </div>
</div> -->
<div class="slected_rows ">
  <div class="toggle_main" *ngIf="spinDiv">
    <label class="switch">
      <span class="left">Show all</span>
      <span class="right">Selected Rows</span>
      <input type="checkbox" (click)="switchToggle($event)">
      <span class="slider round"></span>
    </label>
  </div>
</div>
<label for="filter">Select an option or enter a search term:</label>
<div class=" row search_group">

  <div class="col-2 d-flex justify-content-end" >
      <select class="form-control" (change)="filterSearchKey($event)">
        <option selected disabled value="NA"> Select an option</option>
        <option value="gain">Gain</option>
    <option value="loss">Loss</option>
      </select>
  </div>
  <div class="col-2 input-container">
    <label for="input-box" style="padding: 4px!important; background-color:rgb(209, 204, 204);font-weight:bold; color: black;">≥</label>
    <input type="number" id="input-box" (keyup)="filterSearchNumber($event)" style="width: 100px;">
  </div>
</div>
<nav class="accordion arrows mt-2">
  <div class="panel_box thead d-flex rounded bg-light_cyan rounded mb-3">
    <div class="item"><span>Gene </span></div>
    <div class="item"><span>Sample </span></div>
    <div class="item"><span>Actions </span></div>
    <div class="item" *ngIf="switchToggleButton"><span>Priority Order</span></div>
  </div>
  <div *ngIf="cnaListSpinner" class="w-100 spinner_position">
    <div class="spinner-border  m-auto d-block" role="status">
      <span></span>
    </div>
  </div>
  <div *ngIf="!cnaListSpinner">

  <section class="box" *ngFor="let cna of cnaVariants; let i = index">
    <label class="box-title">
      <div class="panel_box d-flex">
        <div class="item"><span><i>{{cna.gene}}</i></span></div>
        <div class="item"><span><p>{{cna.sample1}}</p><p>{{cna.sample2}}</p> </span></div>

        <div class="item">
          <ul class="actions_list pl-0 d-flex">
            <li>
              <a (click)="seletedRow(cna.selectedForReport, cna)"
              *ngIf="cna.selectedForReport==false">
              <i class="zmdi zmdi-bookmark-outline"></i></a>
              <a (click)="seletedRow(cna.selectedForReport, cna)"
              *ngIf="cna.selectedForReport==true">
              <i style="color: #2292cf;" class="zmdi zmdi-bookmark"></i></a>

            </li>
            <li *ngIf="cna.selectedForReport==true">
              <a (click)="seletedStarRow(cna.selectedStarForReport, cna)"
              *ngIf="cna.selectedStarForReport==false">
              <i class="zmdi zmdi-star-outline"></i></a>
              <a (click)="seletedStarRow(cna.selectedStarForReport, cna)"
              *ngIf="cna.selectedStarForReport==true">
              <i style="color: #2292cf;" class="zmdi zmdi-star"></i></a>

            </li>
            <li>
              <a [routerLink]="['/wb',reportId,'cna',cna.id]" target="_blank">View<br/>Details</a>
            </li>


          </ul>
        </div>
        <div class="item " *ngIf="switchToggleButton">
					<input type="number"  class="form-control" name="priorityOrderNumber_{{i}}" [(ngModel)]="cna.priorityOrder"
					(change)="priorityOrder(cna.priorityOrder, cna.id)">
				</div>
      </div>

    </label>

  </section>
</div>
</nav>


