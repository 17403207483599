<div class="patient_info bg-shade p-4 rounded">
  <div class="item">
    <ul class="p-0">
      <li><span>Sample ID : </span> <span> {{ecrfPatientId || "NA"}} </span></li>
      <li><span>Input file name R1 : </span> <span> {{dnaR1Documents[0]?.title || "NA"}} </span></li>
      <li><span>Input file name R2 : </span> <span> {{dnaR2Documents[0]?.title || "NA"}} </span></li>
      <li><span>Age/DOB : </span> <span> {{patient?.dateOfBirth}} ({{patient?.age}}years) </span></li>
    </ul>
  </div>
  <div class="item">
    <ul class="p-0">
      <li><span>Test name : </span> <span> {{patient?.testPackageName || "NA"}} </span></li>
      <li><span>Gender : </span> <span> {{patient?.gender || "NA"}}</span></li>
      <li><span>Capturing Kit : </span> <span> {{patient?.dnaKitUsed || "NA"}} </span></li>
      <li><span>Registration Date : </span> <span> {{sampleInfo?.sampleRegistrationOn || "NA"}} </span></li>
    </ul>
  </div>
  <div class="item">
    <ul class="p-0">
      <li><span>Cancer type : </span> <span> {{patient?.medical?.wbCancerType || "NA"}} </span></li>
      <li><span> Sample source : </span> <span> {{sampleInfo?.somaticType || "NA"}} </span> </li>
      <li><span>Sample date : </span> <span> {{sampleInfo?.sampleRegistrationOn || "NA"}} </span></li>
    </ul>
  </div>
</div>
<!-- info -->

<div class="bio_details mt-5 ">

  <div *ngIf="this.patient.testPackageName !='TarGT First' && this.patient.testPackageName !='Target First'">
    <p style="font-size: 15px!important; text-align: center;padding: 15px;background-color: #2292cf;color:white;">IT IS {{patient?.testPackageName | uppercase}}, IT NEEDS TO BE TARGT FIRST TO START ANALYSIS !!</p>
  </div>
  <div *ngIf="(this.patient.testPackageName =='TarGT First' || this.patient.testPackageName =='Target First') && dnaR1Documents[0]?.title==null || dnaR2Documents[0]?.title==null">
    <p style="font-size: 15px!important; text-align: center;padding: 15px;background-color: #2292cf;color:white;">
      DNA R1 or DNA R2 FILE IS NOT UPLOADED !!</p>
  </div>
  <!-- <div class="btn_sec mt-5 text-right"> -->
  <!-- <button type="button" class="btn btn-secondary cancel_btn">Quit</button>
      <button type="button" class="btn btn_cyan ml-2">Run</button> -->
  <div class="col-5  text-right">
    <div *ngIf="( showStatAnalysis && isTargetFirst)" class="d-grid gap-2 d-md-flex justify-content-md-start mt-">
      <button type="button" class="btn btn-primary " [disabled]="isStartAnalysisDisabled" (click)="confirm()">Start
        Analysis</button>
    </div>
  </div>
</div>
<!-- </div> -->
<!-- <div class="bio_details mt-5"> -->
<!-- <div class="row"> -->
<div *ngIf="(this.patient.testPackageName =='TarGT First' || this.patient.testPackageName =='Target First') && (dnaR1Documents[0]?.title!=null && dnaR2Documents[0]?.title!=null)">
  <ul id="progress-bar" class="progressbar" *ngIf="this.patient.dnaKitUsed=='FEV2F2both'">
    <li *ngFor="let status of analysisStatusFEV2F2both">
      <span class="be " [ngClass]="status.class"><i _ngcontent-ndc-c146="" class="zmdi"
          [ngClass]="status.icon"></i></span> <span class="content" style="color: black;">{{status.value}}</span>
    </li>
  </ul>
  <ul id="progress-bar" class="progressbar" *ngIf="this.patient.dnaKitUsed=='CDS'">
    <li *ngFor="let status of analysisStatusCDS">
      <span class="be " [ngClass]="status.class"><i _ngcontent-ndc-c146="" class="zmdi"
          [ngClass]="status.icon"></i></span> <span style="color: black;" class="content">{{status.value}}</span>
    </li>
  </ul>
  <ul id="progress-bar" class="progressbar" *ngIf="this.patient.dnaKitUsed=='FUS'">
    <li *ngFor="let status of analysisStatusFUS">
      <span class="be " [ngClass]="status.class"><i _ngcontent-ndc-c146="" class="zmdi"
          [ngClass]="status.icon"></i></span> <span style="color: black;" class="content">{{status.value}}</span>
    </li>
  </ul>
  <!-- </div> -->
  <!-- </div> -->

</div>


