import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/_interfaces/auth.model';
import { CancerReq, CancerSites, CancerType, FamilyReqDto, Hospital, HospitalLocation, LabTest, LabTestPackages, LabTestsTaken, Laterality, Medical, MorphologyType, Patient, ReqId, SampleInfoReq, TMHReportInfoReq, WBCancerType, reportsEmailDto } from 'src/app/_interfaces/lab-model';
import { LabService } from 'src/app/_services/oncobench-service/lab.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})
export class AddPatientComponent implements OnInit {
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;
  //
  statusMsg!: string;
  appUser = {} as User;
  isTMHUser = false;
  isLifeCell:boolean=false;
  isHCG:boolean=false;
  isKokilaben:boolean=false;
  patient = {} as Patient;
  medicalInfo = {} as Medical;
  hospitals: Hospital[] = [];
  hospitalLocations: HospitalLocation[] = [];
  hospitalLocationDetails = {} as HospitalLocation;
  sampleInfo = {} as SampleInfoReq;
  tmhReportInfoReq = {} as TMHReportInfoReq;
  addFamilyHistory!: {
    addFamilyHistorys: FamilyReqDto[];
  };
  familyReqDtoList: FamilyReqDto[] = [];
  reqId = {} as ReqId;
  isAge = false;
  patientId!: number;
  afterSelectPackage = false;
  isDna = false;
  isRna = false;
  selectedTest = false;
  testTaken!: Date;
  packageName!: string;
  labTestPackages: LabTestPackages[] = [];
  isSaving = false;
  cancerIdentifier!: string;
  biomarker!: string;
  hormonalChanges!:string;
  addCancer!: {
    addCancers: CancerReq[];
  };
  isReadOnly:boolean=false
  cancerSites: CancerSites[] = [];
  cancerTypes: CancerType[] = [];
  cancerTypeList: WBCancerType[] = [];
  morphologies: MorphologyType[] = [];
  lateralities: Laterality[] = [];

  constructor(
    private labService: LabService,
    private router: Router,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  showError() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }

  stepOne() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
  }

  stepTwo() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
  }

  stepThree() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
    this.step4 = false;
    this.step5 = false;

    this.patient.medicalReqDto = this.medicalInfo;

  }

  stepFour() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;

    if(this.isTMHUser) {
      this.step4 = true;
      this.step5 = false;

    } else {
      this.step4 = true;
      this.step5 = false;
    }



    this.patient.testPackageName = this.packageName;
    this.patient.sampleInfoReq = this.sampleInfo;
  }

  stepFive() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;

    if(!this.isTMHUser ) {
      this.step4 = false;
      this.step5 = true;
    } else {
      this.step4 = false;
      this.step5 = true;
      this.patient.tmhReportDTO = this.tmhReportInfoReq;
    }
    if(this.isLifeCell){
      this.patient.familyHistoryDTO=this.addFamilyHistory.addFamilyHistorys
    }


    this.patient.testPackageName = this.packageName;
    this.patient.sampleInfoReq = this.sampleInfo;
  }
  reportsEmailList:reportsEmailDto[] = [];
  reportsEmail:string[]=[];
  isAccessAllowedButtom = false;

  ngOnInit(): void {

    this.appUser = JSON.parse(localStorage.getItem("user") || '{}');
    this.getAccessibleEmailIdsList();
    console.log("this.appUser",this.appUser,this.router.url);
    this.getCancerTypesList();
    if(this.router.url.includes('view_patient')){
      this.isReadOnly=true
    }else{
      this.isReadOnly=false
      console.log("not read")
    }
    if(this.appUser["orgShortName"] == "TMH") {
      this.isTMHUser = true;
    }
    else if(this.appUser["orgShortName"]?.toLowerCase() == "lifecell") {
      this.isLifeCell = true;
    }
    else if(this.appUser["orgShortName"] == "HCG") {
      this.isHCG = true;
    }
    else if(this.appUser["orgShortName"] == "Kokilaben") {
      this.isKokilaben = true;
    }

    this.patientId = this.routerAct.snapshot.params["id"];
    if (this.patientId) {
      this.getPatient();
    }

    this.getHospitals();
    this.getLabTestPackages();


    this.getCancerSites();
    this.getLateralites();

    this.addCancer = {
      addCancers: [],
    };

    this.addCancer.addCancers.push({
      cancerSite: 0,
      cancerLocation: 0,
      morphologyType: 0,
      laterality: 0,
    });
    this.addFamilyHistory = {
      addFamilyHistorys: [],
    };
    this.medicalInfo.cancerDetails = this.addCancer.addCancers;
    this.patient.medicalReqDto = this.medicalInfo;
  }

  getAccessibleEmailIdsList(){
    this.labService.getAccessibleEmailIdsList().subscribe((res: any) => {
      console.log("=>",res['payLoad']);
      this.reportsEmailList=res['payLoad'];
      for(let i=0;i<this.reportsEmailList.length;i++){
        this.reportsEmail.push(this.reportsEmailList[i]?.emailId);
      }
      if(this.reportsEmail.includes(this.appUser?.email)){
        this.isAccessAllowedButtom = true;
      }
    });
    }
  addPatient() {
    this.isSaving = true;


     if (this.cancerIdentifier == null || this.cancerIdentifier == undefined) {
      this.statusMsg = "select cancer Indentifier";
      this.isSaving = false;
      return this.showError();
    }else if (this.addCancer.addCancers[0].cancerSite == null || this.addCancer.addCancers[0].cancerSite == 0) {
      this.statusMsg = "select cancer site";
      this.isSaving = false;
      return this.showError();
    }
    else if (this.isAccessAllowedButtom  && this.packageName=='TarGT First' && (this.medicalInfo.wbCancerType == null || this.medicalInfo.wbCancerType == undefined)) {
      this.statusMsg = "select cancer Type";
      this.isSaving = false;
      return this.showError();
    } else {

      this.patient.medicalReqDto.cancerIdentifier = this.cancerIdentifier;
      this.patient.medicalReqDto.biomarker = this.biomarker;
      this.patient.medicalReqDto.hormonalChanges = this.hormonalChanges;
      this.patient.isAgeChecked = this.isAge;
      console.log("addPatient", this.patient);
      if(this.isKokilaben==true || this.isHCG==true){
        this.patient.tmhReportDTO = this.tmhReportInfoReq;
      }

      this.labService.addPatient(this.patient).subscribe(
        (response: any) => {
          console.log(response);
          this.statusMsg = response["message"];
          this.showSuccess();
          this.isSaving = false;
          this.router.navigate(["upload/", response["payLoad"]["id"]]);
        },
        (error:any) => {
          this.isSaving = false;
          console.log(error);
          this.statusMsg = error["message"];
          this.showError();
        }
      );

    }
  }

  updatePatient() {
    this.isSaving = true;
    this.patient.pid = this.patientId;

    this.patient.referralDoctorEmail = "";
    this.patient.referralDoctorName = "";
    console.log(this.patient);

    if (this.addCancer.addCancers[0].cancerSite == null || this.addCancer.addCancers[0].cancerSite == 0) {
      this.statusMsg = "select cancer site";
      this.isSaving = false;
      return this.showError();
    } else if (this.cancerIdentifier == null || this.cancerIdentifier == undefined) {
      this.statusMsg = "select cancer Indentifier";
      this.isSaving = false;
      return this.showError();
    } else {
      this.medicalInfo.cancerDetails = this.addCancer.addCancers;
      this.patient.medicalReqDto=this.medicalInfo
      this.patient.medicalReqDto.cancerIdentifier = this.cancerIdentifier;
      this.patient.medicalReqDto.biomarker = this.biomarker;
      this.patient.medicalReqDto.hormonalChanges = this.hormonalChanges;
      if(this.isKokilaben==true || this.isHCG){
        this.patient.tmhReportDTO = this.tmhReportInfoReq;
      }
      console.log("this.addCancer.addCancers=>",this.addCancer.addCancers)

      this.labService.updatePatient(this.patient).subscribe(
        (response: any) => {
          console.log(response);
          this.statusMsg = response["message"];
          this.showSuccess();
          this.isSaving = false;
          this.router.navigate(["/patients"]);
        },
        (error: any) => {
          this.isSaving = false;
          this.statusMsg = error["message"];
          this.showError();
        }
      );
    }
  }

isOtherHospital=false
  getPatient() {
    this.reqId.id = this.patientId;
    this.labService.getPatient(this.reqId).subscribe((response: any) => {
      this.patient = response["payLoad"];
      this.assignPatientDetails(response);
    });
  }

  assignPatientDetails(response: any) {
    console.log("get patient", response);
    this.isAge=this.patient.isAgeChecked;

    this.packageName = response["payLoad"]["testPackageName"];

    if(this.packageName=="HRR Germline"){
      this.isHrrGermline=true
    }else{
      this.isHrrGermline=false
    }

    if (response["bio"] != null) {
      this.sampleInfo = response["bio"];
    }

    //TMH Report Info
    if(response["tmhReportInfo"] != null) {
      this.tmhReportInfoReq = response["tmhReportInfo"];
    }

    if (response["payLoad"]["medical"] != null) {
      this.medicalInfo.doctorName =
        response["payLoad"]["medical"]["doctorName"];
        this.medicalInfo.hospitalName =
        response["payLoad"]["medical"]["hospitalName"];
      this.medicalInfo.doctorEmailId =
        response["payLoad"]["medical"]["doctorEmailId"];
      this.medicalInfo.doctorPhoneNumber =
        response["payLoad"]["medical"]["doctorPhoneNumber"];
      this.cancerIdentifier =
        response["payLoad"]["medical"]["cancerIdentifier"];
        this.medicalInfo.cancerIdentifier = response["payLoad"]["medical"]["cancerIdentifier"];
      this.biomarker = response["payLoad"]["medical"]["biomarker"];
      this.hormonalChanges = response["payLoad"]["medical"]["hormonalChanges"];
      if (response["payLoad"]["medical"]["hospital"] != null) {
        this.medicalInfo.hospitalId =
          response["payLoad"]["medical"]["hospital"]["id"];
          const result = this.hospitals.find((element:any) =>
          element.id === this.medicalInfo.hospitalId );
          console.log("result",result)
          if(result?.name=="Others"){
            this.isOtherHospital=true
          }else{
            this.isOtherHospital=false
          }
        this.getHospitalLocations(this.medicalInfo.hospitalId);
      }

      if (response["payLoad"]["medical"]["hospitalLocation"] != null) {
        this.medicalInfo.hospitalLocationId =
          response["payLoad"]["medical"]["hospitalLocation"]["id"];
        this.medicalInfo.hospitalPhoneNumber =
          response["payLoad"]["medical"]["hospitalLocation"]["contactNumber"];
      }
      this.medicalInfo.wbCancerType =
          response["payLoad"]["medical"]["wbCancerType"];

      if (response["payLoad"]["medical"]["cancerSiteGroup"] != null) {
        this.addCancer.addCancers = response["payLoad"]["medical"]["cancerSiteGroup"];
        this.addCancer.addCancers[0].laterality=response["payLoad"]["medical"]["cancerSiteGroup"][0].lateralitiy;
        this.getCancerTypes(this.addCancer.addCancers[0].cancerSite);
        this.getCancerSubTypes(this.addCancer.addCancers[0].cancerLocation)
      }
    }

    if(response['payLoad']['familyHistory'] && response['payLoad']['familyHistory'].length>0){
      this.addFamilyHistory.addFamilyHistorys=response['payLoad']['familyHistory']
      this.familyReqDtoList=response['payLoad']['familyHistory']
    }
  }
isHrrGermline=false
  selectTest(testName:any){
    if(testName=="HRR Germline")
    {
      this.isHrrGermline=true
    }else{
      this.isHrrGermline=false
    }
  }

  addFamily(): void {
    if(this.addFamilyHistory.addFamilyHistorys.length==0){
      for(let i=1;i<4;i++){
        this.addFamilyHistory.addFamilyHistorys.push({
          id:0,
          familyType: "",
          relationship:"",
          cancerType:"",
          diagnosisAge: ""
        });
      }
    }else{
      this.addFamilyHistory.addFamilyHistorys.push({
        id:0,
        familyType: "",
        relationship:"",
        cancerType:"",
        diagnosisAge: ""

      });

    }

    this.familyReqDtoList = this.addFamilyHistory.addFamilyHistorys;
    console.log(this.familyReqDtoList);
  }

  removeFamily(i: number,data:any) {
    this.addFamilyHistory.addFamilyHistorys.splice(i, 1);
    this.familyReqDtoList = this.addFamilyHistory.addFamilyHistorys;
    console.log(this.familyReqDtoList);
  }


  clickedOnAge() {
    this.isAge = !this.isAge;
  }

  CalculateAge() {
    if (this.patient.dateOfBirth) {
      const convertAge = new Date(this.patient.dateOfBirth);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.patient.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    }
  }

  CalculateDOB() {
    if (this.patient.age) {
      var ageYears = this.patient.age;
      var ageMonths = 5;
      var ageDays = 18;
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth();
      var day = today.getDate();
      var dob = new Date(year - ageYears, month - ageMonths, day - ageDays);
      this.patient.dateOfBirth = dob;
    }
  }

  getHospitals() {
    this.labService.getHospitals().subscribe((response: any) => {
      this.hospitals = response["payLoad"];
    });
  }

  getHospitalLocations(hospitalId: number) {
    console.log(hospitalId)
    const result = this.hospitals.find((element:any) =>
          element.id == hospitalId );
          console.log("result",result)
          if(result?.name=="Others"){
            this.isOtherHospital=true
          }else{
            this.isOtherHospital=false
          }
    this.labService.getHospitalLocations(hospitalId).subscribe((response: any) => {
      this.hospitalLocations = response["payLoad"];
    });
  }

  getHospitalLocation(hospitalLocationId: number) {
    this.labService
      .getHospitalLocation(hospitalLocationId)
      .subscribe((response: any) => {
        this.hospitalLocationDetails = response["payLoad"];
        this.medicalInfo.hospitalPhoneNumber =
          this.hospitalLocationDetails.contactNumber;
      });
  }

  getLabTestPackages() {
    this.labService.getLabTestPackages().subscribe((response: any) => {
      this.labTestPackages = response["payLoad"];
    });
  }

  addCancerDetails() {
    this.addCancer.addCancers.push({
      cancerSite: 0,
      cancerLocation: 0,
      morphologyType: 0,
      laterality: 0,
    });
    this.medicalInfo.cancerDetails = this.addCancer.addCancers;
    this.patient.medicalReqDto = this.medicalInfo;
  }

  getCancerSites() {
    this.labService.getCancerSites().subscribe((response: any) => {
      this.cancerSites = response["payLoad"];
    });
  }

  getCancerTypesList() {
    this.labService.getWBCancerTypeList().subscribe((data: any) => {
      console.log(data);
      this.cancerTypeList = data["payLoad"];
    });
  }

  getCancerTypes(id: number) {
    this.reqId.id = id;
    this.labService.getCancerTypes(this.reqId).subscribe((data: any) => {
      console.log(data);
      this.cancerTypes = data["payLoad"];
    });
  }

  getCancerSubTypes(id: number) {
    this.reqId.id = id;
    this.labService.getCancerSubTypes(this.reqId).subscribe((data: any) => {
      console.log(data);
      this.morphologies = data["payLoad"];
    });
  }

  getLateralites() {
    this.labService.getLateralities().subscribe((response: any) => {
      this.lateralities = response["payLoad"];
    });
  }

}
