<div class="cust_align mx-0 h-100vh main_div">
  <div class="title_section item_1">
    <h3>Oncobench</h3>
  </div>
  <div class=" left_section">
    <div id="demo" class="carousel slide " data-ride="carousel">
      <!-- The slideshow -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/login/Carousel_1.png" alt="Doctor-image" class="img-fluid w-25 m-auto ">
          <div class="slider-text py-3">
            <p class="text-center">In view of the increasing number of cancer cases and limited time available with
              the oncologists, it is crusial that a clear overview of the patients data is available at all times to
              ensure fact based decision making.</p>
          </div>
        </div>
        <div class="carousel-item ">
          <img src="../../../assets/login/Carousel_2.png" alt="Doctor-image" class="img-fluid w-25 m-auto">
          <div class="slider-text py-3">
            <p class="text-center">Currently, the data of all the patients is available is non-standard hard copy
              reports, which are difficult for the patients to manage and share with their consulting oncologists .
            </p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../../assets/login/Carousel_3.png" alt="Doctor-image" class="img-fluid w-25 m-auto">
          <div class="slider-text py-3">
            <p class="text-center">4basecare has created a secure nad structured tool for capturing oncology-specific
              data from the patients and summarizing it in a seamless format so that optimal treatment decision can be
              taken. </p>
          </div>
        </div>
        <!--  -->

      </div>
      <!-- Indicators -->
      <ul class="carousel-indicators slider-indicators">
        <li data-target="#demo" data-slide-to="0" class="active"></li>
        <li data-target="#demo" data-slide-to="1"></li>
        <li data-target="#demo" data-slide-to="2"></li>
      </ul>
<!--
      <div class="slider_footer align-items-end">
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet labore corrupt. </p>
        <button type="button" class="btn btn-primary cust_btn">Read more</button>
      </div> -->

    </div>
    <!--  -->


  </div>
  <div class="right_section">

      <div class="login_panel item_2 p-5 bg-white rounded">
        <div class="logo_section">
          <img src="../../../assets/login/logo.png" class="w-25">
        </div>
       <div class="welcome_section mb-4 pt-4">
         <span>Good Morning!</span>
        <h4>Login to Oncobench</h4>
       </div>
        <form (ngSubmit)="submitLogin()" class=" pb-4" >
          <div class="form-group">
            <label for="email">Email </label>
            <input type="email" class="form-control" placeholder="Enter email" [(ngModel)]="model.email"  name="email" required />
          </div>
          <div class="form-group">
            <label for="pwd">Password:</label>
            <input type="password" class="form-control" placeholder="Enter password" [(ngModel)]="model.password" name="password" required />
            <div class="w-100 mt-2"><a href="#" class="text-right d-block f-11 f-password">Forgot Password?</a></div>
          </div>

          <button type="submit" class="btn btn-primary cust_btn my-3 px-5">Login</button>

        </form>

        <div class="prod_by">
          <p class="f-10">A Product from 4baseCare Genomics Pvt. Ltd.</p>
        </div>
      </div>

   </div>
  </div>
