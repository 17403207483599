import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Auth } from '../_interfaces/auth';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) {}

    login(body: Auth) {
      return this.httpClient.post(environment.apiUrl+'/user/login', body);
    }

    wbLogin(body: Auth) {
      return this.httpClient.post(environment.wbApiUrl+'/user/login', body);
    }
}
