import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkbenchRoutingModule } from './workbench-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { WBTokenInterceptor } from '../_services/workbench-service/wb-token.interceptor';
import { ViewSnvVariantComponent } from './view-snv-variant/view-snv-variant.component';
import { ViewFusionVariantComponent } from './view-fusion-variant/view-fusion-variant.component';
import { ViewCnaVariantComponent } from './view-cna-variant/view-cna-variant.component';
import { AdditionalDetailsComponent } from './additional-details/additional-details.component';
import { WorkbenchComponent } from './workbench.component';
import { VariantDashboardComponent } from './variant-dashboard/variant-dashboard.component';
import { HeaderComponent } from './components/header/header.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SnvVariantComponent } from './snv-variant/snv-variant.component';
import { CnaVariantComponent } from './cna-variant/cna-variant.component';
import { FusionVariantComponent } from './fusion-variant/fusion-variant.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ClinicalTrailComponent } from './clinical-trail/clinical-trail.component';
import { HotspotVariantComponent } from './hotspot-variant/hotspot-variant.component';
import { ViewHotspotVariantComponent } from './view-hotspot-variant/view-hotspot-variant.component';


@NgModule({
  declarations: [
    ViewSnvVariantComponent,
    ViewFusionVariantComponent,
    ViewCnaVariantComponent,
    AdditionalDetailsComponent,
    WorkbenchComponent,
    VariantDashboardComponent,
    HeaderComponent,
    SideNavComponent,
    SnvVariantComponent,
    CnaVariantComponent,
    FusionVariantComponent,
    ClinicalTrailComponent,
    HotspotVariantComponent,
    ViewHotspotVariantComponent,

  ],
  imports: [
    CommonModule,
    WorkbenchRoutingModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxSpinnerModule,
    CKEditorModule
  ]
})
export class WorkbenchModule { }
