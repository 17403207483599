import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/_services/auth/auth.guard';
import { Is4BaseCareComponent } from './4base-care/is4-base-care.component';
import { First72SolidComponent } from './first72-solid.component';
import { TmhComponent } from './tmh/tmh.component';
import { HcgComponent } from './hcg/hcg.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: First72SolidComponent,
    children: [
      {
        path: 'first72_view_report/4bc/:id/:temp',
        canActivate: [AuthGuard],
        component: Is4BaseCareComponent,
      },
      {
        path: 'first72_view_report/hcg/:id/:temp',
        canActivate: [AuthGuard],
        component: HcgComponent,
      },
      {
        path: 'first72_view_report/tmh/:id/:temp',
        canActivate: [AuthGuard],
        component: TmhComponent,
      },
    ]
  }
    ]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class First72SolidRoutingModule {

}
