
<h6 class="clr_text2">Hotspot</h6>
<div class="slected_rows ">
  <!-- <div class="switch_panel pt-2" *ngIf="showDiv">
    <div class="switch_main ml-auto mr-5">
      <span class="switch_off">Show All</span>
      <span class="switch_on ml-1">Selected Rows</span>
      <label class="switch ml-1">
        <input type="checkbox" (click)="switchToggle($event)" />
        <span class="slider round"></span>
      </label>
    </div>
  </div> -->
  <div class="toggle_main" *ngIf="showDiv">
    <label class="switch">
      <span class="left">Show all</span>
      <span class="right">Selected Rows</span>
      <input type="checkbox" (click)="switchToggle($event)">
      <span class="slider round"></span>
    </label>
  </div>
</div>
<nav class="accordion arrows mt-2">
  <div class="panel_box thead d-flex rounded bg-light_cyan rounded ">
    <div class="item"><span>Gene </span></div>
    <div class="item"><span>Chrom </span></div>
    <div class="item"><span>Pos</span> </div>
    <div class="item"><span>Ref</span> </div>
    <!-- <div class="item"><span>Metrics </span></div> -->
    <div class="item"><span>Alt </span></div>
    <div class="item"><span>Actions </span></div>
    <div *ngIf="switchToggleButton" class="item pri_order"   ><span>Priority Order</span></div>


  </div>
  <div *ngIf="variantsListSpinner" class="w-100 spinner_position" style="padding-top: 10px; align-items: center;">
    <div class="spinner-border  m-auto d-block" role="status">
      <span></span>
    </div>
  </div>
  <div *ngIf="!variantsListSpinner">
  <div  *ngFor="let item of hotspotVariant ; let i=index">
  <!-- <input type="radio" name="accordion" id="cb{i}" /> -->
  <section class="box">
    <label class="box-title" for="cb{i}">
      <div class="panel_box d-flex">
        <div class="item"><span><i>{{ item.geneName }}</i></span></div>
        <div class="item"><span><p style="font-size: 12px!important;">{{item?.chrom}}</p> </span></div>
        <div class="item"><span><p>{{item.pos}}</p> </span> </div>
        <div class="item"><span><p>{{item?.ref}}</p></span> </div>
        <div class="item"><span><p>{{item.alt}}</p>
         </span></div>
        <div class="item">
          <ul class="actions_list pl-0 d-flex align-items-center">
            <li>
              <a (click)="hotspotVariantStatus(item.selectedForReport, item)"
              *ngIf="item.selectedForReport==false">
              <i class="zmdi zmdi-bookmark-outline"></i></a>
              <a (click)="hotspotVariantStatus(item.selectedForReport, item)"
              *ngIf="item.selectedForReport==true">
              <i style="color: #2292cf;" class="zmdi zmdi-bookmark"></i></a>

            </li>
            <li *ngIf="item.selectedForReport==true">
              <a (click)="hotspotVariantStarStatus(item.selectedStarForReport, item)"
              *ngIf="item.selectedStarForReport==false">
              <i class="zmdi zmdi-star-outline"></i></a>
              <a (click)="hotspotVariantStarStatus(item.selectedStarForReport, item)"
              *ngIf="item.selectedStarForReport==true">
              <i style="color: #2292cf;" class="zmdi zmdi-star"></i></a>

            </li>
            <li *ngIf="item.selectedForReport==false">
              <a   *ngIf="report.oncobenchAnalysisStatus == 'COMPLETED'" href="{{igvUrl}}/hotspotIGV/{{item.id}}" target="_blank">View <span class="igv">IGV</span></a>
              <a  [routerLink]="['/wb',reportId,'hotspot',item.id]"  target="_blank">View Details</a>
            </li>
            <!--  -->
            <li *ngIf="item.selectedForReport==true" class="px-0 act_view-det">
              <a   *ngIf="report.oncobenchAnalysisStatus == 'COMPLETED'" href="{{igvUrl}}/hotspotIGV/{{item.id}}" target="_blank">View <span class="igv">IGV</span></a>
              <a  [routerLink]="['/wb',reportId,'hotspot',item.id]"  target="_blank">View Details</a>
            </li>
          </ul>

        </div>

        <div  class="item pri_order" *ngIf="switchToggleButton" >
          <input
          type="number"
          class="form-control"
          name="priorityOrderNumber_{{ i }}"
          [(ngModel)]="item.priorityOrder"
          (change)="setHotspotVariantPriorityOrder(item.priorityOrder, item.id)"/>
        </div>
      </div>

    </label>

    <label class="box-close" for="acc-close{i}"></label>
    <!-- <input type="radio" name="accordion" id="acc-close{i}" /> -->

  </section>
  </div>
  </div>

</nav>

