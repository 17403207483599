<div class=" main_wrapper ">
  <div class="sidebar">
    <app-side-nav></app-side-nav>
  </div>
  <div class="content-wrapper">
    <div class="top_bar">
      <app-header></app-header>
    </div>
    <div class="main_content">

      <!--  -->
      <div class="clinical_trail_header row mx-0 py-2">
        <div class="col pl-0">
          <h5 class="mb-0">{{fdatitle}}</h5>
        </div>
        <div class="col-4">
          <h5 *ngIf="reportedVariantId" class="mb-0 clr_text2" >{{ecrfPatientId}}
            <span>[<i>{{reportedVariant?.refGeneX}} </i>
              <span *ngIf="reportedVariant.selectedAAChangeknownGene != null"
              style="font-size:15px">({{reportedVariant.selectedAAChangeknownGene.split(",")[1]}})</span>]</span>
          </h5>
          <h5 *ngIf="!reportedVariantId" class="mb-0 clr_text2" >{{ecrfPatientId}} <span>[{{gene}}]</span></h5>

        </div>
        <div class="col">
          <input type="checkbox" (click)="selectedTrailsList($event)" class="input_check"> <span
          class="ml-1">{{fdaShow}}</span>
        </div>
        <div *ngIf="!fusionVariantId" class="col">
          <p> Gene : <i>{{gene}}</i></p>
        </div>
        <div *ngIf="fusionVariantId" class="col">
          <div class="d-flex">
            <span> Gene : <i>{{gene}}</i></span>
          </div>
        </div>
        <div class="col pr-0">
          <div class=" text-right"> <a (click)="backReportedVariant()" class="drug_title cursor_action">
              <i class="zmdi zmdi-long-arrow-left"></i> Back to Variant Details</a></div>
        </div>
      </div>


      <div class="mx-0">
        <table class="table drug_table">
          <thead class="bg-light_cyan">
            <tr>
              <th *ngIf="isFist72"><input type="checkbox" class="custom-
            control-input" (click)="deSelectOption($event)" id="save-info" required> De-Select All</th>
              <th *ngIf="!isFist72"></th>
              <th>Star Selection</th>
              <th *ngIf="switchToggleButton">Priority Order</th>
              <th>Drugs</th>
              <th>Cancer Type</th>
              <th>Variant</th>
              <th>ASN</th>
              <th *ngIf="!isFirstReport">Action</th>
            </tr>

          </thead>
          <div *ngIf="!trailsListSpinner" class="w-100 spinner_position">
            <div class="spinner-border  m-auto d-block" role="status">
              <span></span>
            </div>
          </div>
          <tbody class="bg-white" *ngIf="
          trailsListSpinner" >


            <tr  *ngFor="let clinicalTrial of clinicalTrialResList; let i = index">
              <td>
                <div class="form-check" style="margin-top: -18px;">

                  <input class="form-check-input" type="checkbox" (click)="addSelectedTrails($event,clinicalTrial.id)"
                    [checked]="clinicalTrial.selected ">
                </div>
              </td>
              <td>
                <div class="form-check" style="margin-top: -18px;">
                  <input *ngIf="clinicalTrial.selected" class="form-check-input" type="checkbox"
                    (click)="addStarSelectedTrails($event,clinicalTrial.id)" [checked]="clinicalTrial.isStarSelected">
                </div>
              </td>
              <td *ngIf="switchToggleButton">
                <input type="number" class="form-control" name="priorityOrderNumber_{{i}}" [(ngModel)]="clinicalTrial.priorityOrder"
                  (ngModelChange)="priorityOrder(clinicalTrial.priorityOrder, clinicalTrial.id)">
              </td>
              <td>
                <div *ngFor="let drug of clinicalTrial.drugs">
                  <span
                    [ngStyle]="{'color': (isFirstReport && clinicalTrial.cancerType.name == firstCancerType) ? 'blue' : 'red'}">{{drug.name}}</span>
                </div>
              </td>
              <td>{{clinicalTrial.cancerType.name}}</td>
              <td style="word-wrap: normal;">{{clinicalTrial.pposition || "-"}}</td>
              <td *ngIf="clinicalTrial.association=='RESPONSIVE'">S</td>
              <td *ngIf="clinicalTrial.association=='RESISTANCE'">R</td>
              <td *ngIf="clinicalTrial.association=='ONGOING'">O</td>
              <td *ngIf="clinicalTrial.association==''||clinicalTrial.association==null">-</td>
              <td *ngIf="!isFirstReport">
                <a *ngIf="clinicalTrial.selected" class="delete_clr" (click)="assignClinicalTrail(clinicalTrial)"
                  data-toggle="modal" data-target="#selectCancerDetails">Select Cancer</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="modal fade" id="selectCancerDetails">
        <div class="modal-dialog clinicalTrail-model modal-dialog-centered">
          <div class="modal-content surgery-modal">

            <div class="modal-header px-4 border-bottom-0 bg-light">
              <h5 class="modal-title text-softblue">Trial Details</h5>
              <a class="text-softblue" #closebutton data-dismiss="modal">
                <i _ngcontent-esd-c8="" class="zmdi zmdi-close"></i>
              </a>
            </div>

            <div class="modal-body p-4">
              <div class="row mb-3">
                <div class="col">
                  <h4 style="color: #001a56;">Cancer Type</h4>
                  <select class="form-control" [(ngModel)]="assignedClinicalTrail.cancerTypeEnum">
                    <option [ngValue]="null" selected disabled>Select</option>
                    <option value="SAME">SAME</option>
                    <option value="OTHERS">OTHERS</option>
                  </select>
                </div>
                <div class="col">
                  <h4 style="color: #001a56;">Cancer</h4>
                  <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="cancerTypeList"
                    [(ngModel)]="assignedClinicalTrail.cancerTypes">
                  </ng-multiselect-dropdown>
                </div>
                <div class="col-2">
                  <button type="button" [disabled]="isSubmited" class="btn save_btn" style="float:right" id="closeModal"
                    (click)="editSelectedTrail()">
                    <span *ngIf="!isSubmited"> Save </span>
                    <div *ngIf="isSubmited" class="spinner-border text-dark">
                      <span class="sr-only"></span>
                    </div>
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
