<div class="header">
  <!-- <div class="title">
    <h4 class="mb-0">Patients </h4>
    <span>Patients List</span>
  </div> -->
  <div class="account ml-auto">
    <ul class="mb-0">
      <!-- <li><a href="javascript:void(0)"><i class="zmdi zmdi-help-outline"></i> </a></li>
      <li><a href="javascript:void(0)"><i class="zmdi zmdi-settings"></i>
      </a></li>-->
      <li><a data-toggle="dropdown" href="javascript:void(0)"><span class="pr-2">{{appUser.fullName}}</span><i class="account_rounded"></i></a>
        <div class="dropdown-menu">
          <a *ngIf="isReportButtom"  class="dropdown-item " href="{{workbenchUrl}}/organisation/reports/{{appUser.organisation}}/{{appUser.email}}" target="_blank"> <i  class="zmdi zmdi-account"></i> Reports</a>
          <a  class="dropdown-item "  (click)="logOut()">  <i  class="zmdi zmdi-power" ></i>Logout</a>

        </div></li>
    </ul>
  </div>
  </div>
