<div class="p-0">
  <div class="search_bar d-flex justify-content-between ">
    <form class="w-75">
      <div class="search_group">
        <i class="zmdi zmdi-search"></i>
        <input type="text" class="form-control" placeholder="Search Patient by ID, Name etc."  (keyup)="modelChange($event)">
      </div>
      </form>

      <!-- <div class="dropdown filter_button">
        <button type="button" class="btn border filter_btn   dropdown-toggle" data-toggle="dropdown">
          <i class="zmdi zmdi-filter-list"></i>
          Filter
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="javascript:void(0)">Link 1</a>
          <a class="dropdown-item" href="javascript:void(0)">Link 2</a>
        </div>
      </div> -->
      <button  *ngIf="appUser.role == 'LAB_USER' && !appUser.is4BaseCare" type="button" class=" btn_cyan-outline"  [routerLink]="['/add_patient']">
        <i class="zmdi zmdi-account-add"></i> Add Patient</button>
  </div>
  <!--patient list tbale  -->
  <div class="p-2 border rounded mt-4 patients-list_table">
    <p class="p-2 f-weight-medium clr_text2">Patient List</p>

    <table class="table patients_table mb-0">
      <thead>
        <tr >
          <th>Patient ID</th>
          <th>Patient Name</th>
          <!-- <th>Org Id</th> -->
          <th>Test Name</th>
          <th>Sample Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <div *ngIf="patientSpinner" class="w-100 spinner_position">
        <div class="spinner-border  m-auto d-block" role="status">
          <span></span>
        </div>
      </div>
      <tbody *ngIf="!patientSpinner && patientList==null || length == 0"  style="text-align: center;">
        <tr>
          <td colspan="6">
            <p style="font-size: larger!important;">There are no Registered Samples for this User !!</p>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr *ngFor="let patient of patientList">
          <td>{{patient.patientId}}	</td>
          <td><a href="javascript:void(0)">{{patient.patientName}} {{ patient.familyName }}</a></td>
          <td>{{patient.testPackageName}}</td>
          <td [ngStyle]="{'color': (patient.patientDocumentStatus === 'Report Generated') ? 'green' : (patient.patientDocumentStatus === 'Sample Registered') ? 'blue' : (patient.patientDocumentStatus === 'Report Processing') ? 'orange' : (patient.patientDocumentStatus === 'Failure Report') ? 'red' : ''}">
            {{patient.patientDocumentStatus}}</td>
            <!-- <td >
              <a *ngIf="!isReportButtom && patient.patientDocumentStatus === 'Report Generated'" (click)="downloadRecord(patient.finalReportUrl)">
              <i class="zmdi zmdi-download" style="cursor: pointer;"></i></a>
              <a *ngIf="isReportButtom && (patient.testPackageName == 'TarGT First' || this.patient.testPackageName =='Target First') && patient.patientDocumentStatus != 'Sample Registered'" href="{{workbenchUrl}}/organisation/dash-reports/{{patient.wbReportId}}/{{appUser.organisation}}/{{appUser.email}}" target="_blank">report</a>
            </td> -->

          <td>
            <div class="actions">
              <div class="dropdown ">
                <button type="button" class="btn border filter_btn   dropdown-toggle" data-toggle="dropdown">
                  <i class="zmdi zmdi-settings"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" *ngIf="patient.patientDocumentStatus === 'Sample Registered'" [routerLink]="['/upload',patient.pid]">Upload</a>
                  <a class="dropdown-item" *ngIf="patient.patientDocumentStatus != 'Sample Registered'" [routerLink]="['/upload',patient.pid]">File</a>
                  <a class="dropdown-item" [routerLink]="['/view_patient',patient.pid]" *ngIf="(patient.patientDocumentStatus == 'Sample Registered'|| patient.patientDocumentStatus =='Report Generated' || patient.patientDocumentStatus == 'Report Processing' ) && appUser.role == 'LAB_USER'">View</a>
                  <a class="dropdown-item" *ngIf="patient.patientDocumentStatus === 'Sample Registered' && appUser.role == 'LAB_USER' && !appUser.is4BaseCare" [routerLink]="['/edit_patient',patient.pid]">Edit</a>
                  <a class="dropdown-item" *ngIf="patient.patientDocumentStatus === 'Sample Registered' && !appUser.is4BaseCare" (click)="confirm(patient.pid)">Delete</a>
                </div>
              </div>

            </div>

          </td>
        </tr>


      </tbody>
    </table>
    <!-- pagination -->
    <div class="d-flex justify-content-between align-items-center mt-3 pagination_no">
      <div class="">
        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
          <p>Showing
           {{currentPage}} to
           <span class="bold"> {{totalPages}}</span> of <span class="bold">{{length}}</span>
            entries</p>
        </div>
      </div>
      <div class="pt-2">
          <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" [pageSize]="length" [maxSize]="5"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="changePage($event)">
          </ngb-pagination>
      </div>

    </div>


  </div>

</div>
