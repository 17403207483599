import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
export interface Auth {
  email: string;
  password: string;
}
@Injectable({
  providedIn: 'root'
})

export class WbService {

  constructor(
    private httpClient: HttpClient
  ) { }

  login(body: Auth) {
    return this.httpClient.post(environment.wbApiUrl+'/user/login', body);
  }

  getReport(reportId: number) {
    return this.httpClient.get(environment.wbApiUrl+'/report/' + reportId + '/get_report',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

}
