<div class="row">
  <div class="col-3">
    <div class="cust_card card_1 d-flex">
      <div class="card_left">
        <p class="mb-2">Sample Processing</p>
        <h5 class="clr_text">{{oncoBenchAnalyticsdata.reportProcessingCount || 0}}</h5>
      </div>
      <div class="card_right">
        <i class="zmdi zmdi-spinner"></i>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="cust_card card_2 d-flex">
      <div class="card_left">
        <p class="mb-2">Informatics Success Analysis</p>
        <h5 class="clr_text">{{oncoBenchAnalyticsdata.bioinformaticsCount || 0}}</h5>
      </div>
      <div class="card_right">
        <i class="zmdi zmdi-check"></i>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="cust_card card_3  d-flex">
      <div class="card_left">
        <p class="mb-2">Informatics Failure Analysis</p>
        <h5 class="clr_text">{{oncoBenchAnalyticsdata.variantAnalysisCount || 0}}</h5>
      </div>
      <div class="card_right">
        <i class="zmdi zmdi-format-line-spacing"></i>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="cust_card card_4 d-flex">
      <div class="card_left">
        <p class="mb-2">Report Generated</p>
        <h5 class="clr_text">{{oncoBenchAnalyticsdata.reportGeneratedCount || 0}}</h5>
      </div>
      <div class="card_right ">
        <i class="zmdi zmdi-calendar"></i>
      </div>
    </div>
  </div>
</div>
<!-- analysis patient list -->
<div class="p-0 mt-4">
  <div class="search_bar d-flex justify-content-between ">
    <form class="w-87">
      <div class="search_group">
        <i class="zmdi zmdi-search"></i>
        <input type="text" class="form-control" placeholder="Search Patient by ID, Name etc." (keyup)="modelChange($event)">
      </div>
    </form>

    <!-- <div class="dropdown filter_button ml-3">
      <button type="button" class="btn border filter_btn   dropdown-toggle" data-toggle="dropdown">
        <i class="zmdi zmdi-filter-list"></i>
        Filter
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item" href="javascript:void(0)">Link 1</a>
        <a class="dropdown-item" href="javascript:void(0)">Link 2</a>
      </div>
    </div> -->

  </div>
  <!--patient list tbale  -->
  <div class="p-2 border rounded mt-4 patients-list_table analysis_list">
    <p class="p-2 f-weight-medium clr_text2">Patient List</p>

    <table class="table analysis_table mb-0">
      <thead>
        <tr>
          <th>Patient ID</th>
          <th>Patient Name</th>
          <!-- <th>Org Id</th> -->
          <th>Test Name</th>
          <th>Sample Status</th>
          <th>Final report</th>
          <th>Actions</th>
        </tr>
      </thead>
      <!-- <div *ngIf="patientSpinner">
        <div class="spinner-border" role="status">
          <span></span>
        </div>
      </div> -->
      <div *ngIf="patientSpinner" class="w-100 spinner_position">
        <div class="spinner-border  m-auto d-block" role="status">
          <span></span>
        </div>
      </div>
      <tbody *ngIf="!patientSpinner && patientList==null && length == 0"  style="text-align: center;">
        <tr>
          <td colspan="6">
            <p style="font-size: larger!important;">There are no Report Processing and Report Generated for this User !!</p>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr *ngFor="let patient of patientList">
          <td>{{patient.patientId}} </td>
          <td><a href="javascript:void(0)">{{patient.patientName}} {{patient.familyName}}</a></td>
          <td>{{patient.testPackageName}}</td>
          <td
            [ngStyle]="{'color': (patient.patientDocumentStatus === 'Report Generated') ? 'green' : (patient.patientDocumentStatus === 'Report Processing') ? 'orange' : (patient.patientDocumentStatus === 'Failure Report') ? 'red' : ''}">
            {{patient.patientDocumentStatus}}</td>
          <td style="justify-self: start;">
            <!-- <a *ngIf="patient.patientDocumentStatus === 'Report Generated'"
              (click)="downloadRecord(patient.finalReportUrl)">
              <i class="zmdi zmdi-download" ></i></a> -->
              <a style="cursor: pointer;" *ngIf=" (patient.testPackageName == 'TarGT First' || this.patient.testPackageName =='Target First') &&  patient.oncobenchAnalysisStatus=='COMPLETED' && patient.wbReportId!=null"
              (click)="clickOnReport(patient.wbReportId,patient.pid,appUser.organisation,appUser.email)"  >Report</a>

          </td>
          <td>
            <div class="actions d-flex">
              <div class="check_success d-flex">
                <i *ngIf="patient.oncobenchAnalysisStatus=='COMPLETED'"
                  class="zmdi zmdi-check-circle"></i>
              </div>
              <div class="spin">

                <i *ngIf="patient.oncobenchAnalysisStatus=='STARTED'"
                  class="zmdi zmdi-spinner"></i>
              </div>
              <div class="error">

                <i *ngIf="patient.oncobenchAnalysisStatus=='FAILED' || patient.oncobenchAnalysisStatus=='ERROR'"
                  class="zmdi zmdi-close-circle"></i>
              </div>
              <i *ngIf="patient.oncobenchAnalysisStatus==null"  class="zmdi zmdi-alert-circle"></i>
              <a [routerLink]="['/bioinformatics',patient.pid]"  target="_blank" class="pl-2">Bioinformatics Analysis</a>

              <span style="font-size: large;font-weight: bold;padding-left: 5px;padding-right: 5px;">|</span>
              <a [routerLink]="['/view_patient',patient.pid]"  target="_blank">View</a>

              <!-- <div class="check_default d-flex ml-4">
                <i class="zmdi zmdi-check-circle"></i>
                <span class="pl-1">Variant Analysis</span>
              </div> -->


              <!-- <div class="dropdown ">
                <button type="button" class="btn border filter_btn   dropdown-toggle" data-toggle="dropdown">
                  <i class="zmdi zmdi-settings"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="javascript:void(0)">Upload</a>
                  <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                  <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                </div>
              </div> -->

            </div>
          </td>
        </tr>
        <!-- <tr>
          <td>002</td>
          <td><a href="javascript:void(0)">sarah J</a></td>
          <td>tarGT First</td>
          <td>Report Generated</td>
          <td>
            <a href="javascript:void(0)" class="clr_text2"><i class="zmdi zmdi-download "></i></a></td>
          <td>
            <div class="actions d-flex">
              <div class="check_success d-flex">
                <i class="zmdi zmdi-check-circle"></i>
                <span class="pl-1">Bioinformatics Analysis</span>
              </div>
              <div class="check_default d-flex ml-4">
                <i class="zmdi zmdi-check-circle"></i>
                <span class="pl-1">Variant Analysis</span>
              </div>


              <div class="dropdown ">
                <button type="button" class="btn border filter_btn   dropdown-toggle" data-toggle="dropdown">
                  <i class="zmdi zmdi-settings"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="javascript:void(0)">Upload</a>
                  <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                  <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                </div>
              </div>

            </div>

          </td>
        </tr> -->


      </tbody>
    </table>
    <!-- pagination -->
    <div class="d-flex justify-content-between align-items-center mt-3 pagination_no">
      <div class="">
        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
          <p>Showing
            {{currentPage}} to
            <span class="bold"> {{totalPages}}</span> of <span class="bold">{{length}}</span>
            entries
          </p>
        </div>
      </div>
      <div class="pt-2">
        <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" [pageSize]="length" [maxSize]="5"
          [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="changePage($event)">
        </ngb-pagination>
      </div>

    </div>


  </div>

</div>
