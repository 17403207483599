import { OncoBenchAnalyticsResDto } from './../../_interfaces/lab-model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LabService } from 'src/app/_services/oncobench-service/lab.service';
import { patientListDto, PaginationReqInterface } from 'src/app/_interfaces/patient.model';
import { saveAs } from '@progress/kendo-file-saver';
import { User } from 'src/app/_interfaces/auth.model';
import { ReportService } from 'src/app/_services/workbench-service/report.service';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit {

  patientList!: patientListDto[];
  paginationReq = {} as PaginationReqInterface;
  oncoBenchAnalyticsdata={} as OncoBenchAnalyticsResDto;

  totalPages!: number;
  currentPage!: number;
  length!: number;
  collectionSize!: number;
  patientSpinner = true;
  appUser = {} as User;
  workbenchUrl!:any
  constructor(private router : Router,
    private reportService:ReportService,
    private _labService : LabService) { }

  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem("user") || '{}');

    this.paginationReq.pageSize = 30;
    this.paginationReq.reqPageNo = 0;
    this.paginationReq.sortBy = "lastModified";
    this.paginationReq.sortOrder = "DESC";
    this.workbenchUrl = 'http://52.66.98.251:9500';

    this.getPatientList();
    this.getOncoBenchAnalyticsData()
  }

  changePage(event: any) {
    this.patientSpinner=true
    console.log("event", event);
    this.currentPage = event;
    this.patientList=[];
    this.paginationReq.reqPageNo = this.currentPage - 1;
    this.getPatientList();
  }


  // patient list
  getPatientList() {
    if (this.currentPage == null) {
      this.currentPage = 0;
      this.paginationReq.reqPageNo = this.currentPage;
    }
    this.paginationReq.isMainList=false;
    this._labService.getPatientList(this.paginationReq).subscribe(
      (data :any) => {
        this.patientList = data["payLoad"]["patientList"];

        console.log("this.patientList", data);
        this.length = this.patientList?.length;
        this.totalPages = data["payLoad"]["totalPages"];
        this.currentPage = data["payLoad"]["currentPage"] + 1;
        this.collectionSize = this.length * this.totalPages;
        this.patientSpinner = false;

      },
      (error:any) => {
        console.log(error);
      }
    );
  }

  // download record
  downloadRecord(url:any){
    let filename:any
    let fileFormat:any
    if(url){
      filename=url.split("/").pop()
    }
    fileFormat=filename.split('.').pop().toLowerCase()
    if(fileFormat=='pdf'){
      this._labService.downloadRecord(url).subscribe((res:any)=>{
        let blob= new Blob([res], { type: 'application/pdf' })
        saveAs(blob,filename)
      })
    }else{
        window.open(url);
    }
  }

  // search patient
  public modelChange(str:any) :void{
    console.log(str.target.value);
    this.paginationReq.searchKey = str.target.value;
    console.log(this.paginationReq);
    this.getPatientList();
  }
  getOncoBenchAnalyticsData() {
    this._labService.getOncoBenchAnalyticsData().subscribe(
      (data :any) => {
        this.oncoBenchAnalyticsdata= data["payLoad"];
        console.log("this.oncoBenchAnalyticsdata", this.oncoBenchAnalyticsdata);
      });
    }
    clickOnReport(reportId:any,pid:any,org:any,emailid:any){
      console.log("Workinggg..!!")
      this.reportService.addReportType(reportId,'First 72 Solid').subscribe(
        (res:any) => {
          console.log(res)
          let rediretTo='/wb/'+pid+'/dash-reports/'+reportId;
          window.open(rediretTo);
        },(error:any) => {
          console.log(error);
        }
      )
    }
}
