import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { VariantDashboardComponent } from '../workbench/variant-dashboard/variant-dashboard.component';
import { PatientsListComponent } from './patients-list/patients-list.component';
import { HeaderComponent } from './components/header/header.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { BioInformaticsComponent } from './bio-informatics/bio-informatics.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TokenInterceptor } from '../_services/oncobench-service/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';


@NgModule({
  declarations: [
    PagesComponent,
    PatientsListComponent,
    HeaderComponent,
    SideNavComponent,
    AddPatientComponent,
    AnalysisComponent,
    BioInformaticsComponent,
    FileUploadComponent,
    DashboardComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    PagesRoutingModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxSpinnerModule,


  ]
})
export class PagesModule { }
