import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { FirstReportPdfRes, HotspotTrailResDto, RelevantData, ReportRes } from 'src/app/_interfaces/reports';
import { ReportService } from 'src/app/_services/workbench-service/report.service';

@Component({
  selector: 'app-tmh',
  templateUrl: './tmh.component.html',
  styleUrls: ['./tmh.component.scss']
})
export class TmhComponent implements OnInit {

  footerDiscription = "All the 4baseCare test samples are processed at 4basecare Offshore Development Centre (ODC) - Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15189:2012 for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO. 69P,71/4P,78/8AP,134P,76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification"

  isTMH = true;
  isReportDownload:boolean=false
  imgsings:any=''
  firstPageTable:any=''
  firstPageTable1:any=''
  firstPageTable2:any=''

  reportId!: number;
  reportPdfRes = {} as FirstReportPdfRes;
  report = {} as ReportRes;
  date = Date();
  downloadSpinner = false;
  data: any;
  reportData = false;
  isStatusDisplay = false;

  statusMsg!: string;
 newsnvHotspotTrailResList:any=[]
 snvHotSpot:any=[]
 cnaHotSpot:any=[]
 fusionHotSpot:any=[]

 geneFlag=false
 variantFlag=false
  tableShow = false;
  sampleSource!: string;
  firstTableFlag:boolean=false
mixedVariants:any=[]
  snvSameRelevantData: RelevantData[] = [];
  snvOtherRelevantData: RelevantData[] = [];

  cnaSameRelevantData: RelevantData[] = [];
  cnaOtherRelevantData: RelevantData[] = [];

  fusionSameRelevantData: RelevantData[] = [];
  fusionOtherRelevantData: RelevantData[] = [];

  constructor(
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private titleService: Title
  ) { }
temp:any='empty'
isTemp2=false

  ngOnInit(): void {
    this.reportId = this.routerAct.snapshot.params['id'];
    this.temp =this.routerAct.snapshot.params['temp'];
    console.log("temp ->",this.temp)
    if (this.reportId) {
      this.getReport();
    }
  }



  isKokilaben =false;
  tableCount=0;
  referringClinician!:string;
  labHospital!:string;

  geneMutationlist:any[]=[];
  fusionGeneMutationList:any[]=[];
  getReport() {
    this.reportPdfRes.allHotspotTrailList=[]
    this.reportService.first72ReportPdfResponse(this.reportId).subscribe(
      (response: any) => {
console.log(response);
        this.reportData = true;
        this.statusMsg = response.message;
        this.showSuccess();
        this.reportPdfRes = response['payLoad'];
        console.log("-------->",this.reportPdfRes.allHotspotTrailList)

        if(this.reportPdfRes?.report?.firstCancerType!=null && this.reportPdfRes?.report?.firstCancerType?.name.includes("Cancer of Unknown Primary")){
          this.isStatusDisplay=false
        }else{
          this.isStatusDisplay=true
        }
        if(this.reportPdfRes.geneMutationResList!=null && this.reportPdfRes.geneMutationResList?.length>0){
          for(let i=0;i<this.reportPdfRes.geneMutationResList?.length;i++){
            if(this.reportPdfRes.geneMutationResList[i]?.gene!=null && this.reportPdfRes.geneMutationResList[i]?.gene?.length!=0){
              this.geneMutationlist.push(this.reportPdfRes.geneMutationResList[i]);
            }
          }
        }
        console.log("geneMutationlist =>",this.geneMutationlist );
        if(this.reportPdfRes.fusionGeneMutationResList!=null && this.reportPdfRes.fusionGeneMutationResList?.length>0){
          for(let i=0;i<this.reportPdfRes.fusionGeneMutationResList?.length;i++){
            if(this.reportPdfRes.fusionGeneMutationResList[i]?.gene!=null && this.reportPdfRes.fusionGeneMutationResList[i]?.gene?.length!=0){
              this.fusionGeneMutationList.push(this.reportPdfRes.fusionGeneMutationResList[i]);
            }
          }
        }
        console.log("fusionGeneMutationList =>",this.fusionGeneMutationList );

        console.log("this.reportPdfRes =>",this.reportPdfRes )
        this.report = response['payLoad']['report'];

        this.referringClinician=this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
        this.titleService.setTitle("View_report"+this.report?.patient?.ecrfId);

        this.referringClinician=this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
        this.labHospital=this.report?.labDetails?.hospital;

        this.tableCount= this.reportPdfRes.allHotspotTrailList.length;

        this.setPage1Table(this.reportPdfRes);
        console.log("additional noted before-->", this.report.addNotes)
        if (this.report.addNotes == null || this.report.addNotes == undefined || this.report.addNotes == '') {
          this.report.addNotes = '<p></p>'
          console.log("additional noted Not requried-->", this.report.addNotes)
        }
        if (this.report.addNotes != null || this.report.addNotes == '<p></p>') {

          // console.log("actual Addnotes",this.report.addNotes)
          this.report.addNotes = this.report.addNotes.replace(/<p>/g, '<div>');
          this.report.addNotes = this.report.addNotes.replace(/<\/p>/g, '</div>');
          this.report.addNotes = this.report.addNotes.replace(/{/g, '<sup>');
          this.report.addNotes = this.report.addNotes.replace(/}/g, '</sup>');
          this.report.addNotes = this.report.addNotes.replace(/~/g, '<sub>');
          this.report.addNotes = this.report.addNotes.replace(/!/g, '</sub>');
          console.log("additional noted after-->", this.report.addNotes)
        }
        if(this.report.disclaimerNote!=null ){
          this.report.disclaimerNote = this.report.disclaimerNote.replace(/{/g, '<sup>');
          this.report.disclaimerNote = this.report.disclaimerNote.replace(/}/g, '</sup>');
          this.report.disclaimerNote = this.report.disclaimerNote.replace(/~/g, '<sub>');
          this.report.disclaimerNote = this.report.disclaimerNote.replace(/!/g, '</sub>');
          this.report.disclaimerNote = this.report.disclaimerNote.replaceAll("$", "<sup>$</sup>");
        }
        if (this.report.labDetails != null) {
          // console.log("sampleScorce-->",this.report.labDetails.sampleSource)
        this.sampleSource = this.report.labDetails?.sampleSource?.replace(/FFPE BlockId:/g, '');
        }
        if (this.reportPdfRes.cnaReportedVariantList.length > 0) {
          this.tableShow = true;
        }
        this.getExonNumbers();
        let geneLength:number=0;
        let associationListLenght:number=0;
        let drugListLength:number=0;
        if(this.reportPdfRes.snvHotspotTrailResList && this.reportPdfRes.snvHotspotTrailResList.length>0){
          geneLength=geneLength+this.reportPdfRes.snvHotspotTrailResList.length;
          this.reportPdfRes.snvHotspotTrailResList.forEach(element => {
            let snvData:any=element
            let maxSameassociationListLenght:number=0
            let maxSamedrugListLength:number=0
            let maxOtherassociationListLenght:number=0
            let maxOtherdrugListLength:number=0
         if(snvData['sameCancerType']!=null){
          maxSameassociationListLenght=associationListLenght+snvData['sameCancerType'].associationList.length
          maxSameassociationListLenght=drugListLength+snvData['sameCancerType'].drugList.length
         }
         else{
          maxSameassociationListLenght=associationListLenght
          maxSamedrugListLength=drugListLength
         }
          if(snvData['otherCancerType']!=null){
            maxOtherassociationListLenght=associationListLenght+snvData['otherCancerType'].associationList.length
            maxOtherdrugListLength=drugListLength+snvData['otherCancerType'].drugList.length
         }else{
          maxOtherassociationListLenght=associationListLenght
          maxOtherdrugListLength=drugListLength
         }

         if((maxSameassociationListLenght+maxSamedrugListLength)>(maxOtherassociationListLenght+maxOtherdrugListLength)){
          associationListLenght=maxSameassociationListLenght
          drugListLength=maxSamedrugListLength
         }else{
          associationListLenght=maxOtherassociationListLenght
          drugListLength=maxOtherdrugListLength
         }
          });

        }
        if(this.reportPdfRes.fusionHotspotTrailResList && this.reportPdfRes.fusionHotspotTrailResList.length>0){
          geneLength=geneLength+this.reportPdfRes.fusionHotspotTrailResList.length
          this.reportPdfRes.fusionHotspotTrailResList.forEach(element => {
            let fusionData:any=element
          if(fusionData['sameCancerType']){
            associationListLenght=associationListLenght+fusionData['sameCancerType'].associationList.length
            drugListLength=drugListLength+fusionData['sameCancerType'].drugList.length
          }

          });

        }
        console.log("size",associationListLenght," ",drugListLength)
        if(associationListLenght>=7 || drugListLength>=7 || geneLength>6){
          this.firstTableFlag=true
        }
        if(this.reportId==2976){
          // if(this.reportPdfRes.snvHotspotTrailResList.length>1){
            this.reportPdfRes.snvHotspotTrailResList.forEach((element:any)=>{
              if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
                this.newsnvHotspotTrailResList.push(element)
               let index= this.reportPdfRes.snvHotspotTrailResList.indexOf(element)
               this.reportPdfRes.snvHotspotTrailResList.splice(index,1)
              }
            })
          // }
          if(this.reportPdfRes.cnaHotspotTrailResList.length>1){
            this.reportPdfRes.cnaHotspotTrailResList.forEach((element:any)=>{
              if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
                this.newsnvHotspotTrailResList.push(element)
               let index= this.reportPdfRes.cnaHotspotTrailResList.indexOf(element)
               this.reportPdfRes.cnaHotspotTrailResList.splice(index,1)
              }

            })
          }
          if(this.newsnvHotspotTrailResList.length>0){
            this.geneFlag=true
          }
        }else{
            if((this.reportPdfRes.cnaHotspotTrailResList.length==0)?this.reportPdfRes.snvHotspotTrailResList.length>2:this.reportPdfRes.snvHotspotTrailResList.length>1){
          this.reportPdfRes.snvHotspotTrailResList.forEach((element:any)=>{
            if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
              this.newsnvHotspotTrailResList.push(element)
             let index= this.reportPdfRes.snvHotspotTrailResList.indexOf(element)
             this.reportPdfRes.snvHotspotTrailResList.splice(index,1)
            }
          })
        }
        if((this.reportPdfRes.snvHotspotTrailResList.length==0)?this.reportPdfRes.cnaHotspotTrailResList.length>2:this.reportPdfRes.cnaHotspotTrailResList.length>1){
          this.reportPdfRes.cnaHotspotTrailResList.forEach((element:any)=>{
            if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
              this.newsnvHotspotTrailResList.push(element)
             let index= this.reportPdfRes.cnaHotspotTrailResList.indexOf(element)
             this.reportPdfRes.cnaHotspotTrailResList.splice(index,1)
            }

          })
        }
        if(this.newsnvHotspotTrailResList.length>0){
          this.geneFlag=true
        }
        }
        let allLength=this.reportPdfRes.snvHotspotTrailResList.length+this.reportPdfRes.cnaHotspotTrailResList.length+this.reportPdfRes.fusionHotspotTrailResList.length
        if(this.newsnvHotspotTrailResList.length==0 && allLength>4){
          this.geneFlag=true
        }
       let variantcount=this.reportPdfRes.snvReportedVariantList.length+this.reportPdfRes.cnaReportedVariantList.length+this.reportPdfRes.fusionReportedVariantList.length
        if(this.geneFlag && variantcount>6){
          this.variantFlag=true
        }
      },
      error => {
        console.log('oops...!', error)
        this.statusMsg = error.error.error;
        this.showFail();
      });
  }
  firstTableContext!:number;
  secondTableContext!:number;
  drugCount=0;
  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }
  showFail() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 10000 }
    );
  }

  count = 0;
  countOther = 0;
  WTcountSNV: boolean = false;
  otherCountSNV = false;
  prognasticCount = 0;
  getExonNumbers() {

    this.reportPdfRes.snvReportedVariantList.forEach(reportedVariant => {
      if(reportedVariant.prognasticSignificance!=null){
        this.prognasticCount++;
      }
      reportedVariant.aachangeknownGeneList.forEach((aachangeknownGene, index) => {
        if(reportedVariant.selectedAAChangeknownGene && reportedVariant.selectedAAChangeknownGene.length>0){
          if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {

            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }
        }else{
          if (aachangeknownGene == 'UNKNOWN') {
            // console.log("UNKNOWN");
            reportedVariant.selectedAAChangeknownGene = ":::::";
            // console.log("reportedVariant.selectedAAChangeknownGene UNKNOWN", reportedVariant.selectedAAChangeknownGene);
          } else if (aachangeknownGene == '.') {
            reportedVariant.selectedAAChangeknownGene = ":::::";
            // console.log("reportedVariant.selectedAAChangeknownGene dot", reportedVariant.selectedAAChangeknownGene);
          } else if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
            reportedVariant.selectedAAChangeknownGene = aachangeknownGene.split(":")[3];
            reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ aachangeknownGene.split(":")[4];
            console.log('AAAAAa',reportedVariant.selectedAAChangeknownGene)
            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }

          // console.log("reportedVariant.selectedAAChangeknownGene", reportedVariant.selectedAAChangeknownGene);


          if (reportedVariant.aachangeknownGeneList.length - 1 == index) {
            // console.log("reportedVariant.selectedAAChangeknownGene index", reportedVariant.selectedAAChangeknownGene);

            if (reportedVariant.selectedAAChangeknownGene == null) {
              let selectedAA:any= reportedVariant.aachangeknownGeneList[0];
              reportedVariant.selectedAAChangeknownGene = selectedAA.split(":")[3];
              reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ selectedAA.split(":")[4];// reportedVariant.selectedAAChangeknownGene = reportedVariant.aachangeknownGeneList[0];
              reportedVariant.exonNumber = reportedVariant.aachangeknownGeneList[0].split(":")[2].substring(4);
              // console.log("reportedVariant.selectedAAChangeknownGene null", reportedVariant.selectedAAChangeknownGene);
            }
          }
        }
        // console.log("==>", aachangeknownGene, reportedVariant.ensembleValue);


      });
      console.log("JJJJ",reportedVariant)
      if (reportedVariant.significance == 'NA' || reportedVariant.significance =='Not Classified') {
        this.count++;
      }
      if (reportedVariant.significance != "NA" && reportedVariant.significance !='Not Classified') {
        this.countOther++;
      }

      if (this.count == 0) {
        this.WTcountSNV = true;
      }
      if (this.countOther > 0) {
        this.otherCountSNV = true;
      }
    });
    this.reportPdfRes.cnaReportedVariantList.forEach(reportedVariant => {
      if(reportedVariant.prognasticSignificance!=null){
        this.prognasticCount++;
      }
    });
    // console.log('NA.count: ', this.WTcountSNV, '\n Other.count: ', this.otherCountSNV);
  }

  setPage1Table(reportPdfRes: FirstReportPdfRes) {
    // SNV //
    if (reportPdfRes.snvHotspotTrailResList != null) {
      for (let i = 0; i < reportPdfRes.snvHotspotTrailResList.length; i++) {
        // Different Cancer type
        if (reportPdfRes.snvHotspotTrailResList[i].otherCancerType != null) {
          for (let j = 0; j < reportPdfRes.snvHotspotTrailResList[i].otherCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.snvHotspotTrailResList[i].otherCancerType.associationList[j];
            relevantData.cancerType = reportPdfRes.snvHotspotTrailResList[i].otherCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.snvHotspotTrailResList[i].otherCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            if(this.snvOtherRelevantData.length>0){

              let data=this.snvOtherRelevantData.filter((snv:any)=>{
                if(snv.therapy==relevantData.therapy && snv.association==relevantData.association){
                  snv.cancerType+=" / "+relevantData.cancerType;
                  console.log("snv.cancerType-->",snv.cancerType)
                  return snv;
                }
              });
              if(data==null || data.length<=0){
                this.snvOtherRelevantData.push(relevantData);
              }
            }else{
              this.snvOtherRelevantData.push(relevantData);
            }
          }
          console.log("this.snvOtherRelevantData", this.snvOtherRelevantData);
          this.drugCount+=this.snvOtherRelevantData?.length;
          reportPdfRes.snvHotspotTrailResList[i].otherTypeRelevantData = this.snvOtherRelevantData;
          this.snvOtherRelevantData = [];
        }

        // Same Cancer Type
        if (reportPdfRes.snvHotspotTrailResList[i].sameCancerType != null) {
          for (let j = 0; j < reportPdfRes.snvHotspotTrailResList[i].sameCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.snvHotspotTrailResList[i].sameCancerType.associationList[j];
            // relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.snvHotspotTrailResList[i].sameCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.snvSameRelevantData.push(relevantData);
          }
          console.log("this.snvSameRelevantData", this.snvSameRelevantData);
          reportPdfRes.snvHotspotTrailResList[i].sameTypeRelevantData = this.snvSameRelevantData;
          this.snvSameRelevantData = [];
        }

      }
    }


    // CNA //
    if (reportPdfRes.cnaHotspotTrailResList != null) {
      for (let i = 0; i < reportPdfRes.cnaHotspotTrailResList.length; i++) {

        // Different Cancer type
        if (reportPdfRes.cnaHotspotTrailResList[i].otherCancerType != null) {
          for (let j = 0; j < reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.associationList[j];
            relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            if(this.cnaOtherRelevantData.length>0){

              let data=this.cnaOtherRelevantData.filter((cna:any)=>{
                if(cna.therapy==relevantData.therapy && cna.association==relevantData.association){
                  cna.cancerType+=" / "+relevantData.cancerType;
                  console.log("snv.cancerType-->",cna.cancerType)
                  return cna;
                }
              });
              if(data==null || data.length<=0){
                this.cnaOtherRelevantData.push(relevantData);
              }
            }else{
              this.cnaOtherRelevantData.push(relevantData);
            }
          }
        }
        console.log("this.cnaOtherRelevantData", this.cnaOtherRelevantData);
        this.drugCount+=this.cnaOtherRelevantData?.length;
        reportPdfRes.cnaHotspotTrailResList[i].otherTypeRelevantData = this.cnaOtherRelevantData;

        // Same Cancer Type
        if (reportPdfRes.cnaHotspotTrailResList[i].sameCancerType != null) {
          for (let j = 0; j < reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.associationList[j];
            // relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.cnaSameRelevantData.push(relevantData);
          }
        }
        console.log("this.cnaSameRelevantData", this.cnaSameRelevantData);
        reportPdfRes.cnaHotspotTrailResList[i].sameTypeRelevantData = this.cnaSameRelevantData;

      }
    }

    // Fusion //
    if (reportPdfRes.fusionHotspotTrailResList != null) {
      for (let i = 0; i < reportPdfRes.fusionHotspotTrailResList.length; i++) {

        // Different Cancer type
        if (reportPdfRes.fusionHotspotTrailResList[i].otherCancerType != null) {
          for (let j = 0; j < reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.associationList[j];
            relevantData.cancerType = reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            if(this.fusionOtherRelevantData.length>0){

              let data=this.fusionOtherRelevantData.filter((fusion:any)=>{
                if(fusion.therapy==relevantData.therapy && fusion.association==relevantData.association){
                  fusion.cancerType+=" / "+relevantData.cancerType;
                  console.log("snv.cancerType-->",fusion.cancerType)
                  return fusion;
                }
              });
              if(data==null || data.length<=0){
                this.fusionOtherRelevantData.push(relevantData);
              }
            }else{
              this.fusionOtherRelevantData.push(relevantData);
            }
          }
        }
        console.log("this.fusionOtherRelevantData", this.fusionOtherRelevantData);
        this.drugCount+=this.fusionOtherRelevantData?.length;
        reportPdfRes.fusionHotspotTrailResList[i].otherTypeRelevantData = this.fusionOtherRelevantData;

        // Same Cancer Type
        if (reportPdfRes.fusionHotspotTrailResList[i].sameCancerType != null) {
          for (let j = 0; j < reportPdfRes.fusionHotspotTrailResList[i].sameCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.fusionHotspotTrailResList[i].sameCancerType.associationList[j];
            // relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.fusionHotspotTrailResList[i].sameCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.fusionSameRelevantData.push(relevantData);
          }
        }
        console.log("this.fusionSameRelevantData", this.fusionSameRelevantData);
        reportPdfRes.fusionHotspotTrailResList[i].sameTypeRelevantData = this.fusionSameRelevantData;

      }
    }
    this.firstTableContext=this.reportPdfRes?.snvHotspotTrailResList?.length+this.reportPdfRes?.cnaHotspotTrailResList?.length+this.reportPdfRes?.fusionHotspotTrailResList?.length;
    this.secondTableContext=this.reportPdfRes?.snvReportedVariantList?.length;
    console.log("1st table leghth->",this.firstTableContext,
    "\n 2nd Table Length->", this.secondTableContext,
    "\n drugCount Table Length->",  this.drugCount);

    if (reportPdfRes.allHotspotTrailList != null) {
      for (let i = 0; i < reportPdfRes.allHotspotTrailList.length; i++) {
        // Different Cancer type
        if (reportPdfRes.allHotspotTrailList[i].otherCancerType != null) {
          for (let j = 0; j < reportPdfRes.allHotspotTrailList[i].otherCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.allHotspotTrailList[i].otherCancerType.associationList[j];
            relevantData.cancerType = reportPdfRes.allHotspotTrailList[i].otherCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.allHotspotTrailList[i].otherCancerType.drugList[j].forEach(
              (
                drug: { name: string; }) => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            if(this.snvOtherRelevantData.length>0){

              let data=this.snvOtherRelevantData.filter((snv:any)=>{
                if(snv.therapy==relevantData.therapy && snv.association==relevantData.association){
                  snv.cancerType+=" / "+relevantData.cancerType;
                  console.log("snv.cancerType-->",snv.cancerType)
                  return snv;
                }
              });
              if(data==null || data.length<=0){
                this.snvOtherRelevantData.push(relevantData);
              }
            }else{
              this.snvOtherRelevantData.push(relevantData);
            }
          }
          console.log("this.snvOtherRelevantData", this.snvOtherRelevantData);
          this.drugCount+=this.snvOtherRelevantData?.length;
          reportPdfRes.allHotspotTrailList[i].otherTypeRelevantData = this.snvOtherRelevantData;
          this.snvOtherRelevantData = [];
        }

        // Same Cancer Type
        if (reportPdfRes.allHotspotTrailList[i].sameCancerType != null) {
          for (let j = 0; j < reportPdfRes.allHotspotTrailList[i].sameCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.allHotspotTrailList[i].sameCancerType.associationList[j];
            // relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.allHotspotTrailList[i].sameCancerType.drugList[j].forEach(
              (              drug: { name: string; }) => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.snvSameRelevantData.push(relevantData);
          }
          console.log("this.snvSameRelevantData", this.snvSameRelevantData);
          reportPdfRes.allHotspotTrailList[i].sameTypeRelevantData = this.snvSameRelevantData;
          this.snvSameRelevantData = [];
        }

      }
    }

  }
downloadReport() {
    this.downloadSpinner = true;
    this.page1()
}

page1(){
  let data:any = document.getElementById("firstPageTable");
  html2canvas(data).then(canvas => {
    // var foreignObjectRendering: true;
    var imgWidth = 208;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    const contentDataURL = canvas.toDataURL("image/png");
    this.firstPageTable=contentDataURL
    if(this.temp=="temp3" && this.reportPdfRes.allHotspotTrailList.length>6){
      this.page1_1()
    }else if(this.temp=="temp3" && (this.reportPdfRes.allHotspotTrailList.length>3 && this.reportPdfRes.allHotspotTrailList.length<=6)){
      this.page1_2()
    }else{

      this.print()
    }
  });
}
page1_1(){
  let data:any = document.getElementById("firstPageTable1");
  html2canvas(data).then(canvas => {
    // var foreignObjectRendering: true;
    var imgWidth = 208;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    const contentDataURL = canvas.toDataURL("image/png");
    this.firstPageTable1=contentDataURL
    if(this.temp=="temp3" && this.reportPdfRes.allHotspotTrailList.length>8){
      this.page1_2()
    }else{

      this.print()
    }
  });
}
page1_2(){
  let data:any = document.getElementById("firstPageTable2");
  html2canvas(data).then(canvas => {
    // var foreignObjectRendering: true;
    var imgWidth = 208;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    const contentDataURL = canvas.toDataURL("image/png");
    this.firstPageTable2=contentDataURL


      this.print()

  });
}

getpposition(data:any, splice:string){
  if(data!=null )
  {

    if(splice!=null && (splice.includes("Splice") || splice.includes("splice"))){
      if(data.includes(",")){
        if(data.split(',')[0].includes("c.")){
          return  data.split(',')[0]
        }
      }
    }else{
    if(data.includes(",")){
      if(data.split(',')[1].includes("p.")){
        return  data.split(',')[1]
      }
    }
  }
  }else{
    return null;
  }
}
page4_img(){
  let data:any = document.getElementById("imgsings");
      html2canvas(data).then(canvas => {
        // var foreignObjectRendering: true;
        var imgWidth = 208;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
        this.imgsings=contentDataURL
        this.isReportDownload=true
      this.print()
      });
}

print(){
  this.isReportDownload=true
 setTimeout(()=>{
  this.downloadSpinner = false;
  document.title="tarGT_First_72_Solid" + "-" + this.report?.patient?.name?.replace(/\./g, ' ')
  window.print()
  this.isReportDownload=false
 },3000)
}


}
