<div *ngIf="!reportData" class="d-flex justify-content-center mt-5">
  <span class="visually-hidden"></span>
  <div class="spinner-border" style="width: 8rem; height: 8rem" role="status"></div>
</div>



<div *ngIf="reportData" style="height: 1600px; padding: 10px; margin: 0 auto;">

  <button [disabled]="downloadSpinner" type="button" class="btn btn-primary btn btn-primary download_button"
    (click)="downloadReport()">
    <span *ngIf="!downloadSpinner">
      <i class="zmdi zmdi-download"></i>
      Download Report
    </span>
    <div *ngIf="downloadSpinner" class="spinner-border text-dark">
      <span class="sr-only"></span>
    </div>
  </button>

  <!-- For TMH -->
  <!-- TMH -->
  <!-- For TMH Template 1-->
  <div *ngIf=" isTMH && temp=='temp1'">

    <!-- Page 1 -->
    <div id="page1" class="container position-relative page" style="background-color: white">

      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                SCOPE OF THE TEST
              </p>
              <p>SNVs, InDels, CNAs, Gene Fusions status</p>
            </div>
            <img src="../../../assets/4bc/BorderLine.png" class="mr-2 w-100" />
          </div>
        </div>
        <div class="col">
          <div class="">
            <div class="w-100">
              <!-- <p style="color: #208dcb;" class="py-1">
                                                            <span style="color: #000 !important"></span>
                                                          </p> -->
              <div class="w-100" style="min-height: 80px">
                <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                  <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                </p>

                <p *ngIf="report.clinicalSummary != null">
                  <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                    }}</span>
                </p>
              </div>
              <!-- </div> -->
              <img src="../../../assets/4bc/BorderLine.png" class="mr-2 w-100" />

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan">Patient Name : </span>
              <span>{{ report.patient?.name }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
              <span class="text-black">{{
                report.patient?.gender | titlecase
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
              <span class="text-black">{{
                report.patient?.dateOfBirth
                ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                : report.patient?.age + " Years"
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Case No : </span><span>{{ report?.tmhReportInfo?.caseNo }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Path No : </span><span>{{ report?.tmhReportInfo?.pathNo }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Referred By : </span><span>{{ referringClinician }}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled mt-4 pt-4">
            <li class="pb-1">
              <span class="clr-cyan">Patient ID : </span><span>{{ report.patient?.organisationPatientId }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Category : </span><span>{{ report?.tmhReportInfo?.category }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Collected on : </span><span>{{
                report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Reported on : </span><span>{{ date | date : "dd/MM/yyyy" }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Hospital Name : </span><span>{{ labHospital }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <h6 class="py-2 h6_bg">CLINICAL HISTORY/SAMPLE DETAILS</h6>
      <div class="row mt-2 py-2">
        <div class="col-5">
          <h6 class="mb-0 font-weight-bold">Clinical Evaluations</h6>
          <span>{{ report?.cancerIdentifier }}</span>
        </div>
        <div class="col-5">
          <h6 class="mb-0 font-weight-bold">Sample Details</h6>
          <span *ngIf="report.labDetails?.sampleSource">{{
            report.labDetails?.sampleSource
            }}</span>
          <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
        </div>
        <div class="col">
          <h6 class="mb-0 font-weight-bold">Tumor content</h6>
          <span>{{ report?.tmhReportInfo?.tumorContent }}</span>
        </div>
        <div class="w-100">
          <div class="col pt-4">
            <p>(Confirmed by - Consultant Pathologist, HBCHRC, Muzaffarpur)</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row mt-2 py-2">
        <div class="col">
          <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
        </div>
      </div>
      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Genomic findings from tumor profiling
      </h6>

      <!--  -->
      <div class="row">
        <div style="width: 100%;" *ngIf="isReportDownload">
          <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
        </div>
        <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
          <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
            <tr *ngIf="tableCount!= 0">
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>
            <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with therapeutic significance were not detected.
              </td>
            </tr>
            <tr *ngIf="tableCount== 0 && prognasticCount == 0">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with both therapeutic and prognostic significance
                were
                not detected.
              </td>
            </tr>



            <ng-container>
              <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 5 ; let i=index
                                                              ">
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>&nbsp;
                  <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                    <span *ngIf="snvHotspotTrail?.multiVariantOB==null">
                      <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;<span
                        *ngIf="snvHotspotTrail.porCDot != null && snvHotspotTrail.porCDot != ''">({{snvHotspotTrail.porCDot}})</span>
                      <p
                        *ngIf="snvHotspotTrail.allelicBurden != null && !snvHotspotTrail.gene.includes('Wild type')">
                        Allelic burden: {{ snvHotspotTrail.allelicBurden }}%
                      </p>
                    </span>

                    <span *ngIf="snvHotspotTrail?.multiVariantOB!=null"><br />
                      <span style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariantOB"></span>
                    </span></span>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Cancer Type</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>


            </ng-container>
          </table>
          <p *ngIf="
                                snvHotSpot.length != 0 ||
                                cnaHotSpot.length != 0 ||
                                                    fusionHotSpot.length !=0
                                                  " style="font-size: 13px !important" class="mt-0">
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <!--  -->
      <div *ngIf="(tableCount>0) &&
                              prognasticCount==0">
        <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
      </div>
      <div class="row" *ngIf="(tableCount>0 )&&
                              prognasticCount==0">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
            [ngClass]="'table_variant-blue'">

            <ng-container>
              <tr>
                <td colspan="2" style="text-align:center">
                  Clinically relevant genomic alterations associated with prognostic significance were not detected.
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>
      <div *ngIf="prognasticCount>0">
        <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
      </div>

      <div class="row" *ngIf="prognasticCount>0">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
            [ngClass]="'table_variant-blue'">
            <thead>
              <tr>
                <th>Genomic Alteration</th>
                <th>Prognostic Significance</th>

              </tr>
            </thead>
            <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
              <tr>
                <td style="padding-left: 3px !important">
                  <i>{{ prog.gene }}&nbsp; </i>
                  <span [innerHtml]="prog.geneDetailsOB"></span>
                </td>
                <td style="padding-left: 3px !important;">{{prog.statement}}
                </td>
              </tr>
            </ng-container>


          </table>
        </div>
      </div>
      <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
        <div class="col">
          <h6 class="py-2 h6_bg">Additional Notes</h6>
          <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
        </div>
      </div>
      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>

    <!-- page 2 -->
    <div id="page2" *ngIf="
                                                      otherCountSNV == true ||
                                                      reportPdfRes.allVariantsList.length > 0
                                                      " class="container position-relative page"
      style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">

        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">

        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>


      <!--  -->

      <div class="row mt-3" *ngIf="
                                                          otherCountSNV == true ||
                                                          reportPdfRes.allVariantsList.length != 0
                                                        ">
        <div class="col">
          <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
            Variant Details:
          </h6>
        </div>
      </div>

      <div class="row" *ngIf="
                                                          otherCountSNV == true ||
                                                          reportPdfRes.allVariantsList.length != 0
                                                        ">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
            <thead>
              <tr>
                <th>Gene</th>
                <th>Variant Location</th>
                <th>Variant Consequence</th>
                <th>Clinical Significance</th>
                <th>Variant Type</th>
                <th>Reference</th>
              </tr>
            </thead>

            <tr *ngFor="let variant of reportPdfRes.allVariantsList">
              <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <i>{{ variant.gene }}</i>
              </td>
              <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant?.variantType=='snv'">
                  {{ variant?.genomicAlteration }},<br />
                  {{ variant?.ensembleValue }},<br />
                  {{ variant?.exonNumber }}
                </span>
                <span *ngIf="variant?.variantType=='cna'">
                  chr{{ variant?.sample?.split(":")[0] }}:g.{{
                  variant.sample
                  .split(":")[1]
                  .replace("-", "_")
                  .split("|")[0]
                  }}
                </span>
                <span *ngIf="variant.variantType=='fusion'">
                  {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                </span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant.variantType=='snv'">
                  <span *ngIf="variant?.selectedAAChangeknownGeneOB">{{
                    variant?.selectedAAChangeknownGeneOB }}</span>, {{variant?.allelicBurden * 0.01 |
                  percent}}
                </span>
                <span *ngIf="variant.variantType=='cna'">NA</span>
                <span *ngIf="variant.variantType=='fusion'">NA</span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                {{ variant?.significance }}
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant.variantType=='snv'">
                  {{ variant?.exonicFuncknownGene }}
                </span>
                <span *ngIf="variant.variantType=='cna'">
                  Copy Number
                  {{ variant?.sample.split(":")[2] | titlecase }}
                  ({{ variant?.sample.split("|")[1].split(":")[0] }})
                </span>
                <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                {{ variant?.reference }}
              </td>
            </tr>
          </table>
          <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>



      <!--  -->
      <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
        <div class="col">
          <h6 class="py-2 h6_bg">Additional Notes</h6>
          <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>


    <!-- Page 5 -->
    <div id="page5" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="disc">
            <div class="ref_data mt-4">
              <ul class="break-word" style="text-align: justify">
                <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                          text-align: justify;
                                                          /* white-space:pre-wrap; */
                                                          word-break: break-word;
                                                        ">
                  {{ referenceArticle }}
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <div class="row pr-2 above-footer">
        <div class="col pl-5">
          <img src="../../../assets/tmc/basusigns1.png" alt="" class="mb-2 sign_width" />
          <div class="sign_text">
            <p>Dr. Moitri Basu, Ph.D.</p>
            <p>Scientific Officer</p>
            <p>Homi Bhabha cancer hospital & Research centre, <br />Muzaffarpur, A unit of Tata Memorial centre.</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>

    <!-- Page 6 -->
    <div id="page6" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <div class="">
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
        <p class="mb-2" style="text-align:justify">
          <span class="font-weight-normal">TAR</span><b>GT</b>
          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
          is a Next Generation Sequencing based test which identifies
          genetic alterations in a comprehensive panel of well curated 72 genes which are having an impact response to
          approved
          therapy for a particular cancer type. Some of the alterations
          detected may have bearing on prognosis and/or therapeutic options
          and may provide relevant information that allows
          oncologists/clinicians to consider various lines of
          targeted treatment for the patient.
        </p>
      </div>

      <!--  -->
      <div class="row">
        <div class="col">
          <div class="">
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
            <p>
              <span class="font-weight-normal">TAR</span><b>GT</b>
              <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
              detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
              Gene Fusions and splice variants in the 72 genes:
            </p>
          </div>
          <!--  -->
          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>SNVs, SHORT INDELS and CNVs
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                      <span>IDH2</span>
                    </div>
                    <div class="gene_list">
                      <span>JAK2</span><span>KIT</span><span>KRAS</span>
                      <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MLH3</span>
                      <span>MSH2</span>
                    </div>
                    <div class="gene_list">
                      <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                      <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                      <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                      <span>POLD1</span>
                    </div>
                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>

              </div>
            </div>



            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>Gene Fusions/Splicing Variations</b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                      <span>MET</span><span>NRG1</span><span>NRG2</span>
                      <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                    </div>
                    <div class="gene_list">
                      <span>ROS1</span>
                    </div>
                  </i>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>

    <!-- Page 7 -->
    <div id="page7" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
      <!--  -->
      <div class="row mt-3">
        <div class="col">
          <h6 class="font-weight-bold">
            Sample preparation and Library preparation :
          </h6>
          <!-- <p>
                                                DNA isolated from FFPE, or any other fresh tumor tissue source was
                                                used to perform targeted gene capture using a custom capture kit.
                                                The libraries were sequenced to mean >350X coverage on Illumina
                                                sequencing platform.
                                              </p> -->
          <p style="text-align: justify;">
            DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture
            using
            a custom capture kit. The libraries were sequenced to mean
            >350X coverage on Illumina sequencing platform.
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col">
          <h6 class="font-weight-bold">
            Bioinformatics Analysis and Reporting :
          </h6>
          <!-- <p>
                                                The sequences obtained are aligned to human reference genome
                                                (GRCh37/hg19) and variant analysis was performed using set of
                                                Bioinformatics Pipeline. Only non-synonymous and splice site
                                                variants found in the panel consisting of specific set of genes were
                                                used for clinical interpretation. Silent variations that do not
                                                result in any change in amino acid in the coding region are not
                                                reported. Clinically relevant mutations were annotated using
                                                published variants in literature and a set of databases – ClinVar,
                                                COSMIC and dbSNP. Common variants are filtered based on allele
                                                frequency in 1000 Genome Phase 3, ExAC, dbSNP, gnomAD, etc. In the
                                                absence of a clinically significant reported known variation(s),
                                                pathogenicity will be predicted based on <i>in-silico</i> gene
                                                prioritization tools: CADD, SIFT, PolyPhen-2, Condel and Mutation
                                                taster and prioritized for clinical correlation. The identified
                                                pathogenic variant will be correlated with observed phenotypic
                                                features of the patient and interpreted according to American
                                                College of Medical Genetics (ACMG) guidelines.
                                              </p>
                                              <p class="mb-2 d-block">
                                                Somatic variants are classified into three tiers based on their level
                                                of clinical significance in cancer diagnosis, prognosis, and/or
                                                therapeutics as per international guidelines: ACMG, ASCO, AMP, CAP,
                                                NCCN and ESMO
                                              </p> -->
          <p style="text-align: justify;">
            The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was
            performed
            using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
            consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
            result in any change
            in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
            published variants in literature and a set of databases – ClinVar, COSMIC
            and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP,
            gnomAD,
            etc. In the absence of a clinically significant reported
            known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools:
            CADD, SIFT,
            PolyPhen-2, Condel and Mutation taster and prioritized for clinical
            correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the
            patient and interpreted according to ACMG/AMP guidelines.
          </p>
          <p class="mb-2 d-block" style="text-align: justify;">
            Somatic variants are classified into three tiers based on their level of clinical significance in cancer
            diagnosis, prognosis, and/or therapeutics as per international guidelines:
            ACMG, ASCO, AMP, CAP, NCCN and ESMO.
          </p>
        </div>
      </div>

      <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
        LIMITATIONS AND DISCLAIMER
      </h6>

      <div class="row">
        <div class="col">
          <div class="disc">
            <ul class="ml-0 pl-4" style="text-align: justify;">
              <li>
                TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd
                and
                has not been cleared or approved by the FDA.
              </li>
              <li>
                DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                should be realized that there are possible sources of error. Errors can result
                from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                recent scientific developments, and alternative classification systems. This
                test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                treatment plan.
              </li>
              <li>
                We are using the canonical transcript for clinical reporting which is usually the longest coding
                transcript with strong/multiple supporting evidence. However, in rare cases,
                clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
              </li>
              <li>
                The contents of this test should be carefully assessed by the treating physician and further interpreted
                along with clinical, histopathological findings, contraindications and
                guidelines before deciding the course of therapy.
              </li>
              <li>
                The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical
                management decisions.
              </li>
              <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
              <li>
                Most recent block is recommended for testing as the mutation profile may change in response to treatment
                and hence differ at different sampling points.
              </li>
              <li>
                The identified pathogenic variant will be correlated with observed phenotypic features of the patient
                and interpreted according to AMP guidelines.
              </li>
              <li>
                Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                rule out the possibility that the tested tumor sample carries
                mutations not previously associated with cancer and hence not included in the panel.
              </li>
              <li>
                Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In
                addition
                to this, sequencing quality and coverage is dependent on many factors
                such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                detection.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- For TMH Template 2-->
  <div *ngIf="isTMH && temp=='temp2'">

    <!-- Page 1 -->
    <div id="page1" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                SCOPE OF THE TEST
              </p>
              <p>SNVs, InDels, CNAs, Gene Fusions status</p>
            </div>
            <img src="../../../assets/4bc/BorderLine.png" class="mr-2 w-100" />
          </div>
        </div>
        <div class="col">
          <div class="">
            <div class="w-100">
              <!-- <p style="color: #208dcb;" class="py-1">
                                                            <span style="color: #000 !important"></span>
                                                          </p> -->
              <div class="w-100" style="min-height: 80px">
                <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                  <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                </p>

                <p *ngIf="report.clinicalSummary != null">
                  <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                    }}</span>
                </p>
              </div>
              <!-- </div> -->
              <img src="../../../assets/4bc/BorderLine.png" class="mr-2 w-100" />

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan">Patient Name : </span>
              <span>{{ report.patient?.name }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
              <span class="text-black">{{
                report.patient?.gender | titlecase
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
              <span class="text-black">{{
                report.patient?.dateOfBirth
                ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                : report.patient?.age + " Years"
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Case No : </span><span>{{ report?.tmhReportInfo?.caseNo }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Path No : </span><span>{{ report?.tmhReportInfo?.pathNo }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Referred By : </span><span>{{ referringClinician }}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled mt-4 pt-4">
            <li class="pb-1">
              <span class="clr-cyan">Patient ID : </span><span>{{ report.patient?.organisationPatientId }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Category : </span><span>{{ report?.tmhReportInfo?.category }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Collected on : </span><span>{{
                report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Reported on : </span><span>{{ date | date : "dd/MM/yyyy" }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Hospital Name : </span><span>{{ labHospital }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <h6 class="py-2 h6_bg">CLINICAL HISTORY/SAMPLE DETAILS</h6>
      <div class="row mt-2 py-2">
        <div class="col-5">
          <h6 class="mb-0 font-weight-bold">Clinical Evaluations</h6>
          <span>{{ report?.cancerIdentifier }}</span>
        </div>
        <div class="col-5">
          <h6 class="mb-0 font-weight-bold">Sample Details</h6>
          <span *ngIf="report.labDetails?.sampleSource">{{
            report.labDetails?.sampleSource
            }}</span>
          <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
        </div>
        <div class="col">
          <h6 class="mb-0 font-weight-bold">Tumor content</h6>
          <span>{{ report?.tmhReportInfo?.tumorContent }}</span>
        </div>
        <div class="w-100">
          <div class="col pt-4">
            <p>(Confirmed by - Consultant Pathologist, HBCHRC, Muzaffarpur)</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row mt-2 py-2">
        <div class="col">
          <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
        </div>
      </div>
      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Genomic findings from tumor profiling
      </h6>

      <!--  -->
      <div class="row">
        <div style="width: 100%;" *ngIf="isReportDownload">
          <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
        </div>
        <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
          <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
            <tr *ngIf="tableCount!= 0">
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>

            <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with therapeutic significance were not detected.
              </td>
            </tr>
            <tr *ngIf="tableCount== 0 && prognasticCount == 0">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with both therapeutic and prognostic significance
                were
                not detected.
              </td>
            </tr>

            <ng-container>
              <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 5 ; let i=index
                                                              ">
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>&nbsp;
                  <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                    <span *ngIf="snvHotspotTrail?.multiVariantOB==null">
                      <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;<span
                        *ngIf="snvHotspotTrail.porCDot != null && snvHotspotTrail.porCDot != ''">({{snvHotspotTrail.porCDot}})</span>
                      <p
                        *ngIf="snvHotspotTrail.allelicBurden != null && !snvHotspotTrail.gene.includes('Wild type')">
                        Allelic burden: {{ snvHotspotTrail.allelicBurden }}%
                      </p>
                    </span>

                    <span *ngIf="snvHotspotTrail?.multiVariantOB!=null"><br />
                      <span style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariantOB"></span>
                    </span></span>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Cancer Type</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>


            </ng-container>
          </table>
          <p *ngIf="
                                snvHotSpot.length != 0 ||
                                cnaHotSpot.length != 0 ||
                                                    fusionHotSpot.length !=0
                                                  " style="font-size: 13px !important" class="mt-0">
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <!--  -->
      <div *ngIf="(tableCount>0) &&
                              prognasticCount==0">
        <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
      </div>
      <div class="row" *ngIf="(tableCount>0 )&&
                              prognasticCount==0">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
            [ngClass]="'table_variant-blue'">

            <ng-container>
              <tr>
                <td colspan="2" style="text-align:center">
                  Clinically relevant genomic alterations associated with prognostic significance were not detected.
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>
      <div *ngIf="prognasticCount>0">
        <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
      </div>

      <div class="row" *ngIf="prognasticCount>0">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
            [ngClass]="'table_variant-blue'">
            <thead>
              <tr>
                <th>Genomic Alteration</th>
                <th>Prognostic Significance</th>

              </tr>
            </thead>
            <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
              <tr>
                <td style="padding-left: 3px !important">
                  <i>{{ prog.gene }}&nbsp; </i>
                  <span [innerHtml]="prog.geneDetailsOB"></span>
                </td>
                <td style="padding-left: 3px !important;">{{prog.statement}}
                </td>
              </tr>
            </ng-container>

          </table>
        </div>
      </div>
      <!--  -->



      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>

    <!-- page 2 -->
    <div id="page2" *ngIf="
                                                    reportPdfRes.allVariantsList.length > 0
                                                    " class="container position-relative page"
      style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="
            report.disclaimerNote != ' ' &&
            report.disclaimerNote != null
          ">
        <h6 style="color: #208dcb"><b>Note: </b></h6>
        <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
      </div>
      <div class="row mt-3" *ngIf="
                                                        otherCountSNV == true ||
                                                        reportPdfRes.allVariantsList.length != 0
                                                      ">
        <div class="col">
          <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
            Variant Details:
          </h6>
        </div>
      </div>

      <div class="row" *ngIf="
                                                        otherCountSNV == true ||
                                                        reportPdfRes.allVariantsList.length != 0
                                                      ">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
            <thead>
              <tr>
                <th>Gene</th>
                <th>Variant Location</th>
                <th>Variant Consequence</th>
                <th>Clinical Significance</th>
                <th>Variant Type</th>
                <th>Reference</th>
              </tr>
            </thead>

            <tr *ngFor="let variant of reportPdfRes.allVariantsList">
              <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <i>{{ variant.gene }}</i>
              </td>
              <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant?.variantType=='snv'">
                  {{ variant?.genomicAlteration }},<br />
                  {{ variant?.ensembleValue }},<br />
                  {{ variant?.exonNumber }}
                </span>
                <span *ngIf="variant?.variantType=='cna'">
                  chr{{ variant?.sample?.split(":")[0] }}:g.{{
                  variant.sample
                  .split(":")[1]
                  .replace("-", "_")
                  .split("|")[0]
                  }}
                </span>
                <span *ngIf="variant.variantType=='fusion'">
                  {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                </span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant.variantType=='snv'">
                  <span *ngIf="variant?.selectedAAChangeknownGeneOB">{{
                    variant?.selectedAAChangeknownGeneOB }}</span>, {{variant?.allelicBurden * 0.01 |
                  percent}}
                </span>
                <span *ngIf="variant.variantType=='cna'">NA</span>
                <span *ngIf="variant.variantType=='fusion'">NA</span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                {{ variant?.significance }}
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant.variantType=='snv'">
                  {{ variant?.exonicFuncknownGene }}
                </span>
                <span *ngIf="variant.variantType=='cna'">
                  Copy Number
                  {{ variant?.sample.split(":")[2] | titlecase }}
                  ({{ variant?.sample.split("|")[1].split(":")[0] }})
                </span>
                <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                {{ variant?.reference }}
              </td>
            </tr>
          </table>
          <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
        <div class="col">
          <h6 class="py-2 h6_bg">Additional Notes</h6>
          <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>


    <!-- Page 5 -->
    <div id="page5" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="disc">
            <div class="ref_data mt-4">
              <ul class="break-word" style="text-align: justify">
                <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                                  text-align: justify;
                                                                  /* white-space:pre-wrap; */
                                                                  word-break: break-word;
                                                                ">
                  {{ referenceArticle }}
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <div class="row pr-2 above-footer">
        <div class="col pl-5">
          <img src="../../../assets/tmc/basusigns1.png" alt="" class="mb-2 sign_width" />
          <div class="sign_text">
            <p>Dr. Moitri Basu, Ph.D.</p>
            <p>Scientific Officer</p>
            <p>Homi Bhabha cancer hospital & Research centre, <br />Muzaffarpur, A unit of Tata Memorial centre.</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>

    <!-- Page 6 -->
    <div id="page6" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <div class="">
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
        <p class="mb-2" style="text-align:justify">
          <span class="font-weight-normal">TAR</span><b>GT</b>
          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
          is a Next Generation Sequencing based test which identifies
          genetic alterations in a comprehensive panel of well curated 72 genes which are having an impact response to
          approved
          therapy for a particular cancer type. Some of the alterations
          detected may have bearing on prognosis and/or therapeutic options
          and may provide relevant information that allows
          oncologists/clinicians to consider various lines of
          targeted treatment for the patient.
        </p>
      </div>

      <!--  -->
      <div class="row">
        <div class="col">
          <div class="">
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
            <p>
              <span class="font-weight-normal">TAR</span><b>GT</b>
              <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
              detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
              Gene Fusions and splice variants in the 72 genes:
            </p>
          </div>
          <!--  -->
          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>SNVs, SHORT INDELS and CNVs
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                      <span>IDH2</span>
                    </div>
                    <div class="gene_list">
                      <span>JAK2</span><span>KIT</span><span>KRAS</span>
                      <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MLH3</span>
                      <span>MSH2</span>
                    </div>
                    <div class="gene_list">
                      <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                      <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                      <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                      <span>POLD1</span>
                    </div>
                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>
              </div>
            </div>

            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>Gene Fusions/Splicing Variations</b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                      <span>MET</span><span>NRG1</span><span>NRG2</span>
                      <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                    </div>
                    <div class="gene_list">
                      <span>ROS1</span>
                    </div>
                  </i>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>

    <!-- Page 7 -->
    <div id="page7" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
      <!--  -->
      <div class="row mt-3">
        <div class="col">
          <h6 class="font-weight-bold">
            Sample preparation and Library preparation :
          </h6>
          <p style="text-align: justify;">
            DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture
            using
            a custom capture kit. The libraries were sequenced to mean
            >350X coverage on Illumina sequencing platform.
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col">
          <h6 class="font-weight-bold">
            Bioinformatics Analysis and Reporting :
          </h6>
          <p style="text-align: justify;">
            The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was
            performed
            using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
            consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
            result in any change
            in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
            published variants in literature and a set of databases – ClinVar, COSMIC
            and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP,
            gnomAD,
            etc. In the absence of a clinically significant reported
            known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools:
            CADD, SIFT,
            PolyPhen-2, Condel and Mutation taster and prioritized for clinical
            correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the
            patient and interpreted according to ACMG/AMP guidelines.
          </p>
          <p class="mb-2 d-block" style="text-align: justify;">
            Somatic variants are classified into three tiers based on their level of clinical significance in cancer
            diagnosis, prognosis, and/or therapeutics as per international guidelines:
            ACMG, ASCO, AMP, CAP, NCCN and ESMO.
          </p>
        </div>
      </div>

      <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
        LIMITATIONS AND DISCLAIMER
      </h6>

      <div class="row">
        <div class="col">
          <div class="disc">
            <ul class="ml-0 pl-4" style="text-align: justify;">
              <li>
                TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd
                and
                has not been cleared or approved by the FDA.
              </li>
              <li>
                DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                should be realized that there are possible sources of error. Errors can result
                from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                recent scientific developments, and alternative classification systems. This
                test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                treatment plan.
              </li>
              <li>
                We are using the canonical transcript for clinical reporting which is usually the longest coding
                transcript with strong/multiple supporting evidence. However, in rare cases,
                clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
              </li>
              <li>
                The contents of this test should be carefully assessed by the treating physician and further interpreted
                along with clinical, histopathological findings, contraindications and
                guidelines before deciding the course of therapy.
              </li>
              <li>
                The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical
                management decisions.
              </li>
              <!-- <li>
                      The sensitivity of this assay to detect large deletions/duplications of more than 10 bp or copy number
                      variations (CNV) is 70-75%. The CNVs detected have to be confirmed
                      by alternate method.
                    </li> -->
              <li>
                Most recent block is recommended for testing as the mutation profile may change in response to treatment
                and hence differ at different sampling points.
              </li>
              <li>
                The identified pathogenic variant will be correlated with observed phenotypic features of the patient
                and interpreted according to AMP guidelines.
              </li>
              <li>
                Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                rule out the possibility that the tested tumor sample carries
                mutations not previously associated with cancer and hence not included in the panel.
              </li>
              <li>
                Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In
                addition
                to this, sequencing quality and coverage is dependent on many factors
                such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                detection.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- For TMH Template 3-->
  <div *ngIf="temp=='temp3'">

    <!-- Page 1 -->
    <div id="page1" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p class="py-1 font-weight-bold" [ngClass]="'txt-skyblue'">
                SCOPE OF THE TEST
              </p>
              <p>SNVs, InDels, CNAs, Gene Fusions status</p>
            </div>
            <img src="../../../assets/4bc/BorderLine.png" class="mr-2 w-100" />
          </div>
        </div>
        <div class="col">
          <div class="">
            <div class="w-100">
              <!-- <p style="color: #208dcb;" class="py-1">
                                                            <span style="color: #000 !important"></span>
                                                          </p> -->
              <div class="w-100" style="min-height: 80px">
                <p [ngClass]="'txt-skyblue'" class="py-1 font-weight-bold" *ngIf="report.clinicalSummary != null">
                  <span *ngIf="report.clinicalSummary != ' '">CLINICAL INDICATION</span>
                </p>

                <p *ngIf="report.clinicalSummary != null">
                  <span style="color: #000 !important" *ngIf="report.clinicalSummary != ' '">{{ report.clinicalSummary
                    }}</span>
                </p>
              </div>
              <!-- </div> -->
              <img src="../../../assets/4bc/BorderLine.png" class="mr-2 w-100" />

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan">Patient Name : </span>
              <span>{{ report.patient?.name }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Gender : </span>
              <span class="text-black">{{
                report.patient?.gender | titlecase
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan" [ngClass]="'txt-skyblue'">Age/DOB : </span>
              <span class="text-black">{{
                report.patient?.dateOfBirth
                ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                : report.patient?.age + " Years"
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Case No : </span><span>{{ report?.tmhReportInfo?.caseNo }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Path No : </span><span>{{ report?.tmhReportInfo?.pathNo }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Referred By : </span><span>{{ referringClinician }}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled mt-4 pt-4">
            <li class="pb-1">
              <span class="clr-cyan">Patient ID : </span><span>{{ report.patient?.organisationPatientId }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Category : </span><span>{{ report?.tmhReportInfo?.category }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Collected on : </span><span>{{
                report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Reported on : </span><span>{{ date | date : "dd/MM/yyyy" }}</span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Hospital Name : </span><span>{{ labHospital }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <h6 class="py-2 h6_bg">CLINICAL HISTORY/SAMPLE DETAILS</h6>
      <div class="row mt-2 py-2">
        <div class="col-5">
          <h6 class="mb-0 font-weight-bold">Clinical Evaluations</h6>
          <span>{{ report?.cancerIdentifier }}</span>
        </div>
        <div class="col-5">
          <h6 class="mb-0 font-weight-bold">Sample Details</h6>
          <span *ngIf="report.labDetails?.sampleSource">{{
            report.labDetails?.sampleSource
            }}</span>
          <span *ngIf="!report.labDetails?.sampleSource">FFPE Block</span>
        </div>
        <div class="col">
          <h6 class="mb-0 font-weight-bold">Tumor content</h6>
          <span>{{ report?.tmhReportInfo?.tumorContent }}</span>
        </div>
        <div class="w-100">
          <div class="col pt-4">
            <p>(Confirmed by - Consultant Pathologist, HBCHRC, Muzaffarpur)</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row mt-2 py-2">
        <div class="col">
          <h6 class="mb-0 text-uppercase f-weight_700">Results</h6>
        </div>
      </div>
      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">
        Genomic findings from tumor profiling
      </h6>

      <!--  -->
      <div class="row">
        <div style="width: 100%;" *ngIf="isReportDownload">
          <img [src]="firstPageTable" alt="" width="100%" class="img-fluid" />
        </div>
        <div class="col-12" id="firstPageTable" *ngIf="!isReportDownload">
          <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
            <tr *ngIf="tableCount!= 0">
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>

            <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with therapeutic significance were not detected.
              </td>
            </tr>
            <tr *ngIf="tableCount== 0 && prognasticCount == 0">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with both therapeutic and prognostic significance
                were
                not detected.
              </td>
            </tr>

            <!-- SNV -->
            <ng-container>
              <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 0 : 3 ; let i=index
                                                              ">
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>&nbsp;
                  <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                    <span *ngIf="snvHotspotTrail?.multiVariantOB==null">
                      <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;<span
                        *ngIf="snvHotspotTrail.porCDot != null && snvHotspotTrail.porCDot != ''">({{snvHotspotTrail.porCDot}})</span>
                      <p
                        *ngIf="snvHotspotTrail.allelicBurden != null && !snvHotspotTrail.gene.includes('Wild type')">
                        Allelic burden: {{ snvHotspotTrail.allelicBurden }}%
                      </p>
                    </span>

                    <span *ngIf="snvHotspotTrail?.multiVariantOB!=null"><br />
                      <span style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariantOB"></span>
                    </span></span>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Cancer Type</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>


            </ng-container>
          </table>
          <p *ngIf="
                                        snvHotSpot.length != 0 ||
                                        cnaHotSpot.length != 0 ||
                                                            fusionHotSpot.length !=0
                                                          " style="font-size: 13px !important" class="mt-0">
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <!--  -->

      <!--  -->



      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>
    <!-- page 1_1 -->
    <div id="page2" *ngIf="reportPdfRes.allHotspotTrailList.length>6" class="container position-relative page"
      style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row">
        <div style="width: 100%;" *ngIf="isReportDownload">
          <img [src]="firstPageTable1" alt="" width="100%" class="img-fluid" />
        </div>
        <div class="col-12" id="firstPageTable1" *ngIf="!isReportDownload">
          <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
            <tr>
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>

            <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with therapeutic significance were not detected.
              </td>
            </tr>
            <tr *ngIf="tableCount== 0 && prognasticCount == 0">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with both therapeutic and prognostic significance
                were
                not detected.
              </td>
            </tr>

            <!-- SNV -->
            <ng-container>
              <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 3 : 8 ; let i=index
                                                              ">
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>
                  &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                    <span *ngIf="snvHotspotTrail?.multiVariant==null">
                      <span
                        *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                        <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                        <span
                          *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace('
                          ','')}})</span></span>
                      <p
                        *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                        Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                      </p>
                      <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{
                        snvHotspotTrail.copyNumber }}
                      </span>
                    </span>
                    <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br />
                      <span style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                    </span></span>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Cancer Type</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>


            </ng-container>
          </table>
          <p *ngIf="
                                        snvHotSpot.length != 0 ||
                                        cnaHotSpot.length != 0 ||
                                                            fusionHotSpot.length !=0
                                                          " style="font-size: 13px !important" class="mt-0">
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>


      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>


    <!-- page 1_2 -->
    <div id="page1_2"
      *ngIf="
                                  prognasticCount==0 || prognasticCount>0 ||  (report.disclaimer != '<p></p>' &&
                                  report.disclaimer != '' &&
                                  report.disclaimer != null) ||
                                  (reportPdfRes.allHotspotTrailList.length>3 && (reportPdfRes.allHotspotTrailList.length<=6 ||reportPdfRes.allHotspotTrailList.length>8))"
      class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->

        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="tableCount>3 && tableCount<=6 || tableCount>8">
        <div style="width: 100%;" *ngIf="isReportDownload">
          <img [src]="firstPageTable2" alt="" width="100%" class="img-fluid" />
        </div>
        <div class="col-12" id="firstPageTable2" *ngIf="!isReportDownload">
          <table class="page_1 cust_table w-100 mt-3 first_1" [ngClass]="'table_skyblue'">
            <tr>
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>

            <tr *ngIf="tableCount== 0 && prognasticCount>0 ">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with therapeutic significance were not detected.
              </td>
            </tr>
            <tr *ngIf="tableCount== 0 && prognasticCount == 0">
              <td colspan="3" class="text-center mt-2">
                Clinically relevant genomic alterations associated with both therapeutic and prognostic significance
                were
                not detected.
              </td>
            </tr>

            <!-- SNV -->
            <ng-container *ngIf="tableCount<=6">
              <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 3 : 6 ; let i=index
                                                              ">
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>
                  &nbsp; <span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                    <span *ngIf="snvHotspotTrail?.multiVariant==null">
                      <span
                        *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                        <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                        <span
                          *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace('
                          ','')}})</span></span>
                      <p
                        *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                        Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                      </p>
                      <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{
                        snvHotspotTrail.copyNumber }}
                      </span>
                    </span>
                    <span *ngIf="snvHotspotTrail?.multiVariant!=null"><br />
                      <span style="font-size: 14px !important" [innerHtml]="snvHotspotTrail?.multiVariant"></span>
                    </span></span>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Cancer Type</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>


            </ng-container>
            <ng-container *ngIf="tableCount>8">
              <tr *ngFor="
                                                                let snvHotspotTrail of reportPdfRes?.allHotspotTrailList  | slice : 8 : 12 ; let i=index
                                                              ">
                <td>
                  <i>{{ snvHotspotTrail.gene }}</i>
                  &nbsp;<span *ngIf="!snvHotspotTrail.gene.includes('Wild type')">
                    <span
                      *ngIf="snvHotspotTrail.exonNumber != null || snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">

                      <span *ngIf="snvHotspotTrail.exonNumber != null">{{snvHotspotTrail.exonNumber}}</span>&nbsp;
                      <span
                        *ngIf="snvHotspotTrail.pposition != null && snvHotspotTrail.pposition != ''">({{snvHotspotTrail.pposition?.replace('
                        ','')}})</span></span>
                    <p
                      *ngIf="snvHotspotTrail.mutantBurdenPercent != null && !snvHotspotTrail.gene.includes('Wild type')">
                      Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                    </p>
                    <span *ngIf="snvHotspotTrail.copyNumber!=null && snvHotspotTrail.copyNumber.length>0"> {{
                      snvHotspotTrail.copyNumber }}
                    </span>
                  </span>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-50">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                        <td class="w-50">NA</td>
                        <td class="w-50">NA</td>
                      </tr>

                      <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.sameTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <!-- <td>{{item.cancerType}}</td> -->
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table class="table first_table w-100">
                    <thead class="border-bottom cust_clr">
                      <tr>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Therapy</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Clinical Relevance</i></span>
                        </th>
                        <th class="w-33">
                          <span class="font-weight-normal"><i>Cancer Type</i></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                        <tr *ngFor="
                                                                          let item of snvHotspotTrail.otherTypeRelevantData
                                                                        ">
                          <td>{{ item.therapy }}</td>
                          <td>{{ item.association }}</td>
                          <td>{{ item.cancerType }}</td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                        <td class="w-33">NA</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>


            </ng-container>
          </table>
          <p *ngIf="
                                        snvHotSpot.length != 0 ||
                                        cnaHotSpot.length != 0 ||
                                                            fusionHotSpot.length !=0
                                                          " style="font-size: 13px !important" class="mt-0">
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <div *ngIf="(tableCount>0) &&
                              prognasticCount==0">
        <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
      </div>
      <div class="row" *ngIf="(tableCount>0 )&&
                              prognasticCount==0">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
            [ngClass]="'table_variant-blue'">

            <ng-container>
              <tr>
                <td colspan="2" style="text-align:center">
                  Clinically relevant genomic alterations associated with prognostic significance were not detected.
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>
      <div *ngIf="prognasticCount>0">
        <h6 class="py-2 h6_bg"> Prognostic Biomarkers</h6>
      </div>

      <div class="row" *ngIf="prognasticCount>0">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details prognasticbiomarkertable"
            [ngClass]="'table_variant-blue'">
            <thead>
              <tr>
                <th>Genomic Alteration</th>
                <th>Prognostic Significance</th>

              </tr>
            </thead>
            <ng-container *ngFor="let prog of reportPdfRes.prognosticBiomarkers">
              <tr>
                <td style="padding-left: 3px !important">
                  <i>{{ prog.gene }}&nbsp; </i>
                  <span [innerHtml]="prog.geneDetailsOB"></span>
                </td>
                <td style="padding-left: 3px !important;">{{prog.statement}}
                </td>
              </tr>
            </ng-container>


          </table>
        </div>
      </div>



      <!--  -->
      <div *ngIf="
                              report.disclaimerNote != ' ' &&
                              report.disclaimerNote != null
                            ">
        <h6 style="color: #208dcb"><b>Note: </b></h6>
        <div style="font-size: 14px !important;text-align: justify" [innerHtml]="report.disclaimerNote"></div>
      </div>



      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>



    <!-- page 2 -->
    <div id="page2" *ngIf="
                                                                    reportPdfRes.allVariantsList.length > 0
                                                                    " class="container position-relative page"
      style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>

      <!--  -->

      <div class="row mt-3" *ngIf="
                                                                        otherCountSNV == true ||
                                                                        reportPdfRes.allVariantsList.length != 0
                                                                      ">
        <div class="col">
          <h6 class="font-weight-bold clr-cyan text-uppercase" [ngClass]="'txt-skyblue'">
            Variant Details:
          </h6>
        </div>
      </div>

      <div class="row" *ngIf="
                                                                        otherCountSNV == true ||
                                                                        reportPdfRes.allVariantsList.length != 0
                                                                      ">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3 varient_details" [ngClass]="'table_variant-blue'">
            <thead>
              <tr>
                <th>Gene</th>
                <th>Variant Location</th>
                <th>Variant Consequence</th>
                <th>Clinical Significance</th>
                <th>Variant Type</th>
                <th>Reference</th>
              </tr>
            </thead>

            <tr *ngFor="let variant of reportPdfRes.allVariantsList">
              <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <i>{{ variant.gene }}</i>
              </td>
              <td *ngIf="variant.significance != 'NA' && variant.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant?.variantType=='snv'">
                  {{ variant?.genomicAlteration }},<br />
                  {{ variant?.ensembleValue }},<br />
                  {{ variant?.exonNumber }}
                </span>
                <span *ngIf="variant?.variantType=='cna'">
                  chr{{ variant?.sample?.split(":")[0] }}:g.{{
                  variant.sample
                  .split(":")[1]
                  .replace("-", "_")
                  .split("|")[0]
                  }}
                </span>
                <span *ngIf="variant.variantType=='fusion'">
                  {{ variant?.leftBreakPoint }}<br />{{variant?.rightBreakPoint}}
                </span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant.variantType=='snv'">
                  <span *ngIf="variant?.selectedAAChangeknownGeneOB">{{
                    variant?.selectedAAChangeknownGeneOB }}</span>, {{variant?.allelicBurden * 0.01 |
                  percent}}
                </span>
                <span *ngIf="variant.variantType=='cna'">NA</span>
                <span *ngIf="variant.variantType=='fusion'">NA</span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                {{ variant?.significance }}
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                <span *ngIf="variant.variantType=='snv'">
                  {{ variant?.exonicFuncknownGene }}
                </span>
                <span *ngIf="variant.variantType=='cna'">
                  Copy Number
                  {{ variant?.sample.split(":")[2] | titlecase }}
                  ({{ variant?.sample.split("|")[1].split(":")[0] }})
                </span>
                <span *ngIf="variant.variantType=='fusion'">Gene Fusions</span>
              </td>
              <td *ngIf="variant?.significance != 'NA' && variant?.significance != 'Not Classified'"
                style="padding-left: 3px !important">
                {{ variant?.reference }}
              </td>
            </tr>
          </table>
          <p *ngIf="reportPdfRes.allVariantsList.length > 0 " style="font-size: 13px !important" class="mt-0">
            <i> *NA: Not Applicable</i>
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row mt-5" *ngIf="
                  !variantFlag &&
                  report.addNotes != null && report.addNotes != '<div></div>'
                ">
        <div class="col">
          <h6 class="py-2 h6_bg">Additional Notes</h6>
          <div class="py-2" [innerHtml]="report.addNotes" style="text-align: justify; font-size: 14px !important"></div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>



    <!-- Page 5 -->
    <div id="page5" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">References</h6>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="disc">
            <div class="ref_data mt-4">
              <ul class="break-word" style="text-align: justify">
                <li *ngFor="let referenceArticle of reportPdfRes.referenceArticles" style="
                                                                                  text-align: justify;
                                                                                  /* white-space:pre-wrap; */
                                                                                  word-break: break-word;
                                                                                ">
                  {{ referenceArticle }}
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <div class="row pr-2 above-footer">
        <div class="col pl-5">
          <img src="../../../assets/tmc/basusigns1.png" alt="" class="mb-2 sign_width" />
          <div class="sign_text">
            <p>Dr. Moitri Basu, Ph.D.</p>
            <p>Scientific Officer</p>
            <p>Homi Bhabha cancer hospital & Research centre, <br />Muzaffarpur, A unit of Tata Memorial centre.</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>

    <!-- Page 6 -->
    <div id="page6" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <div class="">
        <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">TEST DESCRIPTION</h6>
        <p class="mb-2" style="text-align:justify">
          <span class="font-weight-normal">TAR</span><b>GT</b>
          <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
          is a Next Generation Sequencing based test which identifies
          genetic alterations in a comprehensive panel of well curated 72 genes which are having an impact response to
          approved
          therapy for a particular cancer type. Some of the alterations
          detected may have bearing on prognosis and/or therapeutic options
          and may provide relevant information that allows
          oncologists/clinicians to consider various lines of
          targeted treatment for the patient.
        </p>
      </div>

      <!--  -->
      <div class="row">
        <div class="col">
          <div class="">
            <h6 class="py-2 h6_bg" [ngClass]="'skyBlue'">GENES EVALUATED</h6>
            <p>
              <span class="font-weight-normal">TAR</span><b>GT</b>
              <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
              detects mutations (SNVs and Short InDels), Copy Number Variations (CNVs),
              Gene Fusions and splice variants in the 72 genes:
            </p>
          </div>
          <!--  -->
          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>SNVs and SHORT INDELS
                    </b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ABL1</span><span>ALK</span><span>APC</span>
                      <span>AR</span><span>ATM</span><span>BARD1</span>
                      <span>BMPR1A</span><span>BRAF</span><span>BRCA1</span>
                      <span>BRCA2</span>
                    </div>
                    <div class="gene_list bg-white">
                      <span>BRIP1</span><span>CDK12</span><span>CDK4</span>
                      <span>CDK6</span><span>CDKN2A</span><span>CHEK1</span>
                      <span>CHEK2</span><span>CTNNB1</span><span>EGFR</span>
                      <span>EPCAM</span>
                    </div>
                    <div class="gene_list">
                      <span>ERBB2</span><span>ERBB3</span><span>EZH2</span>
                      <span>FANCL</span><span>FGFR1</span><span>FGFR2</span>
                      <span>FGFR3</span><span>GAPDH</span><span>IDH1</span>
                      <span>IDH2</span>
                    </div>
                    <div class="gene_list">
                      <span>JAK2</span><span>KIT</span><span>KRAS</span>
                      <span>MAP2K1</span><span>MAP2K2</span><span>MDM2</span>
                      <span>MET</span><span>MLH1</span><span>MLH3</span>
                      <span>MSH2</span>
                    </div>
                    <div class="gene_list">
                      <span>MSH6</span><span>MUTYH</span><span>NRAS</span>
                      <span>PALB2</span><span>PDGFRA</span><span>PDGFRB</span>
                      <span>PIK3CA</span><span>PMS1</span><span>PMS2</span>
                      <span>POLD1</span>
                    </div>
                    <div class="gene_list">
                      <span>POLE</span><span>POLH</span><span>PTEN</span>
                      <span>RAD50</span><span>RAD51</span><span>RAD51B</span>
                      <span>RAD51C</span><span>RAD51D</span><span>RAD54L</span>
                      <span>RB1</span>
                    </div>
                    <div class="gene_list">
                      <span>RET</span><span>ROS1</span><span>SMAD4</span>
                      <span>STK11</span><span>TP53</span><span>TSC1</span>
                      <span>TSC2</span>
                    </div>
                  </i>
                </div>

              </div>
            </div>
            <div class="col-12 px-0">
              <div class="genes_info">
                <div class="title py-2">
                  <h6 class="mb-0 d-inline-block border-bottom py-2">
                    <b>Gene Fusions/Splicing Variations</b> Covered in
                    <span class="font-weight-normal">TAR</span><b>GT</b>
                    <span class="clr-cyan pl-2" [ngClass]="'txt-skyblue'">First Solid</span>
                  </h6>
                </div>
                <div class="gene_body" [ngClass]="'gene-bg_blue'">
                  <i>
                    <div class="gene_list">
                      <span>ALK</span><span>FGFR2</span><span>FGFR3</span>
                      <span>MET</span><span>NRG1</span><span>NRG2</span>
                      <span>NTRK1</span><span>NTRK2</span> <span>NTRK3</span><span>RET</span>

                    </div>
                    <div class="gene_list">
                      <span>ROS1</span>
                    </div>
                  </i>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>

    <!-- Page 7 -->
    <div id="page7" class="container position-relative page" style="background-color: white">
      <div class="d-flex justify-content-between logo_panel py-3">
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_log1.png" alt="" class="logo_first" />
        </div>
        <div class="logo_part text-center">
          <img src="../../../assets/tmc/tmc_logo2.png" alt="" class="logo_two" />
          <p style="font-size: 10px">
            An Initiative of Bihar Govt. & Doctors For You
          </p>
          <p class="text-uppercase">MEDICAL GENOMICS & MOLECULAR ONCOLOGY</p>
        </div>
        <div class="logo_part">
          <img src="../../../assets/tmc/tmc_logo3.jpg" alt="" class="logo_three" />
        </div>
      </div>
      <div class="row header mt-1">
        <div></div>
        <div class="col">
          <div class="patient-detailes ml-auto float-right">
            <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
              <li>
                Patient Id <br />
                <span>{{ report.patient?.organisationPatientId }}</span>
              </li>
              <li class="ml-4">
                Patient Name<br />
                <span>{{ report.patient?.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <h6 class="py-2 h6_bg mt-3" [ngClass]="'skyBlue'">TEST METHODOLOGY</h6>
      <!--  -->
      <div class="row mt-3">
        <div class="col">
          <h6 class="font-weight-bold">
            Sample preparation and Library preparation :
          </h6>
          <p style="text-align: justify;">
            DNA isolated from FFPE, or any other fresh tumor tissue source was used to perform targeted gene capture
            using
            a custom capture kit. The libraries were sequenced to mean
            >350X coverage on Illumina sequencing platform.
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col">
          <h6 class="font-weight-bold">
            Bioinformatics Analysis and Reporting :
          </h6>
          <p style="text-align: justify;">
            The sequences obtained are aligned to human reference genome (GRCh37/hg19) and variant analysis was
            performed
            using set of Bioinformatics Pipeline. Only non-synonymous and splice site variants found in the panel
            consisting of specific set of genes were used for clinical interpretation. Silent variations that do not
            result in any change
            in amino acid in the coding region are not reported. Clinically relevant mutations were annotated using
            published variants in literature and a set of databases – ClinVar, COSMIC
            and dbSNP. Common variants are filtered based on allele frequency in 1000 Genome Phase 3, ExAC, dbSNP,
            gnomAD,
            etc. In the absence of a clinically significant reported
            known variation(s), pathogenicity will be predicted based on <i>in-silico</i> gene prioritization tools:
            CADD, SIFT,
            PolyPhen-2, Condel and Mutation taster and prioritized for clinical
            correlation. The identified pathogenic variant will be correlated with observed phenotypic features of the
            patient and interpreted according to ACMG/AMP guidelines.
          </p>
          <p class="mb-2 d-block" style="text-align: justify;">
            Somatic variants are classified into three tiers based on their level of clinical significance in cancer
            diagnosis, prognosis, and/or therapeutics as per international guidelines:
            ACMG, ASCO, AMP, CAP, NCCN and ESMO.
          </p>
        </div>
      </div>

      <h6 class="py-2 h6_bg mt-2" [ngClass]="'skyBlue'">
        LIMITATIONS AND DISCLAIMER
      </h6>

      <div class="row">
        <div class="col">
          <div class="disc">
            <ul class="ml-0 pl-4" style="text-align: justify;">
              <li>
                TARGT FIRST SOLID test has been developed, validated and performed by 4baseCare Oncosolutions Pvt. Ltd
                and
                has not been cleared or approved by the FDA.
              </li>
              <li>
                DNA studies do not constitute a definitive test for the selected condition(s) in all individuals. It
                should be realized that there are possible sources of error. Errors can result
                from trace contamination, rare technical errors, rare genetic variants that interfere with analysis,
                recent scientific developments, and alternative classification systems. This
                test should be one of the many aspects used by the healthcare provider to help with a diagnosis and
                treatment plan.
              </li>
              <li>
                We are using the canonical transcript for clinical reporting which is usually the longest coding
                transcript with strong/multiple supporting evidence. However, in rare cases,
                clinically relevant variants annotated in alternate complete coding transcripts could also be reported.
              </li>
              <li>
                The contents of this test should be carefully assessed by the treating physician and further interpreted
                along with clinical, histopathological findings, contraindications and
                guidelines before deciding the course of therapy.
              </li>
              <li>
                The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical
                management decisions.
              </li>
              <li>
                Most recent block is recommended for testing as the mutation profile may change in response to treatment
                and hence differ at different sampling points.
              </li>
              <li>
                The identified pathogenic variant will be correlated with observed phenotypic features of the patient
                and interpreted according to AMP guidelines.
              </li>
              <li>
                Certain genes may not be covered completely, and few mutations could be missed. A negative result cannot
                rule out the possibility that the tested tumor sample carries
                mutations not previously associated with cancer and hence not included in the panel.
              </li>
              <li>
                Our limit of detection for TARGT FIRST SOLID is 5% for SNVs, 10% for InDels and CNV gain &#8805; 6. In
                addition
                to this, sequencing quality and coverage is dependent on many factors
                such as homopolymers, GC-rich regions, intrinsic quality of FFPE DNA and that might impact the variant
                detection.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer d-flex justify-content-between align-items-center">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold">Genomics Bihar,</span>
            </p>
            <p>Homi Bhabha cancer hospital & Research centre</p>
            <p>Inside Srikrishna Medical College & Hospital</p>
            <p>Umanagar, Muzaffarpur-842004.</p>
            <p>Contact: ngs.mfp@gmail.com</p>
          </div>
        </div>
        <div class="col">
          <p class="text-right">
            <img src="../../../assets/tmc/tmc_footer.png" alt="footer_image" class="footer_img" />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
