import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CnaService {

  constructor(
    private httpClient: HttpClient
  ) { }


  autoUploadCnaFile(reportId: Number) {
    return this.httpClient.post(environment.wbApiUrl+'/cna/' + reportId + '/auto_upload_cna_file', null,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getAllCNAVariantsByReportId(reportId: number){
    return this.httpClient.get(environment.wbApiUrl+'/cna/' + reportId + '/get_cna_report_variants',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  cnaVariantStatus(cnaVariantId: number, aBoolean: boolean){
    return this.httpClient.post(environment.wbApiUrl+'/cna/' + cnaVariantId + '/cna_variant_status' ,aBoolean,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  cnaStarVariantStatus(cnaVariantId: number, aBoolean: boolean){
    return this.httpClient.post(environment.wbApiUrl+'/cna/' + cnaVariantId + '/cna_star_variant_status' ,aBoolean,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  setPriorityOrder(cnaVariantId: number, priorityOrderNumber: number) {
    return this.httpClient.post(environment.wbApiUrl+'/cna/' + cnaVariantId + '/priority_order', priorityOrderNumber,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getCNAVariant(cnaVariantId: number){
    return this.httpClient.get(environment.wbApiUrl+'/cna/' + cnaVariantId + '/get_cna_variant',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addCNAVariantSignificance(cnaVariantId: number, body: any){
    return this.httpClient.post(environment.wbApiUrl+'/cna/' + cnaVariantId + '/add_significance' ,body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
  getFilteredCNA( body: any){
    return this.httpClient.post(environment.wbApiUrl+'/cna/get_filtered_cna' ,body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }
}
