import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FusionService {

  constructor(
    private httpClient: HttpClient
  ) { }


  autoUploadFusionFile(reportId: Number) {
    return this.httpClient.post(environment.wbApiUrl+'/fusion/' + reportId + '/auto_upload_fusion_file', null,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getAllFusionVariantsByReportId(reportId: number){
    return this.httpClient.get(environment.wbApiUrl+'/fusion/' + reportId + '/get_fusion_report_variants',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  selectFusionForReport(fusionVariantId: number, aBoolean: boolean){
    return this.httpClient.post(environment.wbApiUrl+'/fusion/' + fusionVariantId + '/select_fusion' ,aBoolean,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  selectStarFusionForReport(fusionVariantId: number, aBoolean: boolean){
    return this.httpClient.post(environment.wbApiUrl+'/fusion/' + fusionVariantId + '/select_star_fusion' ,aBoolean,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  setPriorityOrder(fusionVariantId: number, priorityOrderNumber: number) {
    return this.httpClient.post(environment.wbApiUrl+'/fusion/' + fusionVariantId + '/priority_order', priorityOrderNumber,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  getFusionVariant(fusionVariantId: number){
    return this.httpClient.get(environment.wbApiUrl+'/fusion/' + fusionVariantId + '/get_fusion_variant',{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

  addFusionVariantSignificance(fusionVariantId: number, body: any){
    return this.httpClient.post(environment.wbApiUrl+'/fusion/' + fusionVariantId + '/add_significance' ,body,{
      headers:{
        'Authorization':'Bearer '+ JSON.parse(localStorage.getItem('wbToken')||'{}')
      }
    });
  }

}
